import {
  Bounds,
  FieldModel,
  FieldQueryOptions,
  FieldsAPI,
  MpxTypeaheadAPI,
  MpxTypeaheadResult,
} from '@cibo/core'
import { Icon } from '@cibo/ui'
import FilterList from '@mui/icons-material/FilterList'
import SearchIcon from '@mui/icons-material/Search'
import {
  Autocomplete,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import throttle from 'lodash/throttle'
import { omit } from 'ramda'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBatchedField } from '../../../queries'
import { PaginatedFieldsContext } from '../PaginatedFieldsContext'
import { PaginatedFieldsTableFilters } from '../SearchFilters'
import { filterCount } from '../SearchFilters/filterCount'

const deBrace = (s?: string) => (!s ? '' : s.replace(/\{\{/g, '').replace(/\}\}/g, ''))

function isLocationResult(result: FieldModel | MpxTypeaheadResult): result is MpxTypeaheadResult {
  return (result as MpxTypeaheadResult).resultInd !== undefined
}

const TypeaheadSuggestion = (props: any, item: FieldModel | MpxTypeaheadResult) =>
  isLocationResult(item) ? LocationSuggestion(props, item) : FieldSuggestion(props, item)

const FieldSuggestion = (props: any, item: FieldModel) => (
  <li {...props} key={item.resourceId}>
    <div
      style={{
        display: 'grid',
        gap: 3,
        gridTemplate: '1fr / 1fr',
        gridTemplateColumns: 'min-content auto',
      }}
    >
      <Icon name="Pin" />
      <Stack>
        <Typography>{item.name}</Typography>
        <Typography variant="caption">
          {item.county}, {item.state}
        </Typography>
      </Stack>
    </div>
  </li>
)

const LocationSuggestion = (props: any, item: MpxTypeaheadResult) => (
  <li {...props} key={item.resultInd}>
    <div
      style={{
        display: 'grid',
        gap: 3,
        gridTemplate: '1fr / 1fr',
        gridTemplateColumns: 'min-content auto',
      }}
    >
      <Icon name="Map" />
      <Stack>
        <Typography>{item.label && deBrace(item.label)}</Typography>
        <Typography variant="caption">
          {item.county && `${deBrace(item.county)}, `}
          {deBrace(item.state)}
        </Typography>
      </Stack>
    </div>
  </li>
)

export type FieldSearchBarProps = {
  disabled?: boolean
  onSelectTypeaheadResult?: (item: FieldModel) => void
  onSelectLocationResult?: (item: Bounds) => void
}

export const FieldSearchBar = ({
  disabled,
  onSelectTypeaheadResult,
  onSelectLocationResult,
}: FieldSearchBarProps) => {
  const theme = useTheme()
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation('@cibo/landmanager/FieldSearchBar')
  const [searchResults, setSearchResults] = useState<FieldModel[]>([])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()
  const { filters, setFilters, baseFilters } = useContext(PaginatedFieldsContext)
  const { data: selectedField } = useBatchedField(filters.resourceIds)

  const handleChangeText = throttle(
    async (inputText: string) => {
      const fullText = inputText?.trim().length === 0 ? undefined : inputText?.trim()

      if (fullText) {
        const queries: Array<any> = [
          FieldsAPI.paginatedFields({
            ...filters,
            resourceIds: undefined,
            fullText,
            limit: 15,
          }).then(response => (response ? response.items.map(f => new FieldModel(f)) : [])),
        ]

        if (onSelectLocationResult) {
          queries.push(
            MpxTypeaheadAPI.suggest({
              searchText: fullText,
              limit: 8,
              excludeParcelIdx: true,
              _f: undefined, // do not include plain text results
            }).then(results => results.items)
          )
        }

        const [fieldResults, locationResults] = await Promise.all(queries)

        setSearchResults([...fieldResults, ...(locationResults || [])])
      } else {
        setSearchResults([])
      }
    },
    250,
    { leading: true, trailing: true }
  )

  const handleSelection = (
    e: any,
    item: FieldModel | MpxTypeaheadResult | null | string,
    reason: any
  ) => {
    switch (reason) {
      case 'selectOption':
        if (!item || typeof item === 'string') break

        if (!!onSelectLocationResult && isLocationResult(item)) {
          MpxTypeaheadAPI.resolveQuery({ resultInd: item.resultInd }).then(result => {
            onSelectLocationResult(result as Bounds)
          })
        } else if (item instanceof FieldModel) {
          onSelectTypeaheadResult && onSelectTypeaheadResult(item)
        }
        break
      case 'clear':
        setFilters(omit(['fullText'])(filters))
        break
      case 'createOption':
        if (typeof item === 'string') {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { resourceIds, ...newFilters } = filters
          if (item?.trim().length > 0) {
            newFilters.fullText = item.trim()
          }
          setFilters({
            ...newFilters,
          })
        }
        break
      default:
        break
    }
  }

  const handleCloseFilterPanel = () => {
    setAnchorEl(undefined)
  }
  const handleOpenFilterPanel = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleApplyFilters = (newFilters: FieldQueryOptions) => {
    setFilters(newFilters)
    setAnchorEl(undefined)
  }

  return (
    <>
      <Stack direction={isMdDown ? 'column' : 'row'} alignItems="center" spacing={2} flex={1}>
        <Autocomplete
          disabled={disabled}
          data-testid="search_input"
          freeSolo
          options={searchResults}
          getOptionLabel={option =>
            !option
              ? ''
              : typeof option === 'string'
              ? option
              : isLocationResult(option)
              ? deBrace(option.label)
                  .split(', ')
                  .reverse()
                  .filter(part => part !== 'United States')
                  .join(', ')
              : option.name
          }
          filterOptions={x => x}
          noOptionsText={t('noResults')}
          onChange={handleSelection}
          onInputChange={(event, newValue) => handleChangeText(newValue)}
          value={
            // while the below is overkill, attempts to handle this information in state have mysteriously failed.
            filters.fullText
              ? filters.fullText
              : filters.resourceIds && selectedField
              ? selectedField.name
              : ''
          }
          renderInput={params => (
            <TextField
              {...params}
              size="small"
              placeholder={t('placeholder')}
              slotProps={{
                input: {
                  ...params.InputProps,
                  startAdornment: <SearchIcon color="primary" />,
                  sx: {
                    ...(!isMdDown ? { width: '220px' } : { flex: 1 }),
                    backgroundColor: 'background.default',
                  },
                },
              }}
              data-testid="search_input"
            />
          )}
          renderOption={TypeaheadSuggestion}
          disableClearable={false}
          sx={!isMdDown ? { width: '220px' } : { flex: 1 }}
        />

        <IconButton
          sx={{ backgroundColor: 'background.paper' }}
          color="primary"
          onClick={handleOpenFilterPanel}
          size="large"
          aria-label={t('Filters')}
        >
          <FilterList />
        </IconButton>
      </Stack>

      <PaginatedFieldsTableFilters
        anchorEl={anchorEl}
        filters={filters}
        baseFilters={baseFilters}
        onClose={handleCloseFilterPanel}
        onReset={() => setFilters({})}
        onSearch={handleApplyFilters}
        filterCount={filterCount(filters)}
      />
    </>
  )
}
