import { FERTILIZER_TYPE_UAN, RDFertilizerEvent } from '@cibo/core'
import { Formik, FormikHelpers, useField } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FertilizerEventValidationSchema } from '../schema'
import { FertilizerEventEditorForm } from './FertilizerEventEditorForm'
import { FertilizerEventEditorProps } from './FertilizerEventTypes'

type FertilizerFormInput = RDFertilizerEvent & {
  rateProxy?: number
  unitProxy?: string
}

const findInitialValues = (value: FertilizerFormInput) => {
  if (value) {
    // if legacy values include a product type that is "other" and no nitrogen percent,
    // BUT we have a nitrogen amount: display the nitrogen amount
    if (!!value.nitrogenAmnt && value.type === 'other' && !value.nitrogenPer) {
      return {
        ...value,
        unitProxy: 'poundsOfNitrogenPerAcre',
        rateProxy: value.nitrogenAmnt,
      }
    } else if (value.units && value.rate) {
      return {
        ...value,
        unitProxy: value.units,
        rateProxy: value.rate,
      }
    } else if (!!value.nitrogenAmnt) {
      return {
        ...value,
        unitProxy: 'poundsOfNitrogenPerAcre',
        rateProxy: value.nitrogenAmnt,
      }
    } else {
      return {
        ...value,
        unitProxy: undefined,
        rateProxy: undefined,
      }
    }
  } else {
    return {
      date: null,
      type: null,
      method: null,
      units: null,
    } as unknown as FertilizerFormInput
  }
}

export const FertilizerEventEditor = (props: FertilizerEventEditorProps) => {
  const { name, requirement, setExpanded, onEditEvent, isImmutable } = props
  const { t } = useTranslation('@cibo/landmanager/FertilizerEditor')
  const [{ value }, , { setValue, setTouched }] = useField<
    RDFertilizerEvent & { rateProxy?: number; unitProxy?: string }
  >(name)
  const unitsHelper = useField<string>(`${name}.units`)[2]

  useEffect(() => {
    if (FERTILIZER_TYPE_UAN.includes(value?.type) && !value.nitrogenAmnt) {
      unitsHelper.setValue('gallonsPerAcre')
    }
  }, [value?.type, value?.nitrogenAmnt])

  const validationSchema = FertilizerEventValidationSchema({ t, requirement })

  const handleSubmit = (
    values: RDFertilizerEvent & { rateProxy?: number; unitProxy?: string },
    { setSubmitting }: FormikHelpers<RDFertilizerEvent>
  ) => {
    // eslint-disable-next-line
    const { rateProxy, unitProxy, ...valuesSansProxy } = values
    setTouched(true, false)
    setValue(valuesSansProxy)
    setSubmitting(false)
    onEditEvent(false)
    setExpanded(false)
  }

  return (
    <Formik
      initialValues={findInitialValues(value)}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
      disabled={isImmutable}
      onSubmit={handleSubmit}
    >
      <FertilizerEventEditorForm {...props} />
    </Formik>
  )
}
