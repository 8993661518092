import { FieldsAPI } from '@cibo/core'
import { QUERY_KEY as LANDMANAGER_QUERY_KEY, useInvalidateBatchedFields } from '@cibo/landmanager'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PROGRAMS_QUERY_KEY } from './queryKey'

export const useUnenrollField = () => {
  const queryClient = useQueryClient()

  const invalidateBatchedFields = useInvalidateBatchedFields()

  return useMutation({
    mutationFn: ({ fieldId, programId }: { fieldId: string; programId: string }) =>
      FieldsAPI.unenrollField(fieldId, programId),

    onSuccess: (response, { fieldId, programId }) => {
      queryClient.invalidateQueries({ queryKey: [LANDMANAGER_QUERY_KEY.FIELDS_PAGINATED] })
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAMS] })
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAMS, programId] })
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.OPPORTUNITIES] })

      invalidateBatchedFields([fieldId])
    },
  })
}

export type ChangeYearTraitIds = 'coverCrop' | 'tillage' | 'nitrogenReduction'

export type ForceModifyChangeYearArgs = {
  resourceId: string
  prgIdToEnroll: string
  coverCropChangeYear?: string
  tillageChangeYear?: string
  nitrogenReductionChangeYear?: string
}

export const useForceModifyChangeYear = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (args: ForceModifyChangeYearArgs) => FieldsAPI.forceModifyChangeYear(args),

    onSuccess: (response, { resourceId }) => {
      queryClient.invalidateQueries({ queryKey: [LANDMANAGER_QUERY_KEY.FIELDS_PAGINATED] })
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.OPPORTUNITIES] })
    },
  })
}
