import { DebugButton } from '@cibo/profile'
import { ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import {
  useProgramEngagementDetails,
  useUpdateProgramEngagementDetails,
} from '../../../../hooks/useProgramEngagementDetails'
import { RDEqipAutogenRequiredForms } from '../RDEqipAutogenRequiredFormsResourceDetailFeature'
import { EqipApplicationDocusign } from './EqipApplicationDocusign'
import { PaymentConsentDocusign } from './PaymentConsentDocusign'
import { RDEqipDocuments } from './types'
import { DocSigningStatus } from './DocSigningStatus'

export const RDEqipDocumentsTaskEditor = ({
  detailRequirements,
  engagementId,
  task,
}: ResourceDetailFeatureTaskEditorProps<RDEqipDocuments, { docusignType?: string, docType?: string }>) => {
  // @todo get a better year. the happenstance first requirement may not be yearly
  const detailYear = detailRequirements[0].year as number

  const eqipDocumentsDetails = useProgramEngagementDetails({
    detailRequirements: [{ year: detailYear, traitId: 'eqipDocuments', dataType: 'workflow' }],
    resourceId: engagementId,
  })
  const eqipDocumentsDetail = eqipDocumentsDetails.data?.[0] as RDEqipDocuments

  const updateDetail = useUpdateProgramEngagementDetails()

  const debugButton = (
    <DebugButton
      loading={updateDetail.isPending}
      disabled={!eqipDocumentsDetail}
      onClick={() => {
        updateDetail.mutateAsync({
          resourceId: engagementId,
          details: [
            {
              traitId: 'eqipDocuments',
              year: detailYear,
            } as RDEqipAutogenRequiredForms,
          ],
        })
      }}
    >
      Reset Docusign
    </DebugButton>
  )

  const docType = task.rule.params.docusignType ?? task.rule.params.docType;

  switch (docType) {
    case 'eqipPrgAssignPaymentContractDoc':
      return (
        <>
          <PaymentConsentDocusign
            engagementId={engagementId}
            taskId={task.id}
            isSigned={!!eqipDocumentsDetail?.result?.eqipPrgAssignPaymentContractDoc?.signed}
          />
          {debugButton}
        </>
      )

    case 'eqipPrgDoc':
      return (
        <>
          <EqipApplicationDocusign
            detailYear={detailYear}
            engagementId={engagementId}
            taskId={task.id}
          />
          {debugButton}
        </>
      )

    case 'eqipPrgAssignPaymentForm':
      return (
        <>
          <DocSigningStatus
            engagementId={engagementId}
            taskId={task.id}
            // TODO It would be nice to use the task status here to determine signing status. If the task is `valid: true`,
            // then document has been signed.
            isSigned={!!eqipDocumentsDetail?.result?.eqipPrgAssignPaymentForm?.signed}
            ns="@cibo/programs/eqipPrgAssignPaymentForm"
          />
          {debugButton}
        </>
      )
  }
}
