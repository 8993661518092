import { COMPONENTS_BY_TYPE, FERTILIZER_COMPONENTS, RDFertilizerEvent } from '@cibo/core'
import { FormControl, FormLabel, InputAdornment, Stack, Typography } from '@mui/material'
import { Field, useField, useFormikContext } from 'formik'
import { TextField } from 'formik-mui'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsRequired } from '../../useIsRequired'
import { useShowDetailAttribute } from '../../useShowDetailAttribute'
import { FertilizerEventEditorProps } from './FertilizerEventTypes'

export const FertilizerComponentsInput = ({
  name,
  filterInputs,
  requirement,
}: Pick<FertilizerEventEditorProps, 'name' | 'filterInputs' | 'requirement'>) => {
  const { t } = useTranslation('@cibo/landmanager/FertilizerEditor')
  const isRequired = useIsRequired(requirement)
  const show = useShowDetailAttribute<RDFertilizerEvent>(filterInputs)

  const { values, setFieldValue, errors } = useFormikContext<
    RDFertilizerEvent & { rateProxy?: number; unitProxy?: string }
  >()
  const { error } = useField<RDFertilizerEvent>(`${name}.combined`)[1]

  // @ts-ignore combinedPercent is a "synthetic" field to generate this error / not a form value
  const combinedPercentError = errors.combinedPercent
  const combinedError = error

  useEffect(() => {
    FERTILIZER_COMPONENTS.forEach(component =>
      setFieldValue(
        component,
        COMPONENTS_BY_TYPE[values.type] ? COMPONENTS_BY_TYPE[values.type][component] : '',
        true
      )
    )
  }, [values.type])

  const comboFieldStyle = useMemo(
    () => ({
      zIndex: 0,
      marginBottom: 2,
      '& .MuiTextField-root': {
        flex: 1,
      },
      '& .MuiTextField-root .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        marginLeft: '-1px',
        zIndex: 0,
        borderColor: combinedError ? 'error.main' : undefined,
      },
      '& .MuiTextField-root:first-of-type .MuiOutlinedInput-notchedOutline': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
      },
      '& .MuiTextField-root:last-child .MuiOutlinedInput-notchedOutline': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
      },
      '& .MuiError .MuiOutlinedInput-notchedOutline': {
        zIndex: 1,
      },
    }),
    [error]
  )

  return (
    <FormControl fullWidth>
      <Stack direction="row">
        {FERTILIZER_COMPONENTS.filter(component => show(component)).map(component => (
          <FormLabel
            key={`${name}.${component}`}
            htmlFor={`${name}.${component}`}
            sx={{ width: '25%' }}
            required={
              isRequired(component) ||
              (values.type === 'other' && values.unitProxy !== 'poundsOfNitrogenPerAcre')
            }
          >
            {t('fertilizerComponent', { context: component })}
          </FormLabel>
        ))}
      </Stack>
      <Stack direction="row" sx={comboFieldStyle}>
        {FERTILIZER_COMPONENTS.filter(component => show(component)).map(component => (
          <Field
            required={values?.type === 'other'}
            disabled={values?.type !== 'other'}
            key={`${name}.${component}`}
            name={`${component}`}
            component={TextField}
            inputProps={{
              min: 0,
              disabled: !!COMPONENTS_BY_TYPE[values.type],
              max: 100,
              type: 'number',
              'data-testid': `${name}.${component}`,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        ))}
      </Stack>

      {!!combinedPercentError && (
        <Typography color="error" variant="caption">
          {combinedPercentError}
        </Typography>
      )}
    </FormControl>
  )
}
