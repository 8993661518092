import * as Sentry from '@sentry/react'

export const logNonRequestError = (error: any, _extra?: Record<string, any>) => {
  if (import.meta.env.PROD || import.meta.env.VITE_DEBUG_SENTRY) {
    Sentry.captureException(error, {
      extra: { passedLocation: 'nonRequestError', ..._extra },
    })
    return
  } else {
    console.error(`logNonRequestError: ${JSON.stringify(error)} ${JSON.stringify(_extra)}`)
  }
}
