import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'

type YesNoQuestionProps = {
  disabled: boolean
  isError: boolean
  noLabel: string
  onChange(answer: 'true' | 'false'): void
  questionLabel: string
  value?: 'true' | 'false'
  yesLabel: string
  'data-testid'?: string
}

export const YesNoQuestion = ({
  disabled,
  isError,
  noLabel,
  onChange,
  questionLabel,
  value,
  yesLabel,
  'data-testid': dataTestId,
}: YesNoQuestionProps) => {
  return (
    <FormControl
      component="fieldset"
      variant="standard"
      error={isError}
      data-testid={dataTestId || 'yesNoQuestion'}
    >
      <FormLabel id="radio-buttons-group-label">{questionLabel}</FormLabel>
      <RadioGroup
        aria-labelledby="radio-buttons-group-label"
        value={value ?? ''}
        onChange={event => onChange(event.target.value as 'true' | 'false')}
      >
        <FormControlLabel
          data-testid="yesBtn"
          value="true"
          control={<Radio />}
          label={yesLabel}
          disabled={disabled}
        />
        <FormControlLabel
          data-testid="noBtn"
          value="false"
          control={<Radio />}
          label={noLabel}
          disabled={disabled}
        />
      </RadioGroup>
    </FormControl>
  )
}
