import { FieldQueryOptions } from '@cibo/core'
import { GridRowId } from '@mui/x-data-grid-pro'
import { uniq } from 'ramda'
import { PropsWithChildren, ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBatchedFields } from '../../queries'
import { useLandManagement } from '../LandManagementProvider'
import { HideFilters, PaginatedFieldsContext } from './PaginatedFieldsContext'

export const PAGE_SIZES = [10, 25, 50]

type PaginatedFieldsProviderProps = {
  hideFilters?: HideFilters
  children?: ReactNode
}

export const PaginatedFieldsProvider = ({
  children,
  hideFilters = {},
}: PropsWithChildren<PaginatedFieldsProviderProps>) => {
  const navigate = useNavigate()
  const [filters, setFilters] = useState<FieldQueryOptions>({})
  const [baseFilters, setBaseFilters] = useState<FieldQueryOptions>({})

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([])
  const clearSelectedRowIds = () => setSelectedRowIds([])

  const fields = useBatchedFields(selectedRowIds)

  const { setDeleteModal, setLabelModal, setAssignFieldsModal, LAND_MANAGER_ROUTES } =
    useLandManagement()

  const handleSetSelectedRowIds = (ids: GridRowId[]) => {
    setSelectedRowIds(ids as string[])
  }
  const handleDeselectRowIds = (ids: GridRowId[]) => {
    setSelectedRowIds(selectedRowIds.filter(id => !ids.includes(id)) || [])
  }

  const selectedFields = fields.data?.filter(Boolean) || []

  const ownedByUsers = uniq(
    selectedFields ? selectedFields.filter(Boolean).map(f => f.ownedBy?.userId) : []
  )

  return (
    <PaginatedFieldsContext.Provider
      value={{
        filters,
        setFilters,
        hideFilters,
        baseFilters,
        setBaseFilters,

        isReady: !fields.isPending,

        selectedFields,
        ownedByUsers,
        selectedRowIds,
        setSelectedRowIds: handleSetSelectedRowIds,
        deselectRowIds: handleDeselectRowIds,
        clearSelectedRowIds,

        page,
        setPage,

        pageSize,
        setPageSize,

        onDelete: (fieldId?: string) =>
          setDeleteModal({
            open: true,
            selectedIds: fieldId ? [fieldId] : selectedRowIds,
            onSuccess: clearSelectedRowIds,
          }),

        onLabel: () => setLabelModal({ open: true, selectedIds: selectedRowIds }),
        onAssignFields: () => setAssignFieldsModal({ open: true, selectedIds: selectedRowIds }),
        onAddInformation: () =>
          navigate(LAND_MANAGER_ROUTES.PROFILE_FIELDS, {
            state: { profileFieldIds: selectedRowIds },
          }),
      }}
    >
      {children}
    </PaginatedFieldsContext.Provider>
  )
}
