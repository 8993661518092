'eqipPracticeChoiceEngineeringWaterControlStructures'
import { GeneralResourceDetail } from '../ResourceDetail'

/**
 * !IMPORTANT
 *
 * When updating the list of practices, make sure to ALSO update the Contentful
 * entry associated with this resource detail.
 *
 * https://app.contentful.com/spaces/mqztk29gethb/entries/4lKlHnCuqXsFzh1EIJoiFR
 *
 */
export const EQIP_PRACTICE_CHOICE_ENGINERING_WATER_CONTROL_STRUCTURES = [
  '442',
  '430',
  '642',
  '441',
  '587',
  '410',
  '638',
  '356',
  '428',
  '436',
  '468',
  '646',
  '606',
  '447',
  '362',
  '574',
  '520',
  '521',
  '636',
  '582',
  '554',
  '388',
  '443',
  '350',
  '557',
  '402',
  '522',
  '533',
  '607',
  '640',
  '320',
  '348',
  '396',
  '397',
  '432',
  '608',
  'none',
] as const
export type EqipPracticeChoiceEngineeringWaterControlStructures = typeof EQIP_PRACTICE_CHOICE_ENGINERING_WATER_CONTROL_STRUCTURES[number]

export type RDEqipPracticeChoiceEngineeringWaterControlStructures = GeneralResourceDetail<
  EqipPracticeChoiceEngineeringWaterControlStructures[],
  'eqipPracticeChoiceEngineeringWaterControlStructures'
>
