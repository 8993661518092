import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEY } from './queryKey'

import { Field, FieldModel, FieldsAPI, MpxResponseError, ResourceDetailsUpdate } from '@cibo/core'
import { logRequestError } from '@cibo/ui'
import { AxiosError } from 'axios'
import { PROGRAMS_QUERY_KEY } from '../types'
import { BATCHED_FIELD_QUERY_KEY } from './useBatchedFields'

export const useUpdateMultipleFields = () => {
  const queryClient = useQueryClient()

  return useMutation<any, AxiosError<MpxResponseError>, ResourceDetailsUpdate[]>({
    mutationKey: [QUERY_KEY.UPDATE_MULTIPLE_FIELDS],
    mutationFn: (updates: ResourceDetailsUpdate[]) =>
      !!updates && !!updates.length ? FieldsAPI.updateMultipleFields(updates) : Promise.resolve(),
    onSuccess: event => {
      const updatedFields = event.data?.items

      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.OPPORTUNITIES] })
      updatedFields.forEach((field: Field) => {
        queryClient.setQueryData([BATCHED_FIELD_QUERY_KEY, field.resourceId], new FieldModel(field))
      })
    },
    onError: (error, variables, context) => {
      logRequestError(error, { query: 'useUpdateMultipleFields', variables, context })
      return error
    },
  })
}
