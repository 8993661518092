import { FERTILIZER_COMPONENTS, RDFertilizerEvent } from '@cibo/core'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Alert, Paper, Stack, Typography } from '@mui/material'
import { useField, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FertilizerEventEditorProps } from './FertilizerEventTypes'

type KeyType = keyof RDFertilizerEvent

export const FertilizerEventFeedback = ({
  name,
  saveButtons,
}: Pick<FertilizerEventEditorProps, 'name'> & {
  saveButtons: React.ReactElement
}) => {
  const { t } = useTranslation('@cibo/landmanager/FertilizerEditor')
  const [, field, { setTouched }] = useField<
    RDFertilizerEvent & { rateProxy?: number; unitProxy?: string }
  >(name)

  const { values, dirty, touched, errors } = useFormikContext<
    RDFertilizerEvent & { rateProxy?: number; unitProxy?: string }
  >()

  const [wasLegacyValue, setWasLegacyValue] = useState<boolean>()

  useEffect(() => {
    if (values?.product && (values?.rate || values?.units)) {
      setWasLegacyValue(true)
      setTouched(true, true)
    }
  }, [])

  return (
    <>
      {wasLegacyValue && (
        <Alert severity="info">
          <Typography>{t('legacyWarning')}</Typography>
          <ul>
            {(['rate', 'units', ...FERTILIZER_COMPONENTS] as KeyType[]).map(
              a =>
                !!field.value &&
                !!field.value[a] && (
                  <li>{`${
                    FERTILIZER_COMPONENTS.find(component => component === a)
                      ? t(`fertilizerComponent_${a}`)
                      : t(a)
                  } - ${t(field.value[a] as string)}`}</li>
                )
            )}
          </ul>
        </Alert>
      )}

      {values.nitrogenAmnt && (values.nitrogenAmnt > 400 || dirty || touched) ? (
        <>
          <Typography variant="caption">{t('requiredFields')}</Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Paper variant="outlined">
              <Stack p={2} spacing={2}>
                <Stack spacing={2} direction="row">
                  <InfoOutlined color="info" />
                  <Typography>
                    <Trans
                      i18nKey={'@cibo/landmanager/FertilizerEditor:fertilizerNitrogenSummary'}
                      values={{
                        nitrogenAmnt: values.nitrogenAmnt,
                      }}
                    >
                      {/* if this event has been touched, display the below text */}
                      This fertilizer event applied
                      <Typography color="info" component="strong">
                        #lbs of nitrogen per acre
                      </Typography>
                      to this field.
                    </Trans>
                  </Typography>
                </Stack>
                {values.nitrogenAmnt > 400 && (
                  <Alert severity="warning">{t('maxNitrogenWarning')}</Alert>
                )}
                {saveButtons}
              </Stack>
            </Paper>
          </Stack>
        </>
      ) : (
        <Stack spacing={1}>
          {Object.values(errors).length === 1 &&
            !!errors.nitrogenAmnt &&
            values.type === 'other' &&
            values.nitrogenPer !== undefined && (
              <Alert severity="error">{t('nitrogenValueIsZero')}</Alert>
            )}
          <Stack direction="row" alignItems="end" justifyContent="space-between">
            <Typography variant="caption">{t('requiredFields')}</Typography>
            {saveButtons}
          </Stack>
        </Stack>
      )}
    </>
  )
}
