import { UserRef } from '@cibo/core'
import { Typography } from '@mui/material'
import {
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import { FieldColumnProps } from './types'

export const fieldGrowerColumn = ({ t, ...columnProps }: FieldColumnProps) => ({
  // API sorts based on 'grower' param
  field: 'grower',
  headerName: t('Farmer'),
  flex: 1,
  minWidth: 200,
  valueGetter: (a: GridValueGetterParams<{ ownedBy: UserRef }>) => a.row.ownedBy,
  valueFormatter: ({ value }: GridValueFormatterParams<UserRef | undefined>) =>
    !!value ? value.label : null,
  renderCell: ({ row }: GridRenderCellParams<any, string, any>) => {
    return <Typography data-testid="field-grower-cell">{row.ownedBy.label}</Typography>
  },
  ...columnProps,
})
