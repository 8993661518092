import {
  EnrollmentMessageRequestParams,
  FieldModel,
  MAX_FIELD_PAGE_SIZE,
  PaginationResponse,
  WorkflowItem,
  WorkflowQueryParams,
  WorkflowRequest,
  WorkflowRequestParams,
  WorkflowsAPI,
} from '@cibo/core'
import { QUERY_KEY as LAND_MANAGER_QUERY_KEY } from '@cibo/landmanager'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { PROGRAMS_QUERY_KEY } from './queryKey'

export const useWorkflows = (
  page: number,
  pageSize: number,
  params: WorkflowRequestParams = {}
) => {
  return useQuery<PaginationResponse<WorkflowItem>>({
    queryKey: [PROGRAMS_QUERY_KEY.WORKFLOWS, params],
    queryFn: async () => {
      const finalParams = {
        includeEngagementWorkflows: true,
        ...params,
      } as WorkflowRequestParams

      const results = await WorkflowsAPI.getWorkflows({
        // limit: pageSize,
        // offset: page,
        params: finalParams,
      })

      return {
        ...results,
        //@todo remove when this endpoint has pagination param
        numAvailable: results.numItems,
      } as PaginationResponse<WorkflowItem>
    },
  })
}

export const useWorkflow = (workflowId?: string) =>
  useQuery<WorkflowItem>({
    queryKey: [PROGRAMS_QUERY_KEY.WORKFLOW, workflowId],
    queryFn: async () => {
      // @ts-ignore tsc doesn't know this would be disabled for !workflowId
      const results = await WorkflowsAPI.getWorkflow(workflowId)

      return {
        ...results,
      } as WorkflowItem
    },
    enabled: !!workflowId,
  })

export const usePaginatedWorkflowFields = (
  workflowId?: string,
  { offset, limit } = { offset: 0, limit: MAX_FIELD_PAGE_SIZE },
  enabled = true
) => {
  return useQuery<PaginationResponse<FieldModel>>({
    queryKey: [PROGRAMS_QUERY_KEY.WORKFLOW_FIELDS, workflowId, limit, offset],
    queryFn: async () => {
      if (!workflowId) throw new Error('no workflow id')
      const results = await WorkflowsAPI.getWorkflowFields(workflowId, {
        offset,
        limit,
      } as WorkflowQueryParams)

      return {
        ...results,
      } as PaginationResponse<FieldModel>
    },
    enabled: enabled && !!workflowId,
  })
}

export const useCreateWorkflow = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (workflow: WorkflowRequest) => WorkflowsAPI.createWorkflow(workflow),
    onSuccess: res => {
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAMS] })
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.WORKFLOW] })
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.WORKFLOW, res.data.id] })
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.WORKFLOWS] })
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.WORKFLOW_FIELDS] })
      queryClient.invalidateQueries({ queryKey: [LAND_MANAGER_QUERY_KEY.FIELDS_PAGINATED] })
      queryClient.invalidateQueries({ queryKey: [LAND_MANAGER_QUERY_KEY.FIELD_STATS] })
    },
  })
}
/**
 * @deprecated The method should not be used
 * https://cibotech.atlassian.net/browse/CPD-2071
 */
export const useUpdateWorkflow = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      workflowId,
      updates,
    }: {
      workflowId: string
      updates: Partial<Pick<WorkflowRequest, 'fields' | 'name' | 'benchmark'>> & {
        action: 'merge' | 'replace' | 'remove'
      }
    }) => WorkflowsAPI.updateWorkflow(workflowId, updates),

    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAMS] })
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.WORKFLOWS] })
      queryClient.invalidateQueries({
        queryKey: [PROGRAMS_QUERY_KEY.WORKFLOW_FIELDS, variables.workflowId],
      })
      queryClient.invalidateQueries({
        queryKey: [PROGRAMS_QUERY_KEY.WORKFLOW, variables.workflowId],
      })
      queryClient.invalidateQueries({ queryKey: [LAND_MANAGER_QUERY_KEY.FIELDS_PAGINATED] })
      queryClient.invalidateQueries({ queryKey: [LAND_MANAGER_QUERY_KEY.FIELD_STATS] })
    },
  })
}

export const useDeleteWorkflow = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (workflowId: string) => WorkflowsAPI.deleteWorkflow(workflowId),
    onSuccess: (data, workflowId) => {
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAMS] })
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.WORKFLOWS] })
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS] })
      queryClient.invalidateQueries({ queryKey: [LAND_MANAGER_QUERY_KEY.FIELDS_PAGINATED] })
      queryClient.invalidateQueries({ queryKey: [LAND_MANAGER_QUERY_KEY.FIELD_STATS] })
      // At present, invalidating these queries on delete causes an immediate refetch on both, which fails.
      // If you strongly want to invalidate these queries, please ensure that it's not cuasing a re-fetch.
      // queryClient.invalidateQueries({ queryKey:[QUERY_KEY.WORKFLOW_FIELDS, workflowId]})
      // queryClient.invalidateQueries({ queryKey:[QUERY_KEY.WORKFLOW, workflowId]})
    },
  })
}

export const useSendEnrollmentMessage = () =>
  useMutation({
    mutationFn: (params: EnrollmentMessageRequestParams) =>
      WorkflowsAPI.sendEnrollmentMessage(params),
  })
