import { Benchmark, BenchmarkStatus, BenchmarkStatusReason } from '@cibo/core'
import { useBatchedField } from '@cibo/landmanager'
import { ResponsiveDialog } from '@cibo/ui'
import Close from '@mui/icons-material/Close'
import {
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack,
  chipClasses,
} from '@mui/material'
import { PropsWithChildren, createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BenchmarkStatusReasons } from './BenchmarkStatusReasons'
import { statusColor, statusIcon } from './benchmarkStyles'

export interface IBenchmarkModalContext {
  showBenchmarkModal: (
    programId: string,
    benchmark: Benchmark,
    fieldId: string,
    DialogActions?: BenchmarkStatusModalStateDialogActions
  ) => void
}

const BenchmarkModalContext = createContext<IBenchmarkModalContext>({
  showBenchmarkModal: () => null,
})
BenchmarkModalContext.displayName = 'BenchmarkModalContext'

export type BenchmarkStatusModalStateDialogActions = React.JSXElementConstructor<{
  resourceId: string
  benchmark: Benchmark
  programId: string
  onCloseDialog: () => void
}>

type BenchmarkStatusModalState = {
  programId: string
  benchmark: Benchmark
  fieldId: string
  DialogActions?: BenchmarkStatusModalStateDialogActions
}

export const BenchmarkStatusModalProvider = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation('@cibo/programs/BenchmarkStatus')
  const [modalState, setModalState] = useState<BenchmarkStatusModalState | undefined>(undefined)

  const { data: fieldModel, isPending } = useBatchedField(modalState?.fieldId)

  const benchmarkStatus =
    modalState && fieldModel?.getBenchmarkStatus(modalState.programId, modalState.benchmark)

  const reasons: BenchmarkStatusReason[] | undefined = benchmarkStatus?.reasons
  const status: BenchmarkStatus | undefined = benchmarkStatus?.status

  const showBenchmarkModal = (
    programId: string,
    benchmark: Benchmark,
    fieldId: string,
    DialogActions?: BenchmarkStatusModalStateDialogActions
  ) => {
    setModalState({
      programId,
      benchmark,
      fieldId,
      DialogActions,
    })
  }

  return (
    <BenchmarkModalContext.Provider value={{ showBenchmarkModal }}>
      {children}

      <ResponsiveDialog open={!!modalState} onClose={() => setModalState(undefined)}>
        <DialogTitle data-testid="benchmark-modal-title">
          <Stack alignItems="center" justifyContent="space-between" direction="row">
            {isPending || !status ? (
              <Skeleton variant="text" width={100} />
            ) : (
              <Chip
                color={statusColor[status]}
                icon={statusIcon[status]}
                label={t(status, { context: modalState?.benchmark })}
                sx={{
                  [`& .${chipClasses.icon}`]: {
                    color: 'white',
                  },
                  [`& .${chipClasses.label}`]: {
                    color: 'white',
                  },
                }}
              />
            )}
            <IconButton onClick={() => setModalState(undefined)}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          {isPending && (
            <>
              <Skeleton variant="text" width={120} />
              <Skeleton variant="text" width={180} />
              <Skeleton variant="text" width={110} />
              <Skeleton variant="text" width={190} />
              <Skeleton variant="text" width={120} />
              <Skeleton variant="text" width={160} />
            </>
          )}
          {!!modalState && !!reasons && (
            <BenchmarkStatusReasons
              reasons={reasons.filter(({ reason }) =>
                status === 'incomplete'
                  ? ['missingData', 'needsFile'].includes(reason)
                  : reason !== 'missingData'
              )}
            />
          )}
        </DialogContent>
        {modalState?.DialogActions && (
          <DialogActions>
            <modalState.DialogActions
              resourceId={modalState.fieldId}
              benchmark={modalState.benchmark}
              programId={modalState.programId}
              onCloseDialog={() => setModalState(undefined)}
            />
          </DialogActions>
        )}
      </ResponsiveDialog>
    </BenchmarkModalContext.Provider>
  )
}

export const useBenchmarkModal = () => useContext(BenchmarkModalContext)
