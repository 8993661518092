import {
  DetailRequirement,
  GeneralResourceDetail,
  MpxResponseError,
  ProgramEngagementsAPI,
  ResourceDetailDeleteRequest,
  ResourceDetailsUpdate,
} from '@cibo/core'
import { UseMutationOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { PROGRAMS_QUERY_KEY } from '../queries'

export const useProgramEngagementDetails = <
  T extends GeneralResourceDetail = GeneralResourceDetail
>({
  detailRequirements,
  resourceId,
}: {
  detailRequirements?: DetailRequirement<T>[]
  resourceId?: string
}) =>
  useQuery({
    queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENT_DETAILS, resourceId],
    // @ts-ignore not enabled if there's no resource id
    queryFn: () => ProgramEngagementsAPI.getDetails<T>({ resourceId }),
    select: response => {
      return response.details?.filter(({ traitId, year }) =>
        detailRequirements
          ? !!detailRequirements.find(req => req.traitId === traitId && req.year === year)
          : true
      )
    },
    enabled: !!resourceId,
  })

export const useUpdateProgramEngagementDetails = <
  T extends GeneralResourceDetail = GeneralResourceDetail
>(
  props?: Pick<
    UseMutationOptions<unknown, AxiosError<MpxResponseError>, ResourceDetailsUpdate<T>, unknown>,
    'onSuccess' | 'onError'
  > & { engagementId: string }
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: ResourceDetailsUpdate) => ProgramEngagementsAPI.updateDetails(params),

    onError: props?.onError,
    onSuccess: (event, updates) => {
      const engagementId = updates.resourceId

      queryClient.setQueryData([PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENT_DETAILS, engagementId], event)

      queryClient.invalidateQueries({
        queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, engagementId],
      })
    },
  })
}

export const useRemoveProgramEngagementDetail = (
  props?: Pick<
    UseMutationOptions<unknown, AxiosError<MpxResponseError>, ResourceDetailDeleteRequest, unknown>,
    'onSuccess' | 'onError'
  >
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (request: ResourceDetailDeleteRequest) =>
      ProgramEngagementsAPI.removeDetail(request),

    onError: props?.onError,
    onSuccess: (event, updates) => {
      //invalidate queries with this resourceId
      queryClient.invalidateQueries({
        queryKey: [
          PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS,
          // see useFields
          updates.resourceId,
        ],
      })
    },
  })
}
