import { UserId } from '@cibo/core'
import { PaginatedFieldsContext } from '@cibo/landmanager'
import { useOrgUser } from '@cibo/profile'
import { sum, uniq } from 'ramda'
import { PropsWithChildren, createContext, useContext, useMemo } from 'react'
import {
  usePaginatedWorkflowFields,
  useProgram,
  useProgramEngagementFields,
  useProgramStats,
  useWorkflow,
  useWorkflows,
} from '../../queries'
import { LimitDefinition, evaluateLimitsByPriority } from './utils/programLimitUtils'

interface ProgramLimitContextProps {
  isReady: boolean
  display: boolean
  limits?: [LimitDefinition, LimitDefinition] | [LimitDefinition]
  programId: string
  workflowId: string
  userId?: UserId
  prevent: boolean
}

export const ProgramLimitContext = createContext<ProgramLimitContextProps>({
  isReady: false,
  display: false,
  programId: '',
  workflowId: '',
  prevent: false,
})
ProgramLimitContext.displayName = 'ProgramLimitContext'

export const ProgramLimitProvider = ({
  programId,
  workflowId,
  userId,
  children,
  orgId,
}: PropsWithChildren<{
  programId: string
  workflowId: string
  userId?: UserId
  orgId?: string
}>) => {
  const { isPending: workflowLoading, data: workflow } = useWorkflow(workflowId)
  const { isPending: statsLoading, data: stats } = useProgramStats({ userId })
  const { isPending: programLoading, data: program } = useProgram({ programId })
  const { isPending: workflowFieldsLoading, data: workflowFieldsData } =
    usePaginatedWorkflowFields(workflowId)
  const workflowAcreageTotal = useMemo(
    () => sum(workflowFieldsData?.items.map(f => f?.metadata.acreage) || [0]),
    [workflowFieldsData]
  )

  const actingLimits = evaluateLimitsByPriority({
    stats,
    workflowAcreageTotal,
    workflowFieldcountTotal: workflow?.fieldCounts.total || 0,
    orgId,
    program,
    programId,
  })

  const display = actingLimits ? !!actingLimits?.find(a => a.display) : false

  return (
    <ProgramLimitContext.Provider
      value={{
        isReady: !programLoading && !workflowLoading && !workflowFieldsLoading && !statsLoading,
        display,
        limits: display ? actingLimits : undefined,
        programId,
        workflowId,
        userId,
        prevent: !!actingLimits?.find(limit => limit.display && limit.limitType === 'noSpace'),
      }}
    >
      {children}
    </ProgramLimitContext.Provider>
  )
}

export const useProgramLimit = () => useContext(ProgramLimitContext)

export const SelectedFieldsProgramLimitContext = createContext<ProgramLimitContextProps>({
  isReady: false,
  display: false,
  programId: '',
  workflowId: '',
  prevent: false,
})
SelectedFieldsProgramLimitContext.displayName = 'SelectedFieldsProgramLimitContext'

export const SelectedFieldsProgramLimitProvider = ({
  programId,
  children,
}: PropsWithChildren<{
  programId: string
}>) => {
  const { selectedFields } = useContext(PaginatedFieldsContext)
  const ownedByUsers = uniq(selectedFields.filter(Boolean).map(f => f.ownedBy?.userId))
  const { isPending: statsLoading, data: stats } = useProgramStats({
    userId: ownedByUsers[0],
  })
  const { isPending: userIsLoading, data: user } = useOrgUser(ownedByUsers[0])
  const { isPending: programLoading, data: program } = useProgram({ programId })

  const { isPending: workflowLoading, data: workflow } = useWorkflows(0, 1, {
    programId,
    filters: { userId: ownedByUsers[0] },
    benchmark: ['enrollment'],
  })

  const workflowFields = usePaginatedWorkflowFields(
    workflow?.items[0]?.id,
    undefined,
    !!(program?.rulesVersion === 'v1')
  )
  const engagementFields = useProgramEngagementFields({
    id: program?.rulesVersion === 'v2' ? workflow?.items[0]?.id : undefined,
  })

  const fields =
    program?.rulesVersion === 'v2'
      ? { ...engagementFields, data: { items: engagementFields.data } }
      : workflowFields

  const workflowAcreageTotal = useMemo(
    () =>
      sum([...selectedFields, ...(fields.data?.items || [])].map(f => f?.metadata.acreage) || [0]),
    [fields]
  )

  const actingLimits = evaluateLimitsByPriority({
    stats,
    workflowAcreageTotal,
    workflowFieldcountTotal:
      selectedFields.length + (workflow ? workflow?.items[0]?.fieldCounts.total : 0) || 0,
    orgId: user?.orgId,
    program,
    programId,
  })

  const display =
    ownedByUsers.length === 1 && actingLimits && selectedFields.length > 0
      ? !!actingLimits?.find(limit => limit.display)
      : false

  return (
    <SelectedFieldsProgramLimitContext.Provider
      value={{
        isReady:
          !userIsLoading &&
          !programLoading &&
          !workflowLoading &&
          !fields.isLoading &&
          !statsLoading,
        display,
        limits: display ? actingLimits : undefined,
        programId,
        workflowId: workflow?.items[0]?.id || '',
        userId: ownedByUsers[0],
        prevent: !!actingLimits?.find(limit => limit.display && limit.limitType === 'noSpace'),
      }}
    >
      {children}
    </SelectedFieldsProgramLimitContext.Provider>
  )
}

export const useSelectedFieldsProgramLimit = () => useContext(SelectedFieldsProgramLimitContext)
