/* istanbul ignore file */
import { FieldModel } from '../models/FieldModel'
import { Field, PaginationRequest, PaginationResponse, UserId } from '../types'
import {
  Benchmark,
  WorkflowItem,
  WorkflowRequest,
  WorkflowSortFields,
} from '../types/WorkflowTypes'
import { generatePath } from '../utils'
import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  MESSAGE_ENROLL: '/workflows/:workflowId/msgEnroll',
  WORKFLOWS: '/workflows',
  WORKFLOWS_FOR_USER: '/workflows/forUser',
  WORKFLOW: '/workflows/:workflowId',
  WORKFLOW_FIELDS: '/workflows/:workflowId/fields',
}

export interface EnrollmentMessageRequestParams {
  customMessage?: string
  workflowId: string
}
export interface WorkflowRequestParams {
  programId?: string
  benchmark?: Benchmark[]
  filters?: {
    userId?: UserId | UserId[]
  }
  includeEngagementWorkflows?: boolean
  includeAll?: boolean
}

export type WorkflowQueryParams = PaginationRequest<WorkflowSortFields> & {
  params?: WorkflowRequestParams
}

// backend requires a stringified+urlencoded object for filters
// CPD-8230 will change this to a more readable encoding
const userFiltersWorkaround = (params: any) => {
  if (params.filters?.userId) {
    const encodedUserIdFilter = {
      userId: Array.isArray(params.filters?.userId)
        ? `${params.filters?.userId.join(',')}`
        : `${params.filters?.userId}`,
    }

    return {
      ...params,
      filters: JSON.stringify({
        ...params.filters,
        ...encodedUserIdFilter,
      }),
    }
  }

  return params
}

export class WorkflowsAPI {
  static getWorkflows({
    params: { benchmark, ...otherParams } = {},
    ...paginationParams
  }: WorkflowQueryParams) {
    const params = {
      ...paginationParams,
      benchmark: benchmark?.join(','),
      ...otherParams,
    }

    return agent
      .get<PaginationResponse<WorkflowItem>>(PATHS.WORKFLOWS, {
        params: userFiltersWorkaround(params),
      })
      .then(response => response && response.data)
  }

  static getWorkflow(workflowId: string) {
    return agent
      .get<WorkflowItem>(generatePath(PATHS.WORKFLOW, { workflowId }))
      .then(response => response && response.data)
  }

  static getWorkflowFields(workflowId: string, params: WorkflowQueryParams) {
    return agent
      .get<PaginationResponse<Field>>(generatePath(PATHS.WORKFLOW_FIELDS, { workflowId }), {
        params,
      })
      .then(response => {
        return (
          response && {
            ...response.data,
            items: response.data?.items.map((r: Field) => new FieldModel(r)),
          }
        )
      })
  }

  static createWorkflow(workflow: WorkflowRequest) {
    return agent.post<WorkflowItem>(PATHS.WORKFLOWS, workflow)
  }

  static createWorkflowForUser({ name, ...workflow }: WorkflowRequest) {
    //throw away the name for now
    return agent.post<WorkflowItem>(PATHS.WORKFLOWS_FOR_USER, workflow)
  }

  static updateWorkflow(
    workflowId: string,
    update: Partial<Pick<WorkflowRequest, 'fields' | 'name'>> & {
      action: 'merge' | 'replace' | 'remove'
    }
  ) {
    return agent.put<WorkflowItem>(generatePath(PATHS.WORKFLOW, { workflowId }), update)
  }

  static deleteWorkflow(workflowId: string) {
    return agent.delete(generatePath(PATHS.WORKFLOW, { workflowId }))
  }

  static sendEnrollmentMessage(params: EnrollmentMessageRequestParams) {
    return agent.post(generatePath(PATHS.MESSAGE_ENROLL, { workflowId: params.workflowId }))
  }
}
