export const QUERY_KEY = {
  ALL_FILES: 'all_files',
  ALL_FIELDS: 'all_fields',
  CARBON_COMPARISON_JOB: 'carbonComparisonJob',
  CARBON_COMPARISON_PREVIOUS: 'carbonLab.comparison.previousResult',
  CARBON_COMPARISON: 'carbonLab.comparison',
  COLLECTION: 'land_manager.collection',
  FARM: 'farm',
  FARMS: 'farms',
  FARMS_SEARCH: 'farms_search',
  FSA_FARM: 'fsa_farm',
  FSA_FARMS: 'fsa_farms',
  FSA_FARMS_SEARCH: 'fsa_farms_search',
  FEATURES: 'land_manager.collection.features',
  FIELD_ASSIGN_USER: 'field_assign_user',
  FIELD_CONFIRM_IMPORT: 'field_confirm_import',
  FIELD_CREATE: 'field_create',
  FIELD_DELETE: 'field_delete',
  FIELD_LABEL_SEARCH: 'field_label_search',
  FIELDS_PAGINATED: 'fields_paginated',
  FIELDS_PAGINATED_FIELD_COUNT: 'fields_paginated_field_count',
  FIELD_STATS: 'field_stats',
  SAVE_DETAIL: 'save_detail',
  SAVE_DETAIL_FILE: 'save_detail_file',
  REMOVE_DETAIL: 'remove_detail',
  SAVE_BULK_DETAILS: 'save_bulk_details',
  LABELS_SEARCH: 'labels_search',
  LABELS: 'labels',
  ORGANIZATION_GROWER_CONTACTS: 'org_grower_contacts',
  ORGANIZATION_GROWER_CONTACTS_FIELDS: 'org_grower_contacts_fields',
  SPACES: 'land_manager.spaces',
  PRACTICES_CONTENT: 'practices_content',
  RESOURCE_CONCERN_DEFINITION: 'usdaResourceConcern',
  UPDATE_MULTIPLE_FIELDS: 'updateMultipleFields',
}
