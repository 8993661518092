import {
  DetailRequirement,
  RDAgronomicDataCSV,
  RDAmendment,
  RDAmendmentSummary,
  RDBiological,
  RDBiomassBurning,
  RDCashCrop,
  RDConversionDate,
  RDCornNitrogenApplication,
  RDCoverCrop,
  RDEqipEligibilityConservationACEP,
  RDEqipEligibilityConservationCRP,
  RDEqipEligibilityConservationCSP,
  RDEqipEligibilityFarmInfo,
  RDEqipEligibilityLandControl,
  RDEqipEligibilityWetlandCompliance,
  RDEqipPracticesIrrigationMethod,
  RDEqipPracticesIrrigationQuantity,
  RDEventIrrigation,
  RDFarmManagementSystem,
  RDFertilizer,
  RDFertilizerReduction,
  RDFutureCashCrop,
  RDFutureCoverCrop,
  RDFutureLivestockChange,
  RDFutureTillage,
  RDGrazing,
  RDIrrigation,
  RDLiming,
  RDLivestock,
  RDNativeConversion,
  RDNaturalDisturbance,
  RDOffer,
  RDOwnership,
  RDShipmentDistance,
  RDTillage,
  RDWetlandDesignation,
  RDXtremeAgCode,
  TraitId,
} from '@cibo/core'
import { AgronomicDataCSV } from './AgronomicDataCSV'
import { Amendment } from './Amendment'
import { AmendmentSummary } from './AmendmentSummary'
import { Biological } from './Biological'
import { BiomassBurning } from './BiomassBurning'
import { CashCropInput, CashCropResult } from './CashCrop'
import { CornNitrogenApplication } from './CornNitrogenApplication'
import { CoverCropInput, CoverCropResult } from './CoverCrop'
import {
  ConservationACEP,
  ConservationCRP,
  ConservationCSP,
  EqipIrrigationMethod,
  EqipIrrigationQuantity,
} from './Eqip'
import { LandControl } from './Eqip/LandControl'
import { WetlandCompliance } from './Eqip/WetlandCompliance'
import { EventIrrigation } from './EventIrrigation'
import { FarmManagementSystem } from './FarmManagementSystem'
import { Fertilizer } from './Fertilizer'
import { FertilizerReduction } from './FertilizerReduction'
import { FutureCashCropInput, FutureCashCropResult } from './FutureCashCrop'
import { FutureCoverCropInput, FutureCoverCropResult } from './FutureCoverCrop'
import { FutureLivestockChange } from './FutureLivestockChange'
import { FutureTillageInput, FutureTillageResult } from './FutureTillage'
import { Grazing } from './Grazing'
import { IrrigationInput, IrrigationResult } from './Irrigation'
import { Liming } from './Liming'
import { Livestock } from './Livestock'
import { NativeConversionInput, NativeConversionResult } from './NativeConversion'
import { NativeConversionDateInput, NativeConversionDateResult } from './NativeConversionDate'
import { NaturalDisturbance } from './NaturalDisturbance/NaturalDisturbance'
import { Offer } from './Offer'
import { OwnershipInput } from './Ownership'
import { ShipmentDistance } from './ShipmentDistance'
import { TillageInput, TillageResult } from './Tillage'
import { WaterPumpIrrigation } from './WaterPumpIrrigation/WaterPumpIrrigation'
import { RDWaterPumpIrrigation } from './WaterPumpIrrigation/types'
import { WetlandDesignation } from './WetlandDesignation'
import { XtremeAgCode } from './XtremeAgCode'
import { ITraitFeatureById, ITraitFeatureByIdYear } from './types'

const TraitFeaturesById: Partial<
  Record<
    TraitId,
    Partial<
      Record<
        'input' | 'result',
        | ITraitFeatureById<RDConversionDate>
        | ITraitFeatureById<RDOwnership>
        | ITraitFeatureById<RDShipmentDistance>
        | ITraitFeatureById<RDWetlandDesignation>
        | ITraitFeatureById<RDXtremeAgCode>
        | ITraitFeatureById<RDNativeConversion>
      >
    >
  >
> = {
  includesWetlandDesignation: {
    result: WetlandDesignation,
    input: WetlandDesignation,
  },
  ownership: {
    input: OwnershipInput,
    result: OwnershipInput,
  },
  nativeVegetationConversionDate: {
    input: NativeConversionDateInput,
    result: NativeConversionDateResult,
  },
  nativeVegetationConversion: {
    input: NativeConversionInput,
    result: NativeConversionResult,
  },
  xtremeAgCode: {
    input: XtremeAgCode,
    result: XtremeAgCode,
  },
  shipmentDistance: {
    input: ShipmentDistance,
    result: ShipmentDistance,
  },
}

const TraitFeaturesByIdYear: Partial<
  Record<
    TraitId,
    Record<
      'input' | 'result',
      | ITraitFeatureByIdYear<RDAgronomicDataCSV>
      | ITraitFeatureByIdYear<RDAmendment>
      | ITraitFeatureByIdYear<RDAmendmentSummary>
      | ITraitFeatureByIdYear<RDBiological>
      | ITraitFeatureByIdYear<RDBiomassBurning>
      | ITraitFeatureByIdYear<RDCashCrop>
      | ITraitFeatureByIdYear<RDCornNitrogenApplication>
      | ITraitFeatureByIdYear<RDCoverCrop>
      | ITraitFeatureByIdYear<RDEqipEligibilityConservationACEP>
      | ITraitFeatureByIdYear<RDEqipEligibilityConservationCRP>
      | ITraitFeatureByIdYear<RDEqipEligibilityConservationCSP>
      | ITraitFeatureByIdYear<RDEqipEligibilityFarmInfo>
      | ITraitFeatureByIdYear<RDEqipEligibilityLandControl>
      | ITraitFeatureByIdYear<RDEqipEligibilityWetlandCompliance>
      | ITraitFeatureByIdYear<RDEqipPracticesIrrigationMethod>
      | ITraitFeatureByIdYear<RDEqipPracticesIrrigationQuantity>
      | ITraitFeatureByIdYear<RDEventIrrigation>
      | ITraitFeatureByIdYear<RDFarmManagementSystem>
      | ITraitFeatureByIdYear<RDFertilizer>
      | ITraitFeatureByIdYear<RDFertilizerReduction>
      | ITraitFeatureByIdYear<RDFutureCashCrop>
      | ITraitFeatureByIdYear<RDFutureCoverCrop>
      | ITraitFeatureByIdYear<RDFutureLivestockChange>
      | ITraitFeatureByIdYear<RDFutureTillage>
      | ITraitFeatureByIdYear<RDGrazing>
      | ITraitFeatureByIdYear<RDIrrigation>
      | ITraitFeatureByIdYear<RDLiming>
      | ITraitFeatureByIdYear<RDLivestock>
      | ITraitFeatureByIdYear<RDNaturalDisturbance>
      | ITraitFeatureByIdYear<RDOffer>
      | ITraitFeatureByIdYear<RDTillage>
      | ITraitFeatureByIdYear<RDWaterPumpIrrigation>
    >
  >
> = {
  amendment: {
    input: Amendment,
    result: Amendment,
  },
  amendmentSummary: {
    input: AmendmentSummary,
    result: AmendmentSummary,
  },
  biological: {
    input: Biological,
    result: Biological,
  },
  biomassBurning: {
    input: BiomassBurning,
    result: BiomassBurning,
  },
  cashCrop: {
    input: CashCropInput,
    result: CashCropResult,
  },
  cornNitrogenApplication: {
    input: CornNitrogenApplication,
    result: CornNitrogenApplication,
  },
  coverCrop: {
    input: CoverCropInput,
    result: CoverCropResult,
  },
  eqipEligibilityLandControl: {
    input: LandControl,
    result: LandControl,
  },
  eqipEligibilityWetlandCompliance: {
    input: WetlandCompliance,
    result: WetlandCompliance,
  },
  eqipPracticesIrrigationMethod: {
    input: EqipIrrigationMethod,
    result: EqipIrrigationMethod,
  },
  eqipEligibilityConservationCRP: {
    input: ConservationCRP,
    result: ConservationCRP,
  },
  eqipEligibilityConservationCSP: {
    input: ConservationCSP,
    result: ConservationCSP,
  },
  eqipEligibilityConservationACEP: {
    input: ConservationACEP,
    result: ConservationACEP,
  },
  eqipPracticesIrrigationQuantity: {
    input: EqipIrrigationQuantity,
    result: EqipIrrigationQuantity,
  },
  eventIrrigation: {
    input: EventIrrigation,
    result: EventIrrigation,
  },
  farmManagementSystem: {
    input: FarmManagementSystem,
    result: FarmManagementSystem,
  },
  fertilizer: {
    //@ts-ignore TODO: fix this
    input: Fertilizer,
    //@ts-ignore TODO: fix this
    result: Fertilizer,
  },
  fertilizerReduction: {
    input: FertilizerReduction,
    result: FertilizerReduction,
  },
  futureCoverCrop: {
    input: FutureCoverCropInput,
    result: FutureCoverCropResult,
  },
  futureCashCrop: {
    input: FutureCashCropInput,
    result: FutureCashCropResult,
  },
  futureLivestockChange: {
    input: FutureLivestockChange,
    result: FutureLivestockChange,
  },
  futureTillage: {
    input: FutureTillageInput,
    result: FutureTillageResult,
  },
  grazing: {
    input: Grazing,
    result: Grazing,
  },
  irrigation: {
    input: IrrigationInput,
    result: IrrigationResult,
  },
  livestock: {
    input: Livestock,
    result: Livestock,
  },
  liming: {
    input: Liming,
    result: Liming,
  },
  offer: {
    //@ts-ignore TODO: fix this
    input: Offer,
    //@ts-ignore TODO: fix this
    result: Offer,
  },
  agronomicDataCsv: {
    input: AgronomicDataCSV,
    result: AgronomicDataCSV,
  },
  tillage: {
    input: TillageInput,
    result: TillageResult,
  },
  naturalDisturbance: {
    input: NaturalDisturbance,
    result: NaturalDisturbance,
  },
  waterPumpIrrigation: {
    input: WaterPumpIrrigation,
    result: WaterPumpIrrigation,
  },
}

export class TraitFeature {
  static forTraitId(traitId?: TraitId, resultsOnly = false) {
    if (!traitId) {
      return undefined
    }

    const traitFeature = TraitFeaturesById[traitId] || TraitFeaturesByIdYear[traitId]

    if (!traitFeature) {
      return undefined
    }

    if (resultsOnly) {
      return traitFeature.result
    }

    return traitFeature.input || traitFeature.result
  }

  static forDetailRequirement({ traitId, resultsOnly }: DetailRequirement) {
    return (
      TraitFeature.forTraitId(traitId, resultsOnly) ??
      ({
        traitId,
        resultsOnly: true,
      } as ITraitFeatureById)
    )
  }
}
