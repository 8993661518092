import {
  FieldModel,
  Integer,
  ResourceDetail,
  ResourceDetailInputBase,
  ResourceDetailsUpdate,
} from '@cibo/core'
import { DataGridPro, Markdown, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Box, Collapse, MenuItem, Select, Stack, styled, Typography } from '@mui/material'
import { GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { assocPath, path, pathEq, propEq } from 'ramda'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fieldColumnAttributes, FieldNameCell } from '../../../components/FieldColumns'
import { useBatchedFields, useUpdateMultipleFields } from '../../../queries'
import { useRemoveDetails, useSaveBulkDetails } from '../../DetailEditing'
import { BooleanRollupInput, BULK_ANSWERS, BulkAnswer } from './BooleanRollupInput'

const StyledDataGridPro = styled(DataGridPro)(() => ({
  '.MuiDataGrid-cell': {
    padding: 0,
    position: 'relative',
  },
  '.MuiSelect': { boxShadow: 'none' },
  '.MuiDataGrid-pinnedRows': {
    backgroundColor: 'unset',
    boxShadow: 'unset',
  },
})) as unknown as typeof DataGridPro

type BooleanAnswerRow = {
  id: string
  field: FieldModel
  answer?: BulkAnswer
  immutable: boolean
}

export type BooleanRollupProps<T extends ResourceDetail = ResourceDetail> = {
  detailKey?: keyof T['input'] | keyof T['result']
  ns: string
  answerValues?: Partial<Record<BulkAnswer, { value: any }>>
  showTableAnswers?: BulkAnswer[]
  rollupAnswers?: BulkAnswer[]
} & ResourceDetailFeatureTaskEditorProps<T>

export const BooleanRollup = <T extends ResourceDetail = ResourceDetail>({
  answerValues = { yes: { value: 'yes' }, no: { value: 'no' }, unknown: { value: 'unknown' } },
  detailRequirements: [{ traitId, year, resultsOnly }],
  ns,
  detailKey,
  onError,
  onSuccess,
  onUpdating,
  resourceIds,
  userRole,
  ownerName,
  showTableAnswers = ['yes'],
  rollupAnswers = ['no', 'unknown'],
}: BooleanRollupProps<T>) => {
  const { t } = useTranslation('@cibo/landmanager/BooleanRollup')
  const gridApi = useGridApiRef()

  const fieldModels = useBatchedFields(resourceIds)
  const [rollupAnswer, setRollupAnswer] = useState<BulkAnswer>()
  const [bulkAnswer, setBulkAnswer] = useState<BulkAnswer | undefined>()
  const updateFields = useUpdateMultipleFields()

  const saveBulkDetails = useSaveBulkDetails()
  const removeDetails = useRemoveDetails()

  const valuePath = useMemo(() => {
    const valuePath: Array<string> = [resultsOnly ? 'result' : 'input']
    if (detailKey) valuePath.push(detailKey as string)
    return valuePath
  }, [detailKey, resultsOnly])

  const detailHasAnswer = useCallback(
    (answer: BulkAnswer) => pathEq(valuePath, answerValues[answer]?.value),
    [valuePath, answerValues]
  )

  const answerForDetail = useCallback(
    (detail: ResourceDetail) => {
      if (!answerValues) return
      const value = path(valuePath, detail)
      if (typeof value === 'undefined') return

      for (const answer of BULK_ANSWERS) {
        if (value === answerValues[answer]?.value) return answer
      }
    },
    [answerValues, valuePath]
  )

  const detailForAnswer = useCallback(
    (answer: BulkAnswer) => {
      const detail = {
        traitId,
        year,
      }

      return assocPath(valuePath, answerValues[answer]?.value)(detail)
    },
    [traitId, year]
  )

  const changeFieldAnswer = useCallback(
    (params: GridRenderCellParams, answer: BulkAnswer | 'remove') => {
      gridApi.current?.updateRows([{ id: params.id, answer: answer === 'remove' ? '' : answer }])

      const answers = new Set<BulkAnswer>()
      const rowData = gridApi.current.getSortedRows()
      rowData.forEach(row => answers.add(row.answer))
      if (answers.size === 1) {
        setBulkAnswer(Array.from(answers)[0])
      } else {
        setBulkAnswer(undefined)
      }

      const template = { traitId, year }

      const newDetail =
        answer === 'remove'
          ? template
          : assocPath(valuePath, answerValues?.[answer]?.value, template)

      const updates = [{ resourceId: `${params.id}`, details: [newDetail] }]

      onUpdating?.()
      updateFields
        .mutateAsync(updates)
        .then(() => onSuccess?.())
        .catch(onError)
    },
    [gridApi.current, updateFields, setBulkAnswer]
  )

  const changeBulkAnswer = useCallback(
    (answer: BulkAnswer | 'remove') => {
      const rowData = gridApi.current.getSortedRows()

      if (answer === 'remove') {
        setBulkAnswer(undefined)
        const fieldsWithDetail: string[] = []

        rowData.forEach(row => {
          if (row.answer !== '') {
            fieldsWithDetail.push(row.id)
            gridApi.current?.updateRows([{ id: row.id, answer: '' }])
          }
        })

        onUpdating?.()
        removeDetails
          .mutateAsync({ resourceIds: fieldsWithDetail, traitId, year: year as Integer })
          .catch(onError)
          .then(onSuccess)
      } else {
        setBulkAnswer(answer)
        const fieldsToUpdate: string[] = []

        rowData.forEach(row => {
          if (row.answer !== answer) {
            gridApi.current?.updateRows([{ id: row.id, answer }])
            fieldsToUpdate.push(row.id)
          }
        })

        onUpdating?.()
        saveBulkDetails
          .mutateAsync({
            resourceIds: fieldsToUpdate,
            details: [detailForAnswer(answer) as ResourceDetail<ResourceDetailInputBase, any>],
          })
          .catch(onError)
          .then(onSuccess)
      }
    },
    [gridApi.current, setBulkAnswer /* removeDetails, saveBulkDetails*/]
  )

  const handleChangeAnswer = useCallback(
    (params: GridRenderCellParams, answer: BulkAnswer | 'remove') => {
      if (params.id === 'bulkAnswerRow') {
        changeBulkAnswer(answer)
      } else {
        changeFieldAnswer(params, answer)
      }
    },
    [changeBulkAnswer, changeFieldAnswer]
  )

  const columns = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: t('field'),
        hideable: false,
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<FieldModel>) => (
          <Box sx={{ marginLeft: 2 }}>
            {params.row.id === 'bulkAnswerRow' ? (
              <Typography sx={{ fontWeight: 'bold', my: 2 }}>{t('answerForAllFields')}</Typography>
            ) : (
              <FieldNameCell {...params} />
            )}
          </Box>
        ),
      },
      {
        field: 'answer',
        headerName: t('answer'),
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Select
              fullWidth
              onChange={event => handleChangeAnswer(params, event.target.value)}
              value={params.value}
              disabled={params.row.immutable}
              data-testid={`booleanSelect-${params.row.id}`}
            >
              {params.row.id === 'bulkAnswerRow' ? (
                <MenuItem value="remove" data-testid="option::removeAll">
                  <Typography variant="caption">{t('removeAllAnswers')}</Typography>
                </MenuItem>
              ) : (
                params.row.answer !== '' && (
                  <MenuItem value="remove" data-testid="option::remove">
                    <Typography variant="caption">{t('remove')}</Typography>
                  </MenuItem>
                )
              )}
              <MenuItem value="yes" data-testid="option::yes">
                {t('yes')}
              </MenuItem>
              <MenuItem value="no" data-testid="option::no">
                {t('no')}
              </MenuItem>
              {answerValues.unknown && <MenuItem value="unknown">{t('unknown')}</MenuItem>}
            </Select>
          )
        },
      },
    ]
  }, [])

  const details = fieldModels.data?.map(field => field.resolveStandingDetail(traitId, year))

  const existingDetails = details?.filter(Boolean)
  const immutableDetails = existingDetails?.filter(detail => detail.immutable)

  // handle first fetch of fields
  useEffect(() => {
    if (!fieldModels.isFetched || !gridApi.current || !fieldModels.data) return

    if (!existingDetails) return
    const allDetailsPresent = existingDetails?.length === resourceIds.length

    if (allDetailsPresent) {
      for (const rollupAnswer of rollupAnswers) {
        // if all details have rollup answer
        if (existingDetails?.every(detailHasAnswer(rollupAnswer))) {
          setRollupAnswer(rollupAnswer)
          return
        }
      }

      const answers = new Set<BulkAnswer>()
      existingDetails.forEach(detail => {
        answers.add(answerForDetail(detail) as BulkAnswer)
      })
      if (answers.size === 1) {
        setBulkAnswer(Array.from(answers)[0])
      }
    }

    // some answer is not the rollup
    for (const detail of existingDetails) {
      const answer = answerForDetail(detail)
      if (answer && !rollupAnswers.includes(answer)) {
        setRollupAnswer(answer)
        return
      }
    }
  }, [fieldModels.isFetched, setBulkAnswer])

  const syncAnswers = (answers: Array<{ resourceId: string; answer: BulkAnswer }>) => {
    if (!fieldModels.isFetched) return

    const template = { traitId, year }

    const updates: ResourceDetailsUpdate[] = []

    answers.forEach(({ resourceId, answer }) => {
      const fieldModel = fieldModels.data?.find(propEq('resourceId', resourceId))
      if (!fieldModel) return

      const detail = fieldModel?.resolveStandingDetail(traitId, year)

      if (detail?.immutable) return

      if (!detailHasAnswer(answer)(detail)) {
        updates.push({
          resourceId: fieldModel.resourceId,
          details: [assocPath(valuePath, answerValues?.[answer]?.value, template)],
        })
      }
    })

    if (updates.length === 0) return

    onUpdating?.()
    updateFields
      .mutateAsync(updates)
      .then(() => onSuccess?.())
      .catch(onError)
  }

  const handleClickRollupAnswer = (newAnswer: BulkAnswer) => () => {
    if (!fieldModels.data) return

    if (fieldModels.data.length === 1 || rollupAnswers.includes(newAnswer)) {
      gridApi.current?.updateRows(
        fieldModels.data.map(({ resourceId }) => ({ id: resourceId, answer: newAnswer }))
      )

      syncAnswers(fieldModels.data.map(({ resourceId }) => ({ resourceId, answer: newAnswer })))
    }

    setRollupAnswer(newAnswer)
  }

  const allImmutable = useMemo(() => {
    return fieldModels.data?.every(({ details }) =>
      details.some(detail => detail.traitId === traitId && detail.year === year && detail.immutable)
    )
  }, [fieldModels?.dataUpdatedAt])

  const showTable =
    rollupAnswer && showTableAnswers.includes(rollupAnswer) && (fieldModels.data?.length ?? 0) > 1

  // disable rollup buttons if some immutable detail disagrees (can't be rolled up)
  const buttonDisabled = rollupAnswers.reduce(
    (acc, rollupAnswer) => ({
      ...acc,
      [rollupAnswer]: immutableDetails?.some(detail => !detailHasAnswer(rollupAnswer)(detail)),
    }),
    {}
  )

  const rows = useMemo(
    () =>
      !fieldModels.data
        ? []
        : fieldModels.data.map(field => {
            const detail = field.resolveStandingDetail(traitId, year)

            return {
              ...fieldColumnAttributes(field),
              answer: answerForDetail(detail) || '',
              immutable: detail?.immutable,
            }
          }),
    [fieldModels.isFetched]
  )

  const isAnyDetailImmutable = !!fieldModels.data?.some(
    field => field.resolveStandingDetail(traitId, year)?.immutable
  )

  return (
    <Stack spacing={3} flex={1}>
      <Markdown
        overrides={{
          em: {
            component: ({ children }: { children: ReactNode }) => (
              <Typography variant="body2" color="text.secondary" component="span">
                {children}
              </Typography>
            ),
          },
        }}
      >
        {t('question', { context: userRole, name: ownerName, ns, count: fieldModels.data?.length })}
      </Markdown>

      <Stack>
        <BooleanRollupInput
          answer={rollupAnswer}
          answerValues={answerValues}
          buttonDisabled={buttonDisabled}
          disabled={!fieldModels.isFetched || allImmutable || updateFields.isPending}
          onChange={handleClickRollupAnswer}
        />

        <Collapse in={showTable}>
          <Stack mt={3} spacing={3}>
            <Markdown>{t('tableCta', { context: userRole, name: ownerName, ns })}</Markdown>
            <StyledDataGridPro<BooleanAnswerRow>
              apiRef={gridApi}
              autoHeight
              columns={columns}
              disableRowSelectionOnClick
              getRowHeight={() => 'auto'}
              hideCellFocus
              rows={rows}
              pinnedRows={
                fieldModels.data?.length && fieldModels.data.length > 1
                  ? {
                      top: [
                        {
                          id: 'bulkAnswerRow',
                          answer: bulkAnswer || '',
                          immutable: isAnyDetailImmutable,
                        },
                      ],
                    }
                  : undefined
              }
            />
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  )
}
