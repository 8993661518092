import { useFigures } from '@cibo/ui'
import { Card, CardContent, CardHeader, styled, Theme, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { statusChipColor } from './StatusUIMaps'
import { AgreementModel } from './models'
import { AgreementStatus, AgreementType } from './types'

const StyledHeader = styled(CardHeader)(
  ({
    agreementType,
    status,
    theme,
  }: {
    theme?: Theme
    agreementType: AgreementType
    status: AgreementStatus
  }) => {
    const themeColor =
      !!status &&
      !!statusChipColor[agreementType || 'dataCollection'][status] &&
      // @ts-ignore statusChipColor[*][status] will provide a string, but ts doesn't agree
      theme?.palette[statusChipColor[agreementType || 'dataCollection'][status]]

    return {
      background: themeColor ? themeColor.main : theme?.palette.divider,
      color: themeColor ? themeColor.contrastText : 'default',
      height: 32,
      padding: 4,
      paddingInlineStart: 16,
    }
  }
)

interface AgreementStatusCardProps {
  agreement: AgreementModel
  concise?: boolean
}

export const AgreementStatusCard = ({ agreement }: AgreementStatusCardProps) => {
  const { t } = useTranslation(`@cibo/impact/AgreementStatus`)
  const { modifiedDate } = useFigures()
  const { agreementId, agreementType, status } = agreement

  const cardDate =
    agreementType === 'dataCollection' || agreementType === 'offer'
      ? agreement.modifiedDate
      : agreementType === 'insights'
      ? agreement.group
      : !!agreement.publicationDateTime && !isNaN(agreement.publicationDateTime.getTime())
      ? agreement.publicationDateTime
      : agreement.modifiedDate

  const isOfferAgreement = agreementType === 'offer'

  return (
    <Card variant="outlined">
      {(agreementType === 'dataCollection' && status === 'concluded') ||
      agreementType === 'soilHealth' ||
      agreementType === 'insights' ? (
        <></>
      ) : (
        <StyledHeader
          data-testid={`agreement-status-card_${agreementId}`}
          agreementType={agreementType}
          status={status}
          title={t(`agreementStatus_${status}`)}
          titleTypographyProps={{ variant: 'overline' }}
        />
      )}
      <CardContent sx={{ padding: '16px !important' }}>
        {/* TODO: this content will need to change when document center is in development */}
        <Typography>
          {isOfferAgreement ? 'Program Summary' : agreement.userModel.displayNameFull}
        </Typography>
        <Typography color="text.secondary" variant="caption">
          {`${isOfferAgreement ? agreement.userModel.displayNameFull : ''}${
            isOfferAgreement ? ' · ' : ''
          }${!!cardDate && (typeof cardDate === 'string' ? cardDate : modifiedDate(cardDate))}`}
        </Typography>
      </CardContent>
    </Card>
  )
}
