import { MpxAxiosError, SupplementedAxiosError } from '@cibo/core'
import * as Sentry from '@sentry/react'
import { DefaultError } from '@tanstack/react-query'
import { AxiosError } from 'axios'

const problematicUrls = ['/fields/:resourceId']

// over by one due to layering in the error telemetry on lines 24-27
// eslint-disable-next-line complexity
export const logRequestError = (
  errorIn: SupplementedAxiosError | DefaultError | MpxAxiosError,
  _extra?: Record<string, any>
) => {
  // @ts-ignore if isAxiosError is present then it's an axios error
  if (!!errorIn?.isAxiosError) {
    const error = errorIn as SupplementedAxiosError

    if (!error.config) {
      Sentry.captureException(error, {
        extra: {
          logRequestError: 'error.config undefined',
          passLocation: 'logRequestError first call',
          ..._extra,
        },
      })
      return
    }

    const extra: Record<string, any> = { ..._extra }

    const requestPath = error.pathTemplate || error.config.url
    const bestGuessGenericUrl = requestPath?.includes('mppf')
      ? requestPath
          .split('/')
          .map(urlSegment => (urlSegment.includes('mppf') ? ':param' : urlSegment))
          .join('/')
      : requestPath

    const errorName = `${error.config.method?.toUpperCase()} ${bestGuessGenericUrl} ${
      error.response?.status
    } ${
      bestGuessGenericUrl &&
      problematicUrls.includes(bestGuessGenericUrl) &&
      (error as AxiosError<{ error: any }>).response?.data.error
    }`
    if (
      error.response?.data &&
      error.response?.status !== 401 // no user data for expired tokens
    ) {
      extra.response = error.response?.data
    }
    if (error.pathParams) {
      extra.pathParams = error.pathParams
    }

    if (error.name === 'Error') error.name = errorName

    if (import.meta.env.PROD || import.meta.env.VITE_DEBUG_SENTRY) {
      Sentry.captureException(error, {
        extra: { passLocation: 'logRequestError second call', ...extra },
      })
    } else {
      console.error(`logRequestError: "${errorName}"`)
    }
  } else {
    if (import.meta.env.PROD || import.meta.env.VITE_DEBUG_SENTRY) {
      const error = errorIn

      if (!error.name) {
        error.name = !!_extra
          ? `error for ${_extra?.firstQueryKey || _extra?.firstMutationKey} - ${
              _extra.queryKeyLength
            }`
          : 'unknown error provenance'
      }
      // any non-sentry error, specifically this may be an error passed through by react-query, though that's somewhat unlikely
      Sentry.captureException(error, {
        extra: { passLocation: 'logRequestError final else', ..._extra },
      })
      return
    } else {
      console.error(`logRequestError: ${JSON.stringify(_extra)}`)
    }
  }
}
