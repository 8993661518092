import { GeneralResourceDetail } from '../ResourceDetail'

/**
 * !IMPORTANT
 *
 * When updating the list of practices, make sure to ALSO update the Contentful
 * entry associated with this resource detail.
 *
 * https://app.contentful.com/spaces/mqztk29gethb/entries/gyijIAdzeOLtalOP1WBd1
 *
 */
export const EQIP_PRACTICE_CHOICE_AGROFORESTRY = [
  '380',
  '381',
  '379',
  '311',
  '391',
  '603',
  'none',
] as const
export type EqipPracticeChoiceAgroforestry = typeof EQIP_PRACTICE_CHOICE_AGROFORESTRY[number]

export type RDEqipPracticeChoiceAgroforestry = GeneralResourceDetail<
  EqipPracticeChoiceAgroforestry[],
  'eqipPracticeChoiceAgroforestry'
>
