import { EnrollmentMessageRequestParams, ResourceDetailsUpdate, WorkflowsAPI } from '@cibo/core'
import {
  DetailSimpleCellProps,
  ResourceDetailFeature,
  ResourceDetailFeatureTaskEditorProps,
  useSaveIndicator,
} from '@cibo/ui'
import { LoadingButton } from '@mui/lab'
import { Button, Stack, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { pick } from 'ramda'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBatchedFields, useUpdateMultipleFields } from '../../../queries'
import {
  EditorTableChanges,
  EditorTableDetail,
  FieldDetailEditorTable,
} from '../FieldDetailEditorTable'
import { ReadOnlyDetailTable } from './ReadOnlyDetailTable'
import {
  PREFERRED_RANK_PRACTICE_OPTION,
  PreferredRankPracticeOption,
  RDPreferredRankPractice,
} from './types'

// This is a one-off hack to notify grower from this task without
// making an architecture change. A component in LandManager should not have
// access to this programs-specific function. The task config should specify
// to display the notifyGrower button via matching display for the role. Then
// a new display type could implement the button and request.
export const useSendEnrollmentMessage = () =>
  useMutation({
    mutationFn: (params: EnrollmentMessageRequestParams) =>
      WorkflowsAPI.sendEnrollmentMessage(params),
  })

const SimpleCellView = ({ detail }: DetailSimpleCellProps) => {
  const { t } = useTranslation('@cibo/landmanager/PreferredRankPractice')
  const value = detail?.result?.practice
  return value ? <span>{t(`option_${value}`)}</span> : <></>
}

const TaskEditor = (props: ResourceDetailFeatureTaskEditorProps<RDPreferredRankPractice>) => {
  const { t } = useTranslation('@cibo/landmanager/PreferredRankPractice')
  const updateMultipleFields = useUpdateMultipleFields()
  const { enqueueSnackbar } = useSnackbar()

  const { setIsSaving, setIsError, setIsDirty } = useSaveIndicator()
  const {
    resourceIds,
    detailRequirements: [firstRequirement],
    userRole,
    engagement,
  } = props

  const sendEnrollmentMessage = useSendEnrollmentMessage()
  const handleSendUserMessage = () =>
    sendEnrollmentMessage
      .mutateAsync({ workflowId: engagement.id })
      .then(() => {
        enqueueSnackbar(t('notifySent', { ns: '@cibo/programs/TaskDialog' }), {
          variant: 'success',
        })
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' })
      })

  const fields = useBatchedFields(resourceIds)

  const [declined, setDeclined] = useState(false)

  const taskStatus = props.engagement.getTaskStatus(props.task.id)

  const acceptPractices = () => {
    if (!fields.data) return

    setIsSaving(true)
    setIsDirty(true)
    setIsError(false)

    const updates = fields.data
      .map(field => {
        const recommendedDetail = field.findDetail({
          traitId: firstRequirement.traitId,
          year: firstRequirement.year,
          programId: props.engagement.programId,
        })
        if (
          !recommendedDetail ||
          recommendedDetail.immutable ||
          recommendedDetail.source === 'user'
        ) {
          return false
        }

        const detail = {
          traitId: recommendedDetail.traitId,
          year: recommendedDetail.year,
          programId: recommendedDetail.programId,
          result: {
            practice: recommendedDetail.input?.ciboValue.practice,
          } as RDPreferredRankPractice['result'],
        }

        return {
          resourceId: field.id,
          details: [detail],
        }
      })
      .filter(Boolean) as ResourceDetailsUpdate[]

    updateMultipleFields
      .mutateAsync(updates)
      .then(() => {
        props.onSuccess?.()
      })
      .catch(error => {
        setIsError(true)
        enqueueSnackbar(error.message, { variant: 'error' })
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const [selections, setSelections] = useState<EditorTableDetail[]>([])

  const handleDetailsChanged = useCallback(
    (models: EditorTableDetail[], { update, remove }: EditorTableChanges) => {
      setSelections(models)
      setIsDirty(true)
    },
    []
  )

  const handleConfirmSelections = () => {
    if (!fields.data) return

    setIsSaving(true)
    setIsDirty(true)
    setIsError(false)

    const updates: ResourceDetailsUpdate[] = []

    selections.forEach(({ resourceId, detail }) => {
      const field = fields.data?.find(field => field.id === resourceId)
      const existingDetail = field?.findDetail({
        traitId: firstRequirement.traitId,
        year: firstRequirement.year,
        programId: props.engagement.programId,
      })

      if (detail && !existingDetail?.immutable) {
        updates.push({
          resourceId,
          details: [pick(['traitId', 'year', 'programId', 'result', 'input'], detail)],
        })
      }
    })

    if (!updates.length) return

    updateMultipleFields
      .mutateAsync(updates)
      .then(() => {
        props.onSuccess?.()
      })
      .catch(error => {
        setIsError(true)
        enqueueSnackbar(error.message, { variant: 'error' })
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  if (taskStatus?.status.progress?.completed) {
    return (
      <ReadOnlyDetailTable
        {...props}
        /* @todo: figure out why programId isn't in the requirement */
        detailRequirements={[{ ...firstRequirement, programId: engagement.programId }]}
        accessValue={detail => detail?.result?.practice}
        columnHeader={t('practiceChange')}
        renderDetail={value => t(`option_${value}`)}
      />
    )
  }

  return (
    <Stack>
      {!declined && (
        <>
          <Typography>{t('preferredDescription')}</Typography>

          <ReadOnlyDetailTable
            {...props}
            /* @todo: figure out why programId isn't in the requirement */
            detailRequirements={[{ ...firstRequirement, programId: engagement.programId }]}
            accessValue={detail => detail?.result?.practice}
            columnHeader={t('practiceChange')}
            renderDetail={value => t(`option_${value}`)}
          />

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            {userRole === 'participant' ? (
              <>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setDeclined(true)}
                  data-testid="decline"
                >
                  {t('decline')}
                </Button>

                <LoadingButton
                  variant="contained"
                  color="secondary"
                  onClick={acceptPractices}
                  loading={updateMultipleFields.isPending}
                  data-testid="accept"
                >
                  {t('accept')}
                </LoadingButton>
              </>
            ) : (
              <LoadingButton
                variant="contained"
                loading={sendEnrollmentMessage.isPending}
                onClick={handleSendUserMessage}
                data-testid="notifyGrower"
              >
                {t('notifyGrower', { ns: '@cibo/programs/TaskNavigation' })}
              </LoadingButton>
            )}
          </Stack>
        </>
      )}

      {declined && (
        <Stack>
          <Typography>{t('selectNonPreferredPractices')}</Typography>

          <FieldDetailEditorTable<RDPreferredRankPractice, readonly PreferredRankPracticeOption[]>
            options={PREFERRED_RANK_PRACTICE_OPTION}
            detailKey="practice"
            ns="@cibo/landmanager/PreferredRankPractice"
            programId={props.engagement.programId}
            onDetailsModelChanged={handleDetailsChanged}
            {...props}
          />

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <LoadingButton
              variant="contained"
              onClick={handleConfirmSelections}
              disabled={selections.some(({ detail }) => detail?.result?.practice === '')}
              loading={updateMultipleFields.isPending}
              data-testid="confirmSelections"
            >
              {t('confirmSelections')}
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export const PreferredRankPracticeFeature: ResourceDetailFeature<RDPreferredRankPractice> = {
  traitId: 'preferredRankPractice',
  SimpleCellView,
  TaskEditor,
}
