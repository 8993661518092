import { FieldModel, FieldsAPI, Integer, TraitId } from '@cibo/core'
import { logRequestError } from '@cibo/ui'
import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useInvalidateBatchedFields } from '../../../queries'
import { QUERY_KEY } from '../../../queries/queryKey'

export type RemoveDetailRequest = {
  resourceId: string
  traitId: TraitId
  year?: number
  programId?: string | string[]
}

export const useRemoveDetail = (onSuccess?: (queryClient: QueryClient) => void) => {
  const queryClient = useQueryClient()

  const invalidateBatchedFields = useInvalidateBatchedFields()

  return useMutation({
    mutationFn: ({ resourceId, traitId, year, programId }: RemoveDetailRequest) =>
      FieldsAPI.updateField(resourceId, [{ traitId, year, programId }]).then(
        f => new FieldModel(f.data.item)
      ),
    mutationKey: [QUERY_KEY.REMOVE_DETAIL],
    onSuccess: (result: FieldModel, request: RemoveDetailRequest) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })

      invalidateBatchedFields([request.resourceId])

      onSuccess && onSuccess(queryClient)
    },
    onError: (error: AxiosError) => {
      logRequestError(error, { useQuery: 'useRemoveDetail' })
    },
  })
}

export type RemoveDetailsRequest = {
  resourceIds: string[]
  traitId: TraitId
  year?: Integer
  programId?: string
}

export const useRemoveDetails = (onSuccess?: (queryClient: QueryClient) => void) => {
  const queryClient = useQueryClient()

  const invalidateBatchedFields = useInvalidateBatchedFields()

  return useMutation({
    mutationFn: ({ resourceIds, traitId, year, programId }: RemoveDetailsRequest) =>
      FieldsAPI.bulkUpdateFields(resourceIds, [{ traitId, year, programId }]),

    mutationKey: [QUERY_KEY.REMOVE_DETAIL],
    onSuccess: (result: any, request: RemoveDetailsRequest) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })

      invalidateBatchedFields(request.resourceIds)

      onSuccess && onSuccess(queryClient)
    },
    onError: (error: AxiosError) => {
      logRequestError(error, { useQuery: 'useRemoveDetails' })
    },
  })
}
