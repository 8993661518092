import { useAuth, useUserData } from '@cibo/profile'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Fade,
  FormControlLabel,
  IconButton,
  Paper,
  Portal,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Consent } from './AnalyticsPreferencesSection'

export const CONSENT_TO_ANALYTICS = 'analytics:impact:consent'
//@ts-ignore - Cypress and Playwright are not typed
const isIntegrationTest = window.Cypress || window.localStorage.driver === 'playwright'

export const Analytics = () => {
  const { t } = useTranslation('impact/components/Analytics')
  const [isReady, setIsReady] = useState(false)
  const [sessionConsent, setSessionConsent] = useState<Consent>(null)
  const userData = useUserData(CONSENT_TO_ANALYTICS)
  const [intent, setIntent] = useState<Consent>('allow')
  const [AnalyticsImplementation, setAnalyticsImplementation] = useState<React.ComponentType<
    any
  > | null>(null)
  const { authResolved, isLoggedIn, userId } = useAuth()
  const mute = isIntegrationTest || window['location'].hostname === 'localhost'

  useEffect(() => {
    if (!mute) {
      if (!isReady && authResolved) {
        if ((userId && userData.isFetched) || !userId) {
          setIsReady(true)
          const localConsent = localStorage.getItem(CONSENT_TO_ANALYTICS)
          if (!!localConsent) {
            setSessionConsent(localConsent as Consent)
          } else if (typeof userData.data === 'string') {
            setSessionConsent(userData.data as Consent)
          }
        }
      }
    }
  }, [authResolved, userId, userData.isFetched])

  /**
   * Only load the analytics implementation if the user has consented.
   */
  useEffect(() => {
    const setup = async () => {
      if (sessionConsent) {
        localStorage.setItem(CONSENT_TO_ANALYTICS, sessionConsent)
        if (isLoggedIn) {
          userData.update(sessionConsent)
        }
      }

      if (mute || sessionConsent !== 'allow') return

      if (sessionConsent === 'allow') {
        const { AnalyticsImplementation } = await import('./AnalyticsImplementation')
        setAnalyticsImplementation(() => AnalyticsImplementation)
      }
    }
    !mute && setup()
  }, [sessionConsent, isLoggedIn])

  /**
   * Prefer local storage over user data since it is possible to answer the analytics
   * question during the login process.  It is reasonable to assume that the user when
   * the users sees this question on the login page, they are answering as a general
   * preference.
   */
  useEffect(() => {
    if (!mute && isReady && typeof userData.data === 'string') {
      const localConsent = localStorage.getItem(CONSENT_TO_ANALYTICS)
      if (localConsent) {
        setSessionConsent(localConsent as Consent)
      } else if (typeof userData.data === 'string') {
        setSessionConsent(userData.data as Consent)
      }
    }
  }, [userData.data, isReady])

  const onSavePreferences = useCallback(() => {
    setSessionConsent(intent)
  }, [intent])

  return isReady && !mute ? (
    <>
      <Portal container={document.body}>
        <Fade appear={false} in={sessionConsent === null}>
          <Box
            sx={{
              position: 'fixed',
              bottom: { xs: 0, md: 16 },
              left: { xs: 0, md: 'unset' },
              right: { xs: 0, md: 16 },
              maxWidth: { xs: '100%', md: 400 },
              zIndex: 'tooltip',
            }}
          >
            <Paper
              role="dialog"
              aria-modal="false"
              aria-label="Cookie banner"
              square
              variant="outlined"
              tabIndex={-1}
              sx={{
                m: 0,
                p: 2,
                borderWidth: 0,
                borderTopWidth: { xs: 1, md: 0 },
                zIndex: 'tooltip',
                borderRadius: { xs: 0, md: 0.5 },
                boxShadow: { xs: 0, md: 4 },
              }}
            >
              <Stack
                direction={{ xs: 'row', sm: 'column' }}
                sx={{ justifyContent: 'space-between', gap: 2 }}
              >
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                  <Typography sx={{ fontWeight: 'bold' }}>{t('cookie-banner-title')}</Typography>
                  <IconButton size="small" onClick={() => setSessionConsent('deny')}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Box sx={{ flexShrink: 1, alignSelf: { xs: 'flex-start', sm: 'center' } }}>
                  <Typography variant="body2">{t('cookie-banner-description')}</Typography>
                </Box>
                <Stack gap={2} px={2}>
                  <Stack gap={1}>
                    <Box>
                      <FormControlLabel
                        control={<Switch size="small" defaultChecked disabled />}
                        label={t('functionalCookies')}
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '0.875rem',
                          },
                        }}
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            defaultChecked
                            onChange={(e, checked) => setIntent(checked ? 'allow' : 'deny')}
                          />
                        }
                        label={t('analyticsCookies')}
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '0.875rem',
                          },
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack direction="row">
                    <Button onClick={onSavePreferences} variant="contained" size="small">
                      {t('savePreferences')}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </Box>
        </Fade>
      </Portal>
      {sessionConsent === 'allow' && !!AnalyticsImplementation && <AnalyticsImplementation />}
    </>
  ) : null
}
