import {
  DetailRequirement,
  GeneralResourceDetail,
  MpxResponseError,
  ResourceDetail,
  ResourceDetailsUpdate,
  UserDetailsAPI,
} from '@cibo/core'
import { logRequestError } from '@cibo/ui'
import { UseMutationOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { QUERY_KEY } from '../queries'

export const PROFILE_USER_DETAILS_QUERY_KEY = QUERY_KEY.USER_DETAILS

export const useUserDetails = <T extends GeneralResourceDetail = GeneralResourceDetail>({
  detailRequirements,
  resourceId,
}: {
  detailRequirements?: DetailRequirement<T>[]
  resourceId?: string
}) =>
  useQuery({
    queryKey: [QUERY_KEY.USER_DETAILS, resourceId, detailRequirements],
    queryFn: () =>
      !!resourceId
        ? UserDetailsAPI.get<T>({ resourceId }).catch(error =>
            logRequestError(error, { query: 'useUserDetails' })
          )
        : Promise.reject('missing resourceId'),
    select: response =>
      response?.details?.filter(({ traitId, year }) =>
        detailRequirements
          ? !!detailRequirements.find(req => req.traitId === traitId && req.year === year)
          : true
      ),
    enabled: !!resourceId,
  })

export const useUpdateUserDetails = <T extends ResourceDetail = ResourceDetail>(
  props?: Pick<
    UseMutationOptions<unknown, AxiosError<MpxResponseError>, ResourceDetailsUpdate<T>, unknown>,
    'onSuccess' | 'onError'
  >
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (update: ResourceDetailsUpdate<T>) => UserDetailsAPI.update(update),
    onError: (error: any, variables, context) => {
      props?.onError && props.onError(error, variables, context)
      logRequestError(error, { query: 'useUpdateUserDetails', variables, context })
      return error
    },
    onSuccess: (response: unknown, update: ResourceDetailsUpdate<T>, context: unknown) => {
      //@todo update the query data instead of invalidating
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.USER_DETAILS, update.resourceId] })
      props?.onSuccess && props?.onSuccess(response, update, context)
    },
  })
}

export const useDeleteUserDetails = (
  props?: Pick<
    UseMutationOptions<unknown, AxiosError<MpxResponseError>, ResourceDetailsUpdate, unknown>,
    'onSuccess' | 'onError'
  >
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (request: ResourceDetailsUpdate) => UserDetailsAPI.delete(request),
    onError: (error: any, variables, context) => {
      props?.onError && props.onError(error, variables, context)
      logRequestError(error, { query: 'useUpdateUserDetails', variables, context })
      return error
    },
    onSuccess: (response: unknown, update: ResourceDetailsUpdate, context: unknown) => {
      //@todo update the query data instead of invalidating
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.USER_DETAILS, update.resourceId] })
      props?.onSuccess && props?.onSuccess(response, update, context)
    },
  })
}
