import { FieldSpace, LabelsAPI, LabelsMergeRequest, LabelsRequest } from '@cibo/core'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { QUERY_KEY } from './consts'
import { LAND_REPORT_QUERY_KEY } from './LandReport'
import { useInvalidateBatchedFields } from './useBatchedFields'

const fullSearch = 'all'

export const useLabelsSearch = (fullText?: string, space?: FieldSpace) => {
  const params = fullText ? { space, fullText } : { space }

  return useQuery<string[] | undefined>({
    queryKey: [QUERY_KEY.LABELS_SEARCH, space, fullText || fullSearch],
    queryFn: async () => {
      const response = await LabelsAPI.searchLabels(params)
      return response ? response : undefined
    },
  })
}

export const useMergeLabels = () => {
  const queryClient = useQueryClient()

  const invalidateBatchedFields = useInvalidateBatchedFields()

  return useMutation({
    mutationFn: (requestParams: LabelsRequest) => LabelsAPI.mergeLabels(requestParams),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.LABELS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.LABELS_SEARCH] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })
      queryClient.invalidateQueries({ queryKey: [LAND_REPORT_QUERY_KEY.LAND_REPORT] })

      invalidateBatchedFields(variables.resourceIds)
    },
  })
}

export const useSetLabels = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (requestParams: LabelsMergeRequest) => LabelsAPI.setLabels(requestParams),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.LABELS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.LABELS_SEARCH] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })
      queryClient.invalidateQueries({ queryKey: [LAND_REPORT_QUERY_KEY.LAND_REPORT] })
    },
  })
}
