import { downloadAxiosResponse, logRequestError } from '@cibo/ui'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import { LoadingButton } from '@mui/lab'
import { Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { RequiredDocumentsAPI } from '../../RequiredDocumentsAPI'
import { useProcessSigningReturn } from '../useProcessSigningReturn'

export const PaymentConsentDocusign = ({
  engagementId,
  taskId,
  isSigned,
}: {
  engagementId: string
  taskId: string
  isSigned: boolean
}) => {
  const { t } = useTranslation('@cibo/programs/PaymentConsentDocusign')
  const { enqueueSnackbar } = useSnackbar()

  const [loadingDocusignUrl, setLoadingDocusignUrl] = useState(false)

  const [envelopeLoading, setEnvelopLoading] = useState(false)
  useEffect(() => {
    if (!engagementId) return
    setEnvelopLoading(true)
    RequiredDocumentsAPI.generate({ engagementId, taskId, returnUrl: window.location.href })
      .catch((error: AxiosError) => {
        enqueueSnackbar(error.message, { variant: 'error' })
        logRequestError(error)
      })
      .finally(() => {
        setEnvelopLoading(false)
      })
  }, [])

  const processSigningReturn = useProcessSigningReturn()

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.has('event')) {
      const eventParam = searchParams.get('event') as string

      searchParams.delete('event')
      setSearchParams(searchParams)

      processSigningReturn.mutateAsync({ engagementId, taskId, eventParam })
    }
  }, [searchParams])

  const goToDocusign = useCallback(() => {
    setLoadingDocusignUrl(true)

    RequiredDocumentsAPI.url({ engagementId, taskId, returnUrl: window.location.href })
      .then((response: { docusignUrl: string }) => (document.location.href = response.docusignUrl))
      .catch((error: AxiosError) => {
        enqueueSnackbar(error.message, { variant: 'error' })
        logRequestError(error)
      })
      .finally(() => {
        setLoadingDocusignUrl(false)
      })
  }, [])

  const downloadSigned = useCallback(() => {
    setLoadingDocusignUrl(true)

    RequiredDocumentsAPI.download({ engagementId, taskId })
      .catch((error: AxiosError) => {
        enqueueSnackbar(error.message, { variant: 'error' })
        logRequestError(error)
      })
      .then(downloadAxiosResponse())
      .finally(() => {
        setLoadingDocusignUrl(false)
      })
  }, [])

  return (
    <Stack spacing={3}>
      <Typography variant="body1">{t('taskTitle')}</Typography>

      <Typography variant="body2">{t('description')}</Typography>

      <Stack direction="row" spacing={2} alignItems="center" justifyContent={'space-between'}>
        <Typography>{t('documentTitle')}</Typography>
        {!isSigned ? (
          <LoadingButton
            variant="contained"
            onClick={goToDocusign}
            loading={envelopeLoading || loadingDocusignUrl}
            data-testid="view-documents-button"
          >
            {t('reviewAndSign')}
          </LoadingButton>
        ) : (
          <Stack direction="row" alignItems="center">
            <Tooltip title={t('downloadCompletedForms')}>
              <IconButton
                onClick={downloadSigned}
                data-testid="download-documents-button"
                disabled={envelopeLoading || loadingDocusignUrl}
              >
                <DownloadRoundedIcon />
              </IconButton>
            </Tooltip>
            <Chip
              size="small"
              color="success"
              onClick={goToDocusign}
              disabled={envelopeLoading || loadingDocusignUrl}
              data-testid="view-documents-button"
              label={t('complete')}
            ></Chip>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
