import { OrganizationAPI, OrganizationContentModel } from '@cibo/core'
import { useQuery } from '@tanstack/react-query'
import { Entry } from 'contentful'
import { QUERY_KEY } from './queryKey'

type ContentOptions = { orgId?: string }

export const useSponsorOrganizationContent = (args?: ContentOptions) => {
  const orgId = args?.orgId

  return useQuery<Entry<OrganizationContentModel>, unknown, Entry<OrganizationContentModel>>({
    queryKey: [QUERY_KEY.ORGANIZATION_CONTENT, orgId],
    queryFn: () => {
      if (!orgId) throw new Error('can not fetch org content without id')

      return OrganizationAPI.sponsorInfo(orgId).then(
        response => (response?.content as Entry<OrganizationContentModel>) || null
      )
    },

    enabled: !!args?.orgId,
    staleTime: Infinity,
    retry: 3,
  })
}
