import { last, path, propEq } from 'ramda'

import {
  AbbreviatedCarbonComparisonResult,
  Boundary,
  Collection,
  CollectionAction,
  CollectionAncestor,
  CollectionEntry,
  CollectionSpace,
  CollectionSpaceType,
  CollectionType,
  CropType,
  LandProfile,
  ShareDescription,
} from '../types'
import { CarbonFootprintResult } from '../types/CarbonFootprintResult'
import { CollectionSearchResultItem } from '../types/LandManager'
import { FieldUpdate, PortfolioUpdate, UserMessage, YieldChange } from '../types/Messages'
import { hasYieldChangeMessages } from '../utils/userMessageUtils'
import { CarbonComparisonResultModel } from './CarbonComparisonResultModel'

import { CarbonFootprintResultModel } from './CarbonFootprintResultModel'
import { LandProfileModel } from './LandProfileModel'

export class CollectionModel implements Partial<Collection> {
  id?: string
  isNew?: boolean
  type?: CollectionType
  name?: string
  boundaries: Record<string, Boundary>
  color?: string
  parcelCount?: number
  geometrySlug?: string
  operator?: string
  entries?: CollectionEntry[]
  createdDate?: string
  modifiedDate?: string
  tillableGeometrySlug?: string
  _meta?: {
    ciboResults?: {
      carbonFootprint?: CarbonFootprintResult[]
      carbonComparison?: AbbreviatedCarbonComparisonResult[]
    }
    landProfile: LandProfile
    allowedActions: CollectionAction[]
    descendants: {
      total: number
      totalFields?: number
      totalParcels?: number
    }
    parents: CollectionAncestor[]
    lineages: Array<CollectionAncestor[]>
    messages?: UserMessage[]
    shares?: ShareDescription[]
  }
  space?: CollectionSpace
  spaceType?: CollectionSpaceType
  /** @deprecated */
  inventory?: any
  thumbnail?: string

  profileModel: LandProfileModel
  userProperties?: any
  _collection?: Collection

  constructor(params: Collection) {
    this.id = params.id
    this.isNew = params.isNew
    this.type = params.type
    this.name = params.name
    this.color = params.color
    this.parcelCount = params.parcelCount
    this.geometrySlug = params.geometrySlug
    this.operator = params.operator
    this.entries = params.entries
    this.createdDate = params.createdDate
    this.modifiedDate = params.modifiedDate
    this.tillableGeometrySlug = params.tillableGeometrySlug
    this._meta = params._meta
    this.space = params.space
    this.spaceType = params.spaceType
    this.boundaries = params.boundaries
    this.inventory = params.inventory

    this.profileModel = new LandProfileModel(this._meta?.landProfile)
    this.userProperties = params.userProperties
    this._collection = params
    this.thumbnail = params.thumbnail
  }

  static fromSearchResult(result: CollectionSearchResultItem) {
    return new CollectionModel({
      ...result,
      id: result.resourceId,
      boundaries: result.boundaries,
      space: 'user',
      spaceType: 'private',
      tillableGeometrySlug: '',
      createdDate: '',
      description: '',
      entries: [],
      parcelCount: -1,
      _meta: {
        landProfile: new LandProfileModel(result),
        allowedActions: [],
        descendants: { total: 0 },
        parents: [],
        lineages: result.lineages || [[]],
      },
    })
  }

  get descendantCount() {
    return this._meta?.descendants.total
  }

  allowsAction = (action: CollectionAction) => this._meta?.allowedActions?.includes(action)

  get fieldCount() {
    return this._meta?.descendants?.totalFields
  }

  get historicYield() {
    return this.profileModel.historicYield
  }

  get inSeasonYield() {
    return this.profileModel.inSeasonYield
  }

  get leasePerAcreEstimate() {
    return this.profileModel.leaseValuePerAcre
  }

  get acreage() {
    return this._meta?.landProfile.acreage
  }
  get tillableAcres() {
    return this.profileModel.tillableAcres
  }

  get defaultSpecifiedBoundary() {
    return path(['boundaries', 'default', 'specified'], this)
  }

  get ciboBaselineFootprint() {
    const ciboBaseline = this._meta?.ciboResults?.carbonFootprint?.find(
      propEq('type', 'ciboBaseline')
    )
    return ciboBaseline && new CarbonFootprintResultModel(ciboBaseline)
  }

  get sharedTo() {
    const shares = this._meta?.shares
    return shares
      ? shares.map(share => share.type === 'user' && share.to).filter(v => v !== false)
      : []
  }

  get latestCarbonLabResult() {
    const latestCarbonLabResult = this._meta?.ciboResults?.carbonComparison?.find(
      propEq('type', 'userCompare')
    )
    return latestCarbonLabResult && new CarbonComparisonResultModel(latestCarbonLabResult)
  }

  get parentId() {
    return this._collection && CollectionModel.parentId(this._collection)
  }
  static parentId(collection: Collection) {
    const fromParents = path(['_meta', 'parents', 0, 'resourceId'], collection)
    if (fromParents) {
      return fromParents
    }

    const lineage: CollectionAncestor[] = path(['_meta', 'lineages', 0], collection) || []
    return lineage && last<CollectionAncestor>(lineage)?.resourceId
  }

  get hasMessages() {
    return (this._meta?.messages || []).length > 0
  }

  get messages() {
    return this._meta?.messages
  }

  get hasOwnerChangeMessage() {
    if (this.type === CollectionType.FIELD) {
      if (this.messages) {
        return this.messages?.some(message => {
          if (message.messageType === 'fieldUpdate') {
            return (message.messageData as FieldUpdate).hasOwnerChange
          } else if (message.messageType === 'pfUpdate') {
            return (
              this.id && (message.messageData as PortfolioUpdate).fieldData[this.id]?.hasOwnerChange
            )
          }
          return false
        })
      }
    } else if (this.type === CollectionType.PORTFOLIO) {
      return this.messages?.some(({ messageData: { hasOwnerChange } }) => hasOwnerChange)
    }
    return false
  }

  get hasPracticeChangeMessage() {
    if (this.type === CollectionType.FIELD) {
      if (this._meta?.messages) {
        return this.messages?.some(message => {
          if (message.messageType === 'fieldUpdate') {
            return (message.messageData as FieldUpdate).hasPracticeChange
          } else if (message.messageType === 'pfUpdate') {
            return (
              this.id &&
              (message.messageData as PortfolioUpdate).fieldData[this.id]?.hasPracticeChange
            )
          }
          return false
        })
      }
    } else if (this.type === CollectionType.PORTFOLIO) {
      return this.messages?.some(({ messageData: { hasPracticeChange } }) => hasPracticeChange)
    }
    return false
  }

  hasYieldChangeMessages(props: { crop: CropType; includeUnread?: boolean }) {
    if (this.type === CollectionType.FIELD) {
      if (this._meta?.messages) {
        return this._meta.messages?.some(message => {
          if (message.messageType === 'fieldUpdate') {
            return (message.messageData as FieldUpdate).changes?.some(
              change => (change as YieldChange).latestForecast?.data.crop === props.crop
            )
          } else if (message.messageType === 'pfUpdate') {
            return (
              this.id &&
              (message.messageData as PortfolioUpdate).fieldData[this.id]?.changes?.some(
                change => (change as YieldChange).latestForecast?.data.crop === props.crop
              )
            )
          }
          return false
        })
      }
    } else if (this.type === CollectionType.PORTFOLIO) {
      return (
        (
          this._meta?.messages?.filter(message => hasYieldChangeMessages({ ...props, message })) ||
          []
        ).length > 0
      )
    }
    return false
  }
}
