import { ProgramEngagementsAPI } from '@cibo/core'
import { Markdown } from '@cibo/ui'
import AttachFileRounded from '@mui/icons-material/AttachFileRounded'
import UploadIcon from '@mui/icons-material/UploadFile'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, FormLabel, Grid2 as Grid, Link, Stack, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useUploadEqipApplicationResults } from '../../../../queries'

export interface FileUploadFormValues {
  file: { file: File; name?: string }
}

const ACCEPTED_FILES = ['.pdf', '.png', '.jpg']
const MAX_FILE_SIZE = 5 * 1024 * 1024

type Props = {
  // TODO: fix
  currentDetail: any
  currentFile?: any
  programEngagementId: string
  disabled?: boolean
}

export const ScheduleOfOperationsUpload = ({
  currentDetail,
  currentFile,
  programEngagementId,
  disabled,
}: Props) => {
  const { t } = useTranslation('@cibo/programs/RDEqipApplicationResultsTaskEditor')
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const uploadEqipApplicationResults = useUploadEqipApplicationResults()

  const downloadUrl = ProgramEngagementsAPI.getApplicationResultsUrl({
    programEngagementId,
    year: currentDetail.year,
  })

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (acceptedFiles.length > 0) {
        setErrorMessage(undefined)

        uploadEqipApplicationResults
          .mutateAsync({
            id: programEngagementId,
            ...currentDetail,
            file: acceptedFiles[0],
          })
          .catch(error => {
            setErrorMessage(error.response.data?.message || t('rejectedFile'))
          })
      } else {
        setErrorMessage(t(rejectedFiles[0].errors[0].code || t('rejectedFile')))
      }
    },
    [currentDetail]
  )

  const uploadInProgress =
    !!uploadEqipApplicationResults.isPending && !uploadEqipApplicationResults.isSuccess

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILES,
    maxSize: MAX_FILE_SIZE,
    multiple: false,
    noClick: true,
  })

  return (
    <>
      <FormLabel id="upload-schedule-title">{t('uploadSchedule')}</FormLabel>
      {!!errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Stack
        direction="row"
        spacing={3}
        sx={{ width: '100%', zIndex: 5 }}
        justifyContent="start"
        alignItems="center"
        {...getRootProps()}
      >
        {isDragActive ? (
          <Box p={5} sx={{ width: '100%', border: '2px dashed gray' }} alignItems="center">
            <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {t('dropFileToUpload')}
              </Typography>
            </Grid>
          </Box>
        ) : (
          <>
            <input
              {...getInputProps()}
              data-testid="upload-schedule-of-operations"
              aria-describedby="upload-schedule-title"
            />
            <LoadingButton
              style={{ marginLeft: 0 }}
              color="secondary"
              startIcon={<UploadIcon />}
              variant="contained"
              onClick={open}
              loading={uploadInProgress}
              disabled={disabled}
            >
              {t('upload')}
            </LoadingButton>
            {!!currentFile?.length ? (
              <Link href={downloadUrl} target="_blank">
                <Typography
                  color="text.secondary"
                  sx={{ justifyContent: 'center', display: 'flex' }}
                >
                  <AttachFileRounded sx={{ marginRight: 1 }} />
                  {currentFile[0].fileName || currentFile[0].name || t('scheduleOfOperations')}
                </Typography>
              </Link>
            ) : (
              <Typography variant="body1">{t('orDropFile')}</Typography>
            )}
          </>
        )}
      </Stack>
      <Markdown typographyProps={{ variant: 'caption' }} color="text.secondary">
        {t('uploadHelpText')}
      </Markdown>
    </>
  )
}
