import { DataResourceDetail, FieldModel, MpxResponseError, ResourceDetail } from '@cibo/core'
import { Markdown, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import { GridRenderCellParams, GridRowHeightReturnValue, useGridApiRef } from '@mui/x-data-grid-pro'
import { AxiosResponse } from 'axios'
import { equals, partition, pick, values } from 'ramda'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldNameCell } from '../../../components/FieldColumns'
import { RollupRowDataGridPro } from '../../../components/RollupRowDataGridPro'
import { useBatchedFields } from '../../../queries'
import { SaveDetailRequest, useSaveBulkDetails, useSaveDetail } from '../../DetailEditing'

const ANSWER_FOR_ALL = 'answerForAll'

type RowAnswers = Record<number, { result?: boolean; immutable?: boolean }>

type CheckboxBooleanYearRollupEditorRow = {
  id: string
  answers: RowAnswers
  allAnswersFalse?: boolean
}

export type UpdatesArguments<RDT extends ResourceDetail = ResourceDetail<any, any>> = {
  resourceIds: string[]
  details: { traitId: RDT['traitId']; year: number; result: boolean | undefined }[]
}

export type CheckboxBooleanYearRollupEditorProps<
  RDT extends ResourceDetail = ResourceDetail<any, any>
> = {
  ns: string
  title?: string
  updateIsPending?: boolean
  handleUpdates?: (
    { resourceIds, details }: UpdatesArguments<RDT>,
    singleUpdates?: SaveDetailRequest[]
  ) => Promise<AxiosResponse<any>>
  savedFieldDetails?: {
    resourceId: string
    details: DataResourceDetail[]
  }[]
} & ResourceDetailFeatureTaskEditorProps<RDT>

export const CheckboxBooleanYearRollupEditor = <RDT extends ResourceDetail>({
  detailRequirements,
  ns,
  onError,
  onSuccess,
  onUpdating,
  resourceIds,
  userRole,
  ownerName,
  title,
  updateIsPending,
  handleUpdates,
  savedFieldDetails,
}: CheckboxBooleanYearRollupEditorProps<RDT>) => {
  const { t } = useTranslation(['@cibo/landmanager/CheckboxBooleanYearRollupEditor', ns])
  // assuming all requirements have the same traitId
  const traitId = detailRequirements[0].traitId
  const detailYears: number[] = useMemo(
    () =>
      detailRequirements
        .map(detailReq => detailReq.traitId === traitId && detailReq.year)
        .filter(Boolean) as number[],
    [detailRequirements]
  )

  const {
    data: fieldModels,
    dataUpdatedAt: fieldModelsDataUpdatedAt,
    isFetched,
  } = useBatchedFields(resourceIds)
  const gridApi = useGridApiRef()

  const saveBulkDetails = useSaveBulkDetails()
  const saveDetail = useSaveDetail()

  const [rowData, setRowData] = useState<CheckboxBooleanYearRollupEditorRow[]>([])

  const rows = useMemo(() => {
    const rows =
      fieldModels?.map(field => ({
        id: field.resourceId,
        ...pick(['geometrySlug', 'state', 'name', 'county', 'acres'], field),
        answers: detailYears.reduce((acc, year) => {
          const detail =
            savedFieldDetails
              ?.find(details => details.resourceId === field.resourceId)
              ?.details.find(detail => detail.year === year) ||
            field.resolveStandingDetail(traitId, year)

          return {
            ...acc,
            [year]: detail && pick(['result', 'immutable'], detail),
          }
        }, {}),
      })) || []

    return rows
  }, [isFetched])

  const handleRowDataUpdated = useCallback(() => {
    if (!gridApi.current) return

    setRowData(
      resourceIds.map(id => gridApi.current.getRow(id) as CheckboxBooleanYearRollupEditorRow)
    )
  }, [gridApi.current])

  useEffect(handleRowDataUpdated, [])

  const combinedAnswer = useMemo(() => {
    return detailYears.reduce(
      (acc, year) => ({
        ...acc,
        [year]: !rowData?.length
          ? undefined
          : {
              result: rowData.every(row => row?.answers[year]?.result === true),
              immutable: rowData.some(row => row?.answers[year]?.immutable),
            },
      }),
      {}
    ) as RowAnswers
  }, [rowData])

  const allAnswersFalse =
    !!rowData?.length &&
    rowData.every(row => detailYears.every(year => row?.answers[year]?.result === false))

  const allYearsFalse: Record<number, { result: boolean; immutable: undefined }> = {}
  detailYears.forEach(year => {
    allYearsFalse[year] = { result: false, immutable: undefined }
  })

  const saveUpdates = (
    {
      resourceIds,
      values,
    }: {
      resourceIds: string[]
      values: { year: number; result?: boolean }[]
    },
    individualUpdates?: SaveDetailRequest[]
  ) => {
    onUpdating?.()
    const details = values.map(({ year, result }) => {
      return { traitId, year, result }
    })

    return Promise.all([
      (!!handleUpdates
        ? handleUpdates({ resourceIds, details }, individualUpdates)
        : saveBulkDetails.mutateAsync({
            resourceIds,
            details,
          })
      )
        .then(() => onSuccess?.())
        .catch((e: AxiosResponse<MpxResponseError>) => onError?.(e)),
      ...(!!individualUpdates
        ? individualUpdates.map(update => saveDetail.mutateAsync(update))
        : []),
    ])
  }

  const handleToggleRowYear = useCallback(
    (
      { row, api }: GridRenderCellParams<CheckboxBooleanYearRollupEditorRow, RowAnswers>,
      year: number,
      checked: boolean
    ) => {
      const currentAnswers = row.answers
      Object.keys(currentAnswers).forEach(
        (key: any) => currentAnswers[key] === undefined && delete currentAnswers[key]
      )
      const updates: { id: string; answers: RowAnswers }[] = []
      const baseAnswers = {
        ...allYearsFalse,
        ...currentAnswers,
        [year]: { result: checked, immutable: undefined },
      }
      if (row.id === ANSWER_FOR_ALL) {
        const [matchingRows, nonMatchingRows] = partition(
          (aRow: CheckboxBooleanYearRollupEditorRow) =>
            equals(
              Object.values(aRow.answers).map(answer => answer?.result),
              Object.values(row.answers).map(answer => answer?.result)
            ),
          rows
        )
        const values = Object.keys(baseAnswers).map((year: any) => ({
          year: year as number,
          result: baseAnswers[year].result,
        }))
        matchingRows?.forEach(rowItem => {
          const row = api.getRow(rowItem.id)
          updates.push({
            id: row.id,
            answers: { ...row.answers, [year]: { result: checked, immutable: undefined } },
          })
        })
        const singleUpdates = nonMatchingRows.map(row => {
          const gridRow = api.getRow(row.id)
          const answers = { ...gridRow.answers, [year]: { result: checked, immutable: undefined } }
          updates.push({ id: gridRow.id, answers })
          return {
            resourceId: row.id,
            details: Object.keys(answers).map((year: any) => ({
              traitId,
              year: year as number,
              result: answers[year]?.result || false,
            })),
          }
        })
        api.updateRows(updates)
        handleRowDataUpdated()
        saveUpdates(
          {
            resourceIds: matchingRows.map(row => row.id),
            values,
          },
          singleUpdates
        )
      } else {
        const values = Object.keys(baseAnswers).map((year: any) => ({
          year: year as number,
          result: baseAnswers[year].result,
        }))
        updates.push({
          id: row.id,
          answers: baseAnswers,
        })
        api.updateRows(updates)
        handleRowDataUpdated()
        saveUpdates({
          resourceIds: [row.id],
          values,
        })
      }
    },
    [rows]
  )

  const saveNoneOfTheAbove = (resourceIds: string[]) => {
    onUpdating?.()

    return (
      !!handleUpdates
        ? handleUpdates({
            resourceIds,
            details: detailYears.map(year => ({ traitId, year, result: false })),
          })
        : saveBulkDetails.mutateAsync({
            resourceIds,
            details: detailYears.map(year => ({ traitId, year, result: false })),
          })
    )
      .then(() => onSuccess?.())
      .catch(e => onError?.(e))
  }

  const handleNoneOfTheAbove = useCallback(
    ({ api, row }: GridRenderCellParams<CheckboxBooleanYearRollupEditorRow, RowAnswers>) => {
      const updates: { id: string; answers: RowAnswers }[] = []

      if (row.id === ANSWER_FOR_ALL) {
        resourceIds?.forEach(id => {
          updates.push({
            id,
            answers: detailYears.reduce(
              (acc, year) => ({ ...acc, [year]: { result: false } }),
              {}
            ) as RowAnswers,
          })
        })
      } else {
        updates.push({
          id: row.id,
          answers: detailYears.reduce(
            (acc, year) => ({ ...acc, [year]: { result: false } }),
            {}
          ) as RowAnswers,
        })
      }

      api.updateRows(updates)
      handleRowDataUpdated()
      saveNoneOfTheAbove(row.id === ANSWER_FOR_ALL ? resourceIds : [row.id])
    },
    []
  )

  const columns = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: t('field', { ns: '@cibo/landmanager/SelectRollupEditor' }),
        hideable: false,
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<FieldModel>) => (
          <Box sx={{ marginLeft: 2, py: 2 }}>
            {params.row.id === ANSWER_FOR_ALL ? (
              <Typography sx={{ fontWeight: 'bold', my: 2 }}>
                {t('answerForAllFields', { ns: '@cibo/landmanager/SelectRollupEditor' })}
              </Typography>
            ) : (
              <FieldNameCell {...params} />
            )}
          </Box>
        ),
      },
      {
        field: 'answers',
        headerName: t('answer'),
        flex: 1,
        hideable: false,
        disableColumnMenu: true,
        filterable: false,
        renderCell: (
          params: GridRenderCellParams<CheckboxBooleanYearRollupEditorRow, RowAnswers>
        ) => {
          const { row, value } = params
          const rowResults = value
          const isForAll = row.id === ANSWER_FOR_ALL
          const isNoneOfTheAbove = isForAll
            ? row.allAnswersFalse
            : rowResults && values(rowResults).every(resultRow => resultRow?.result === false)

          return (
            <Stack>
              {detailYears.map((year, index) => (
                <FormControlLabel
                  key={year}
                  control={
                    <Checkbox
                      checked={rowResults?.[year]?.result || false}
                      disabled={values(rowResults || {}).some(value => value?.immutable)}
                      onChange={async (e, checked) => {
                        handleToggleRowYear(params, year, checked)
                      }}
                    />
                  }
                  label={year}
                />
              ))}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isNoneOfTheAbove || false}
                    disabled={values(rowResults || {}).some(value => value?.immutable)}
                    onChange={(e, checked) => checked && handleNoneOfTheAbove(params)}
                  />
                }
                label={t('noneOfTheAbove')}
              />
            </Stack>
          )
        },
      },
    ]
  }, [fieldModelsDataUpdatedAt, detailYears])

  const getRowHeight = useCallback((): GridRowHeightReturnValue => 'auto', [])

  return (
    <Stack spacing={3} flex={1}>
      <Markdown
        overrides={{
          em: {
            component: ({ children }: { children: ReactNode }) => (
              <Typography variant="body2" color="text.secondary" component="span">
                {children}
              </Typography>
            ),
          },
        }}
      >
        {title ||
          [
            t('question', {
              context: userRole,
              name: ownerName,
              ns,
              count: fieldModels?.length,
            }),
            t('optionsExplainer', { ns, defaultValue: null }),
          ]
            .filter(Boolean)
            .join('<br/><br/>')}
      </Markdown>

      <Stack>
        <RollupRowDataGridPro
          apiRef={gridApi}
          autoHeight
          columns={columns}
          disableRowSelectionOnClick
          getRowHeight={getRowHeight}
          hideCellFocus
          pinnedRows={
            fieldModels?.length && fieldModels.length > 1
              ? {
                  top: [
                    {
                      id: ANSWER_FOR_ALL,
                      answers: combinedAnswer,
                      allAnswersFalse,
                    },
                  ],
                }
              : {}
          }
          rows={rows || []}
          loading={saveBulkDetails.isPending || updateIsPending}
        />
      </Stack>
    </Stack>
  )
}
