import { getConfigWithKeys } from '../utils/ConfigBase'

export type MP_CONFIG = 'MP_BASE_URL' | 'MP_BASE_URL_WS'

export type AUTH0_CONFIG =
  | 'AUTH0_ENVIRONMENT_DOMAIN'
  | 'AUTH0_ENVIRONMENT_CLIENT_ID'
  | 'AUTH0_DOMAIN'
  | 'AUTH0_CLIENT_ID'

export type CONTENTFUL_CONFIG =
  | 'CONTENTFUL_SPACE_ID'
  | 'CONTENTFUL_DELIVERY_TOKEN'
  | 'CONTENTFUL_PREVIEW_TOKEN'

export type FIREBASE_CONFIG = 'FIREBASE_APP_DOMAIN'

export type SENTRY_CONFIG = 'SENTRY_DSN' | 'SENTRY_ENVIRONMENT'

export type BUILD_CONFIG = 'BUILD_NUMBER' | 'GIT_BRANCH' | 'GIT_COMMIT' | 'VERSION'

export type DEV_CONFIG = 'DEV_ENV' | 'DEV_PERMISSIONS' | 'ENVIRONMENT_CHECK'

/**
 * Seems odd to have this here, but we reference the node service in some of the API classes
 */
export type NODE_SERVICE_CONFIG = 'NODE_SERVICE_URL'
export type MAPTILES_SERVICE_CONFIG = 'MAPTILES_SERVICE_URL'

// This is for use inside core
export const Config = getConfigWithKeys<
  MP_CONFIG | CONTENTFUL_CONFIG | NODE_SERVICE_CONFIG | MAPTILES_SERVICE_CONFIG | FIREBASE_CONFIG
>()
