import {
  AssignFieldsRequestParams,
  FDRStatus,
  FDRStatusQuery,
  FIELD_QUERY_MAX_LIMIT,
  Field,
  FieldMetadataUpdateRequest,
  FieldModel,
  FieldQueryOptions,
  FieldQuerySortFields,
  FieldsAPI,
  GrowerProgramsAPI,
  MpxResponseError,
  PaginationResponse,
  SortModel,
} from '@cibo/core'
import { logRequestError } from '@cibo/ui'
import { GridSortModel } from '@mui/x-data-grid-pro'
import {
  DefaultError,
  QueryKey,
  UseQueryOptions,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { centerOfMass, feature, featureCollection } from '@turf/turf'
import { AxiosError, AxiosResponse } from 'axios'
import { flatten, sum } from 'ramda'
import { useDetailsChanged } from '../features/DetailEditing/DetailEditingProvider/useDetailsChanged'
import { LAND_REPORT_QUERY_KEY } from './LandReport'
import { QUERY_KEY } from './queryKey'
import { BATCHED_FIELD_QUERY_KEY, useInvalidateBatchedFields } from './useBatchedFields'

/**
 * @deprecated Use useBatchedField() instead.
 *
 * This must still be used in places where we need the dataSource of a termset.
 * The dataSource is not present on fields query responses. 2/25/25
 *
 * see this issue: https://cibotech.atlassian.net/browse/CPD-8128
 */
export const useField = (resourceId?: string) => {
  return useQuery<FieldModel | null, AxiosError<MpxResponseError>>({
    queryKey: ['fields', resourceId],
    queryFn: () =>
      resourceId
        ? FieldsAPI.field(resourceId).then(response => (response ? new FieldModel(response) : null))
        : null,
    enabled: !!resourceId,
  })
}

export const useTotalFieldCount = () =>
  useQuery<number>({
    queryKey: [QUERY_KEY.FIELDS_PAGINATED_FIELD_COUNT],
    queryFn: async () => {
      const resources = await FieldsAPI.paginatedFields({
        offset: 0,
        limit: 1,
      })
      //@ts-ignore @todo fix return type on API
      return resources.numAvailable === undefined ? 0 : resources.numAvailable
    },
  })

export interface UsePaginatedFieldsSearchParams extends Omit<FieldQueryOptions, 'sort'> {
  sort?: GridSortModel
  includeAllResolved?: boolean
  sortField?: string
  sortDir?: 'desc' | 'asc'
}

export const usePaginatedFields = (
  page: number,
  pageSize: number,
  params?: UsePaginatedFieldsSearchParams,
  options?: { enabled?: boolean; gcTime?: number; refetchOnMount?: 'always' }
) => {
  const { sort, ...passthroughParams } = params || {}
  const queryClient = useQueryClient()

  return useQuery<PaginationResponse<FieldModel>, AxiosError<MpxResponseError>>({
    queryKey: [QUERY_KEY.FIELDS_PAGINATED, page, pageSize, params],
    queryFn: async () => {
      const resources = await FieldsAPI.paginatedFields({
        offset: page * pageSize,
        limit: pageSize,
        ...passthroughParams,
        owner: passthroughParams.owner,
        sort: sort as SortModel<FieldQuerySortFields>,
        includeAllDetails: true,
      })

      const items = resources?.items.map((r: Field) => new FieldModel(r))

      // WARNING: these fields may not include sourceData on termsets
      // see this issue: https://cibotech.atlassian.net/browse/CPD-8128
      items?.forEach(field => {
        // BATCHED_FIELD_QUERY_KEY fields must be complete.
        // This works only when includeAllDetails == true
        queryClient.setQueryData([BATCHED_FIELD_QUERY_KEY, field.resourceId], field)
      })

      return {
        ...resources,
        items,
        query: params,
      } as PaginationResponse<FieldModel>
    },

    placeholderData: keepPreviousData,
    retry: false,
    ...options,
  })
}

export const PROFILING_QUERY_KEY = ['profiling']

export const useProfileFields = (
  page: number,
  pageSize: number,
  params: Pick<UsePaginatedFieldsSearchParams, 'sort' | 'resourceIds'> & { programIds?: string[] },
  queryKey: QueryKey = PROFILING_QUERY_KEY
) => {
  const queryClient = useQueryClient()

  useDetailsChanged<FieldModel[]>(event => {
    const updates = Array.isArray(event.action.data) ? event.action.data : [event.action.data]

    queryClient.setQueryData<PaginationResponse<FieldModel>>(
      queryKey,

      old => {
        if (!old?.items?.length) {
          return old
        }

        const newItems: FieldModel[] = []

        old.items.forEach(existing => {
          const update = updates.find(
            ({ resourceId }: FieldModel) => resourceId === existing.resourceId
          )

          newItems.push(update || existing)
        })

        return {
          ...old,
          items: newItems,
        }
      }
    )
  })

  return useQuery({
    queryKey,
    queryFn: async () => {
      const { sort, programIds, ...passthroughParams } = params || {}
      const resources = await FieldsAPI.paginatedFields({
        offset: page * pageSize,
        limit: pageSize,
        anyPrograms: !!programIds
          ? programIds.map(id => ({
              id,
              benchmarks: [{ benchmark: 'profile', status: 'incomplete' }],
            }))
          : undefined,
        footprintResolved: true,
        ...passthroughParams,
        includeAllDetails: true,
        sort: sort as SortModel<FieldQuerySortFields>,
      })

      return {
        ...resources,
        items: resources?.items.map((r: Field) => new FieldModel(r)),
        query: params,
      } as PaginationResponse<FieldModel>
    },
    enabled: !!params?.programIds?.length,
    gcTime: Infinity,
  })
}

export const useUpdateFieldMetadata = () => {
  const queryClient = useQueryClient()

  const invalidateBatchedFields = useInvalidateBatchedFields()

  return useMutation({
    mutationFn: (request: FieldMetadataUpdateRequest) => FieldsAPI.updateMetadata(request),
    onSuccess: (event, request) => {
      queryClient.invalidateQueries({ queryKey: [LAND_REPORT_QUERY_KEY.LAND_REPORT] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })

      invalidateBatchedFields(request.resourceIds)
    },
  })
}

export type AssignFieldsVariables = { context?: any }

export const useAssignFields = () => {
  const queryClient = useQueryClient()

  const invalidateBatchedFields = useInvalidateBatchedFields()

  return useMutation<
    AxiosResponse<unknown>,
    DefaultError,
    AssignFieldsRequestParams & AssignFieldsVariables
  >({
    mutationKey: [QUERY_KEY.FIELD_ASSIGN_USER],
    mutationFn: ({ context, ...params }) => FieldsAPI.assign(params),
    onSuccess: (response, request) => {
      queryClient.invalidateQueries({ queryKey: [LAND_REPORT_QUERY_KEY.LAND_REPORT] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })

      invalidateBatchedFields(request.resourceIds)
    },
    onError: (error: any, variables, context) => {
      logRequestError(error, { query: 'useAssignFields', variables, context })
      return error
    },
  })
}

export const useFieldProgramStats = ({
  programId,
  options,
  userId,
}: {
  programId: FDRStatusQuery['programId']
  options?: Partial<UseQueryOptions>
  userId?: FieldQueryOptions['owner']
}) =>
  useQuery({
    queryKey: [QUERY_KEY.FIELD_STATS, { programId, userId }],
    queryFn: () =>
      FieldsAPI.stats({
        owner: userId,
        fdrStats: [
          FDRStatus.eligibleForEnrollment,
          FDRStatus.enrolling,
          FDRStatus.enrolled,
        ].map(status => ({ status, programId })),
      }),
    // @ts-ignore
    select: (response: AxiosResponse<FieldFDRStatsResponse>) => response.data.fdrStats,
    ...options,
  })

export const useEligibleProgramCount = ({
  options,
  userId,
}: {
  options?: UseQueryOptions
  userId?: FieldQueryOptions['owner']
}) =>
  useQuery({
    queryKey: [QUERY_KEY.FIELD_STATS, 'allPrograms', { userId }],
    queryFn: async () => {
      const programs = await GrowerProgramsAPI.allPrograms()
      return await Promise.all(
        programs.map(({ programId }) =>
          FieldsAPI.stats({
            owner: userId,
            fdrStats: [{ programId, status: FDRStatus.eligibleForEnrollment }],
          }).then(({ data }) =>
            data.fdrStats.map(({ fields, status }) =>
              !!fields && status === FDRStatus.eligibleForEnrollment ? 1 : 0
            )
          )
        )
      ).then(values => sum(flatten(values)))
    },
  })

/**
 * Take care when using this hook. It will return all fields matching the query, which could be a lot.
 * @param query
 * @param options
 * @returns
 */
export const useAllFieldsGeoJSON = (
  query?: Omit<FieldQueryOptions, 'limit' | 'offset'>,
  options?: { enabled: boolean }
) => {
  return useQuery({
    queryKey: [QUERY_KEY.ALL_FIELDS, query],
    queryFn: async () => {
      const total = await FieldsAPI.paginatedFields({ limit: 1, ...query }).then(
        ({ numAvailable }) => numAvailable
      )
      const chunks = Array.from({ length: Math.ceil(total / FIELD_QUERY_MAX_LIMIT) }, (_, i) => i)
      const fields = await Promise.all(
        chunks.map(i =>
          FieldsAPI.paginatedFields({
            ...query,
            offset: i * FIELD_QUERY_MAX_LIMIT,
            limit: FIELD_QUERY_MAX_LIMIT,
          }).then(({ items }) => items.map(field => new FieldModel(field)))
        )
      )
        .then(flatten)
        .then(fields => fields.filter(field => !!field.geometry))

      return {
        numAvailable: total,
        items: {
          shapes: featureCollection(fields.map(field => feature(field.geometry))),
          points: featureCollection(fields.map(field => centerOfMass(field.geometry))),
        },
        params: query,
      }
    },

    enabled: !!query,
    placeholderData: keepPreviousData,
    ...options,
  })
}
