import { ResourceDetailYearlyEvents } from './ResourceDetail'

export const FERTILIZER_TYPE_UREA = ['urea', 'nh3']
export const FERTILIZER_TYPE_UAN = ['uan28', 'uan30', 'uan32']
export const FERTILIZER_TYPE_LB_PER_ACRE = [
  'amp_10_34_0',
  'ams_21_0_0_24',
  'ats_12_0_0_26',
  'dap_18_46_0',
  'mesz_12_40_0_10',
  'map_11_52_0',
  'starter',
] as const
export const FERTILIZER_GALLONS_PER_ACRE = ['uan28', 'uan30', 'uan32']
export const FERTILIZER_WEIGHT_PER_ACRE = ['nh3', 'urea']
export const FERTILIZER_TYPE_RATE_UNIT = [
  ...FERTILIZER_GALLONS_PER_ACRE,
  ...FERTILIZER_WEIGHT_PER_ACRE,
] as const
export const FERTILIZER_TYPE_OTHER = ['other'] as const
export const FERTILIZER_TYPE = [
  ...FERTILIZER_TYPE_LB_PER_ACRE,
  ...FERTILIZER_TYPE_RATE_UNIT,
  ...FERTILIZER_TYPE_OTHER,
] as const
export type FertilizerType = typeof FERTILIZER_TYPE[number]

export const FERTILIZER_UNIT = [
  'poundsOfNitrogenPerAcre',
  'gallonsPerAcre',
  'poundsPerAcre',
  'ouncesPerAcre',
] as const
export type FertilizerUnit = typeof FERTILIZER_UNIT[number]

export enum FertilizerComponents {
  NITROGEN = 'nitrogenPer',
  PHOSPHORUS = 'phosphorusPer',
  SULFUR = 'sulfurPer',
  POTASSIUM = 'potassiumPer',
}
export const FERTILIZER_COMPONENTS = [
  FertilizerComponents.NITROGEN,
  FertilizerComponents.PHOSPHORUS,
  FertilizerComponents.POTASSIUM,
  FertilizerComponents.SULFUR,
]

export const FERTILIZER_METHODS = [
  'notApplicable',
  'none',
  'broadcast',
  'incorporated',
  'infurrow',
  'injected',
  'sidedress',
  'spray',
  'other',
] as const

export type FertilizerMethods = typeof FERTILIZER_METHODS[number]

export const UNITS_BY_TYPE: Record<FertilizerType, FertilizerUnit[]> = {
  uan28: ['poundsOfNitrogenPerAcre', 'gallonsPerAcre'],
  uan30: ['poundsOfNitrogenPerAcre', 'gallonsPerAcre'],
  uan32: ['poundsOfNitrogenPerAcre', 'gallonsPerAcre'],
  urea: ['poundsOfNitrogenPerAcre', 'poundsPerAcre', 'ouncesPerAcre'],
  nh3: ['poundsOfNitrogenPerAcre', 'poundsPerAcre', 'ouncesPerAcre'],
}

export const COMPONENTS_BY_TYPE: Record<
  FertilizerType,
  {
    [FertilizerComponents.NITROGEN]: number
    [FertilizerComponents.PHOSPHORUS]: number
    [FertilizerComponents.POTASSIUM]: number
    [FertilizerComponents.SULFUR]: number
  }
> = {
  uan28: {
    [FertilizerComponents.NITROGEN]: 28,
    [FertilizerComponents.PHOSPHORUS]: 0,
    [FertilizerComponents.POTASSIUM]: 0,
    [FertilizerComponents.SULFUR]: 0,
  },
  uan30: {
    [FertilizerComponents.NITROGEN]: 30,
    [FertilizerComponents.PHOSPHORUS]: 0,
    [FertilizerComponents.POTASSIUM]: 0,
    [FertilizerComponents.SULFUR]: 0,
  },
  uan32: {
    [FertilizerComponents.NITROGEN]: 32,
    [FertilizerComponents.PHOSPHORUS]: 0,
    [FertilizerComponents.POTASSIUM]: 0,
    [FertilizerComponents.SULFUR]: 0,
  },
  urea: {
    [FertilizerComponents.NITROGEN]: 46,
    [FertilizerComponents.PHOSPHORUS]: 0,
    [FertilizerComponents.POTASSIUM]: 0,
    [FertilizerComponents.SULFUR]: 0,
  },
  nh3: {
    [FertilizerComponents.NITROGEN]: 82,
    [FertilizerComponents.PHOSPHORUS]: 0,
    [FertilizerComponents.POTASSIUM]: 0,
    [FertilizerComponents.SULFUR]: 0,
  },
  amp_10_34_0: {
    [FertilizerComponents.NITROGEN]: 10,
    [FertilizerComponents.PHOSPHORUS]: 34,
    [FertilizerComponents.POTASSIUM]: 0,
    [FertilizerComponents.SULFUR]: 0,
  },
  ams_21_0_0_24: {
    [FertilizerComponents.NITROGEN]: 21,
    [FertilizerComponents.PHOSPHORUS]: 0,
    [FertilizerComponents.POTASSIUM]: 0,
    [FertilizerComponents.SULFUR]: 24,
  },
  ats_12_0_0_26: {
    [FertilizerComponents.NITROGEN]: 12,
    [FertilizerComponents.PHOSPHORUS]: 0,
    [FertilizerComponents.POTASSIUM]: 0,
    [FertilizerComponents.SULFUR]: 26,
  },
  dap_18_46_0: {
    [FertilizerComponents.NITROGEN]: 18,
    [FertilizerComponents.PHOSPHORUS]: 46,
    [FertilizerComponents.POTASSIUM]: 0,
    [FertilizerComponents.SULFUR]: 0,
  },
  mesz_12_40_0_10: {
    [FertilizerComponents.NITROGEN]: 12,
    [FertilizerComponents.PHOSPHORUS]: 40,
    [FertilizerComponents.POTASSIUM]: 0,
    [FertilizerComponents.SULFUR]: 10,
  },
  map_11_52_0: {
    [FertilizerComponents.NITROGEN]: 11,
    [FertilizerComponents.PHOSPHORUS]: 52,
    [FertilizerComponents.POTASSIUM]: 0,
    [FertilizerComponents.SULFUR]: 0,
  },
}

export type RDFertilizerEvent = {
  [FertilizerComponents.NITROGEN]: number
  [FertilizerComponents.PHOSPHORUS]: number
  [FertilizerComponents.POTASSIUM]: number
  [FertilizerComponents.SULFUR]: number
  ammonium: boolean
  amtPerAcre?: number
  date: Date
  inhibitor: boolean
  method: FertilizerMethods
  rate: number
  slowRelease: boolean
  type: FertilizerType
  units: FertilizerUnit

  nitrogenAmnt?: number
  product?: string
}

export type FertilizerEvents = {
  events: RDFertilizerEvent[]
}

export interface RDFertilizer extends ResourceDetailYearlyEvents<FertilizerEvents, number> {
  traitId: 'fertilizer'
}
