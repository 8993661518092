export const descendDateProp = (prop: string) => (a: any, b: any) => {
  const dateA = a[prop]?.valueOf()
  const dateB = b[prop]?.valueOf()

  if (dateA && !dateB) {
    return -1
  }
  if (!dateA && dateB) {
    return 1
  }
  if (!dateA && !dateB) {
    return 0
  }

  return dateB - dateA
}
