import {
  FieldModel,
  GeneralResourceDetail,
  IntegerString,
  ProgramEngagementModel,
  ResourceDetailsUpdate,
} from '@cibo/core'
import {
  fieldCoverCropScenarioSelection,
  useFieldColumns,
  useUpdateMultipleFields,
} from '@cibo/landmanager'
import { DataGridPro, Markdown, MarkdownLink, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionDetails, AccordionSummary, Typography, styled, useTheme } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import { GridColDef, GridRowId, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PROGRAMS_QUERY_KEY } from '../../queries'
import { useEqipEngagementFieldsWithConcerns } from '../../queries/useEqipRecommendations'
import { doesFieldHaveIncompatiblePractice } from './doesFieldHaveIncompatiblePractice'
import { fieldProgramStatusColumn } from './fieldProgramStatusColumn'

export type FieldsSelectionCardProps<T extends GeneralResourceDetail = GeneralResourceDetail> =
  ResourceDetailFeatureTaskEditorProps<T> & {
    practiceName: string
    practiceDefinition?: string
    engagement?: ProgramEngagementModel
    resourceConcernsAddressed?: string
    practiceId?: IntegerString
    expanded?: boolean
  }

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 4,
  '&:before': {
    display: 'none',
  },
}))

export const FieldSelectionCard = <T extends GeneralResourceDetail = GeneralResourceDetail>({
  engagement,
  detailRequirements,
  onError,
  onSuccess,
  onUpdating,
  practiceName,
  practiceId,
  practiceDefinition,
  resourceConcernsAddressed,
  expanded: initialExpanded,
}: FieldsSelectionCardProps<T>) => {
  const { t } = useTranslation('@cibo/programs/FieldsSelection')
  const { palette } = useTheme()
  const updateFields = useUpdateMultipleFields()
  const [ready, setReady] = useState<boolean>(false)
  const [expanded, setExpanded] = useState(initialExpanded)
  const { traitId, year } = detailRequirements[0]
  const apiRef = useGridApiRef()
  const queryClient = useQueryClient()
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const isCoverCrop = practiceName === 'Cover Crop'

  const { fieldNameColumn } = useFieldColumns()

  const programStatusColumn = {
    ...fieldProgramStatusColumn({
      t,
      practiceId,
      year,
    }),
    flex: isCoverCrop ? 1 : 2,
  }

  const CoverCropScenarioSelectionColumn = fieldCoverCropScenarioSelection({ t })

  const columns = useMemo(() => {
    return isCoverCrop
      ? ([fieldNameColumn, programStatusColumn, CoverCropScenarioSelectionColumn] as GridColDef[])
      : ([fieldNameColumn, programStatusColumn] as GridColDef[])
  }, [])

  const fieldsWithConcerns = useEqipEngagementFieldsWithConcerns(engagement.id)

  useEffect(() => {
    if (!practiceId) return
    if (!ready && fieldsWithConcerns.isFetched && !!fieldsWithConcerns.dataUpdatedAt) {
      const selectedRows =
        fieldsWithConcerns.data
          ?.filter(field => field.selectedPractices?.includes(practiceId))
          .map(field => field.resourceId) ?? []

      apiRef.current?.selectRows(selectedRows)
      setReady(true)
    }
  }, [fieldsWithConcerns.dataUpdatedAt, apiRef.current, fieldsWithConcerns.isFetched])

  const onRowSelectionModelChange = (rowSelectionModel: GridRowId[]) => {
    if (!ready || !practiceId) return
    const updates = fieldsWithConcerns.data
      ?.map(field => {
        const selectedPractices = field.selectedPractices ?? []

        const addPracticeToField =
          rowSelectionModel.includes(field.id) && !selectedPractices.includes(practiceId)
        const removePracticeFromField =
          !rowSelectionModel.includes(field.id) && selectedPractices?.includes(practiceId)

        if (addPracticeToField) {
          return {
            resourceId: field.id,
            details: [
              {
                traitId,
                input: { selectedPractices: [...selectedPractices, practiceId] },
                year,
              },
            ],
          }
        } else if (removePracticeFromField) {
          const newPractices = selectedPractices.filter((a: string) => a !== practiceId)
          const value =
            newPractices.length > 0
              ? { input: { selectedPractices: newPractices } }
              : { result: undefined }
          return {
            resourceId: field.id,
            details: [
              {
                traitId,
                ...value,
                year,
              },
            ],
          }
        } else {
          return undefined
        }
      })
      .filter(a => a !== undefined) as ResourceDetailsUpdate[]
    onUpdating?.()
    updates.length > 0
      ? updateFields
          .mutateAsync(updates)
          .then(() => {
            queryClient.invalidateQueries({
              queryKey: [PROGRAMS_QUERY_KEY.EQIP_RECOMMENDATIONS, engagement.id],
            })
            !!onSuccess && onSuccess()
          })
          .catch(onError)
      : // @ts-ignore we do want to register this error in the UI and console
        onError('selection model change did not result in updates')
  }

  const isRowSelectable = ({
    row,
  }: GridRowParams<FieldModel & { availablePractices?: IntegerString[] }>) => {
    return (
      !!practiceId &&
      !!row.availablePractices?.includes(practiceId) &&
      !doesFieldHaveIncompatiblePractice(row, practiceId, year)
    )
  }

  return (
    <Accordion expanded={expanded} onChange={handleExpandClick}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label={t('expand', { practiceName: practiceName })}
        sx={{ pt: 1, pb: 1 }}
        onClick={handleExpandClick}
      >
        <Typography>{practiceName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Markdown
          typographyProps={{ color: 'text.secondary' }}
          overrides={{
            a: {
              component: MarkdownLink,
            },
          }}
          sx={{
            '& a': { color: palette.secondary.main },
            mb: 1,
          }}
        >
          {t('practiceSelectionSummary', {
            practiceDefinition,
            resourceConcernsAddressed,
          })}
        </Markdown>
        <DataGridPro
          apiRef={apiRef}
          checkboxSelection
          rows={fieldsWithConcerns.data || []}
          getRowHeight={() => 'auto'}
          columns={columns}
          loading={updateFields.isPending}
          autoHeight
          onRowSelectionModelChange={onRowSelectionModelChange}
          isRowSelectable={isRowSelectable}
        />
      </AccordionDetails>
    </Accordion>
  )
}
