import { fieldColumnAttributes, LandThumbnail, LandThumbnailSkin } from '@cibo/landmanager'
import { Markdown, useFigures } from '@cibo/ui'
import { Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ResourceConcernsCell } from '../../features/ResourceDetailFeatures/EQIP/RDEqipReviewRecommendationResultsResourceDetailFeature/ResourceConcernsCell'
import { useEqipEngagementFieldsWithConcerns } from '../../queries/useEqipRecommendations'
import { PracticesSelectedCell } from '../PracticeSelectionConfirmation'
import { PdfLoadingIndicator } from './PdfLoadingIndicator'

type SelectedPracticesTableProps = {
  engagementId: string
}

export const SelectedPracticesTable = ({ engagementId }: SelectedPracticesTableProps) => {
  const { t } = useTranslation('@cibo/programs/EqipSelectedPracticesPDF')

  const fieldModels = useEqipEngagementFieldsWithConcerns(engagementId)

  const { acres } = useFigures()

  const rows = useMemo(
    () =>
      fieldModels.data?.map(field => {
        const detail = field.findDetail({ traitId: 'eqipEligibilityFarmInfo' })

        return {
          ...fieldColumnAttributes(field),
          ...detail?.result,
          ...field,
        }
      }),
    [fieldModels.isFetched]
  )

  if (fieldModels.isPending) return <PdfLoadingIndicator />

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Resource Concern</TableCell>
            <TableCell>Practices Selected</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map(row => (
            <Fragment key={row.id}>
              {/* @ts-ignore */}
              <TableRow style={{ 'break-inside': 'avoid' }}>
                <TableCell sx={{ width: 200 }}>
                  <Stack>
                    <Stack direction="row" spacing={1.5} paddingY={0.75} alignItems={'center'}>
                      <LandThumbnail
                        // @ts-ignore
                        field={row}
                        resourceId={row.id}
                        skin={LandThumbnailSkin.SMALL}
                        height={68}
                        width={68}
                        lazyLoadImages={false}
                      />
                      <Stack>
                        <Typography variant="body2">{row.name}</Typography>
                        <Typography variant="caption">{acres(row.acres)}</Typography>
                        <Markdown typographyProps={{ variant: 'caption' }}>
                          {t('farm', { farmNumber: row.farmNumber })}
                        </Markdown>
                        <Markdown typographyProps={{ variant: 'caption' }}>
                          {t('tract', { tractNumber: row.tractNumber })}
                        </Markdown>
                      </Stack>
                    </Stack>
                  </Stack>
                </TableCell>

                <TableCell>
                  {/* @ts-ignore */}
                  <ResourceConcernsCell row={row} value={row.resourceConcerns} />
                </TableCell>

                <TableCell>
                  {/* @ts-ignore */}
                  <PracticesSelectedCell row={row} value={row.selectedPractices} />
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
