import { ConvertedChoices, NativeVegetationChoices, RDNativeConversion, TraitId } from '@cibo/core'
import { pathEq } from 'ramda'
import * as Yup from 'yup'
import { ITraitFeatureById } from '../types'
import { NativeConversionCell } from './NativeConversionCell'
import { NativeConversionEditor } from './NativeConversionEditor'
import { NativeConversionRollup } from './NativeConversionRollup'

const TRAIT_ID: TraitId = 'nativeVegetationConversion'

const NativeConversion: ITraitFeatureById<RDNativeConversion> = {
  traitId: TRAIT_ID,
  cellDisplay: NativeConversionCell,
  editor: NativeConversionEditor,

  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: NativeConversionRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        input: {
          converted: 'no',
        },
      }),
      isRollupValue: pathEq(['result'], 'no'),
    },
  },

  //@ts-ignore overridden for result and input individually
  initialValues: ({ fieldModels }) => undefined,

  ns: '@cibo/landmanager/NativeConversionEditor',
  validationSchema: ({ t }) =>
    Yup.object({
      converted: Yup.string<ConvertedChoices>().required(t('responseRequired')),
      conversionYear: Yup.number()
        .test('unknown', t('yearOrUnsureRequired'), function (value) {
          return (
            !!this.parent.unsureOfYear ||
            this.parent.converted === 'unsure' ||
            this.parent.converted === 'no' ||
            !isNaN(+this.parent.conversionYear) ||
            !!value
          )
        })
        .optional(),
      nativeVegetation: Yup.string<NativeVegetationChoices>().when('converted', {
        is: 'yes',
        then: Yup.string<NativeVegetationChoices>().required(t('landUseRequired')),
        otherwise: Yup.string().nullable(),
      }),
      unsureOfYear: Yup.boolean()
        .test('unknown', t('yearOrUnsureRequired'), function (value) {
          return this.parent.converted !== 'yes' || !isNaN(+this.parent.conversionYear) || !!value
        })
        .optional(),
    }).required(),

  detailFromEditorValues: values => {
    return {
      traitId: TRAIT_ID,
      input: {
        ...values.input,
      },
    }
  },
}

export const NativeConversionResult: ITraitFeatureById<RDNativeConversion> = {
  ...NativeConversion,
  initialValues: ({ fieldModels }) =>
    fieldModels.length === 1 && fieldModels[0].findDetail({ traitId: 'nativeVegetationConversion' })
      ? fieldModels[0].findDetail({ traitId: 'nativeVegetationConversion' })?.result
      : {},
}
export const NativeConversionInput: ITraitFeatureById<RDNativeConversion> = {
  ...NativeConversion,
  initialValues: ({ fieldModels }) => {
    if (fieldModels.length === 1) {
      const detail =
        fieldModels[0].findDetail<RDNativeConversion>({ traitId: 'nativeVegetationConversion' }) ??
        ({} as RDNativeConversion)

      return detail?.input
        ? { unsureOfYear: undefined, conversionYear: '', nativeVegetation: '', ...detail.input }
        : {
            unsureOfYear: undefined,
            conversionYear: '',
            nativeVegetation: '',
            converted: detail.result,
          }
        ? {
            unsureOfYear: undefined,
            conversionYear: '',
            nativeVegetation: '',
            converted: detail.result,
          }
        : {
            unsureOfYear: undefined,
            conversionYear: '',
            nativeVegetation: '',
          }
    }
    return {}
  },
}
