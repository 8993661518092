import { FSAFarm, RDEqipEligibilityFarmInfo } from '@cibo/core'
import { AddCellValueButton, DataGridPro, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Stack } from '@mui/material'
import {
  GridCellParams,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowHeightReturnValue,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { any, equals, prop } from 'ramda'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { fieldColumnAttributes, useFieldColumns } from '../../../../../components/FieldColumns'
import { useBatchedFields, useUpdateMultipleFields } from '../../../../../queries'
import {
  useAssignFieldsToFSAFarm,
  useUnassignFieldsToFSAFarm,
} from '../../../../../queries/useFSAFarms'
import { FarmNumberPicker } from './FarmNumberPicker'

type FarmInfoRow = ReturnType<typeof fieldColumnAttributes> & {
  id: string
  farmNumber: string
  tractNumber: string
}

type FarmInfoTaskEditorTableProps = Pick<
  ResourceDetailFeatureTaskEditorProps<RDEqipEligibilityFarmInfo>,
  'resourceIds' | 'detailRequirements' | 'onSuccess' | 'onError' | 'onUpdating'
>

export const FarmInfoTaskEditorTable = ({
  resourceIds,
  detailRequirements,
  onSuccess,
  onError,
  onUpdating,
}: FarmInfoTaskEditorTableProps) => {
  const { t } = useTranslation('@cibo/landmanager/FarmInfo')
  const { traitId } = detailRequirements[0]
  const gridApi = useGridApiRef()
  const { enqueueSnackbar } = useSnackbar()

  const fieldModels = useBatchedFields(resourceIds)
  const updateFields = useUpdateMultipleFields()
  const assignFields = useAssignFieldsToFSAFarm()
  const unassignFields = useUnassignFieldsToFSAFarm()

  const { fieldNameColumn } = useFieldColumns()

  const busy = any(prop('isPending'), [fieldModels, updateFields, assignFields, unassignFields])

  const clearFarmTermset = (fieldId: string) => {
    const field = fieldModels.data?.find(field => field.resourceId === fieldId)
    if (!field) return

    const farmNumber = field.metadata.termsets?.find(termset => termset.type === 'FsaNumber')
    if (!farmNumber) return

    return unassignFields.mutateAsync({ fieldIds: [fieldId], termsetId: farmNumber.id })
  }

  const columns = useMemo(() => {
    return [
      { ...fieldNameColumn, sortable: false },
      {
        field: 'farmNumber',
        headerName: t('farmNumber'),
        sortable: false,
        disableColumnMenu: true,
        flex: 0.5,
        editable: true,
        renderEditCell: (params: GridRenderEditCellParams) => (
          <FarmNumberPicker
            onChange={(farm: FSAFarm) => {
              assignFields.mutateAsync({ fieldIds: [params.row.id], termsetId: farm.id })

              gridApi.current?.setEditCellValue({
                id: params.id,
                field: 'farmNumber',
                value: farm.farmSerialNumber,
              })
              gridApi.current?.getCellMode(params.id, 'farmNumber') === 'edit' &&
                gridApi.current?.stopCellEditMode({ id: params.id, field: 'farmNumber' })
            }}
            onClear={() => {
              clearFarmTermset(params.id as string)

              gridApi.current?.setEditCellValue({
                id: params.id,
                field: 'farmNumber',
                value: undefined,
              })
            }}
            ownerId={params.row.ownerId}
            value={params.value}
            disabled={params.row.immutable}
          />
        ),
        renderCell: (params: GridRenderCellParams) =>
          params.value && params.value !== '' ? (
            params.value
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              flex={1}
              height="100%"
            >
              <AddCellValueButton {...params} data-testid="add-farm-number" />
            </Stack>
          ),
      },
      {
        field: 'tractNumber',
        headerName: t('tractNumber'),
        sortable: false,
        disableColumnMenu: true,
        flex: 0.5,
        editable: true,
        renderCell: (params: GridRenderCellParams) =>
          params.value && params.value !== '' ? (
            params.value
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              flex={1}
              height="100%"
            >
              <AddCellValueButton {...params} data-testid="add-tract-number" />
            </Stack>
          ),
      },
    ]
  }, [])

  const rows = useMemo(
    () =>
      fieldModels.data?.map(field => {
        const detail = field.findDetail({ traitId })

        return {
          ...fieldColumnAttributes(field),
          ...detail?.result,
          immutable: detail?.immutable,
        }
      }),
    [fieldModels.isFetched]
  )

  const processRowUpdate = useCallback(
    (updated: FarmInfoRow, original: FarmInfoRow) => {
      const existingValues = {
        farmNumber: original.farmNumber,
        tractNumber: original.tractNumber === '' ? undefined : original.tractNumber,
      }

      const farmNumber = updated.farmNumber
      const tractNumber = updated.tractNumber !== '' ? updated.tractNumber : undefined

      const newValues = { farmNumber, tractNumber }

      if (equals(existingValues, newValues)) return updated

      onUpdating?.()
      updateFields
        .mutateAsync([
          {
            resourceId: updated.id as string,
            details: [
              {
                traitId,
                input: farmNumber || tractNumber ? { farmNumber, tractNumber } : undefined,
              },
            ],
          },
        ])
        .catch(error => {
          enqueueSnackbar(t('errorSavingDetail'), { variant: 'error' })

          onError?.(error)
        })
        .then(onSuccess)

      return updated
    },
    [updateFields, onUpdating, onSuccess, onError]
  )

  const getRowHeight = useCallback((): GridRowHeightReturnValue => 'auto', [])

  return (
    <DataGridPro<FarmInfoRow>
      apiRef={gridApi}
      autoHeight
      columns={columns}
      disableColumnSelector
      disableRowSelectionOnClick
      getRowHeight={getRowHeight}
      isCellEditable={(params: GridCellParams) => !params.row.immutable}
      loading={busy}
      rows={rows || []}
      processRowUpdate={processRowUpdate}
    />
  )
}
