import { useBatchedField } from '@cibo/landmanager'
import { FriendlyError, Markdown, ResponsiveDialog } from '@cibo/ui'
import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrograms, useUnenrollField } from '../../queries'
import { ProgramIcon } from '../ProgramIcon'

export type UnenrollFieldModalProps = {
  onRequestClose(): void
  open: boolean
  fieldId?: string
}

export const UnenrollFieldModal = ({ open, onRequestClose, fieldId }: UnenrollFieldModalProps) => {
  const { t } = useTranslation('@cibo/programs/UnenrollFieldModal')

  const [error, setError] = useState<Error | undefined>()
  const [programId, setProgramId] = useState<string>()
  const unenrollField = useUnenrollField()
  const programsQuery = usePrograms()
  const field = useBatchedField(fieldId)
  useEffect(() => {
    if (typeof field.data?.participatingPrograms) {
      const programs = field.data?.participatingPrograms
      if (programs && programs.length === 1) {
        setProgramId(programs[0].programId)
      }
    }
  }, [field.dataUpdatedAt])

  const availablePrograms = useMemo(
    () =>
      programsQuery.data?.filter(
        p =>
          typeof field.data?.participatingPrograms &&
          field.data?.participatingPrograms?.find(program => program.programId === p.programId)
      ),
    [field.data, programsQuery.data]
  )

  const onSave = () => {
    if (!programId || !fieldId) return
    unenrollField.mutateAsync(
      { fieldId, programId },
      {
        onSuccess: () => {
          setError(undefined)
          onRequestClose()
        },
        onError: error => setError(error as Error),
      }
    )
  }
  if (!availablePrograms || availablePrograms.length === 0) {
    return null
  }

  return (
    <ResponsiveDialog open={open} onClose={onRequestClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('unenrollField')}</DialogTitle>
      <DialogContent>
        {availablePrograms.length === 1 ? (
          <Markdown>
            {t('unenrollingFieldProgram', {
              name: field.data?.name,
              programName: availablePrograms[0].program.content.fields.title,
            })}
          </Markdown>
        ) : (
          <Stack spacing={2}>
            <Markdown>{t('unenrollingField', { name: field.data?.name })}</Markdown>
            <Stack>
              {availablePrograms?.map(p => (
                <Button
                  key={p.programId}
                  data-testid={`enroll_${p.programId}`}
                  size="large"
                  startIcon={<ProgramIcon program={p.program} />}
                  variant={programId === p.programId ? 'contained' : undefined}
                  color={programId === p.programId ? 'secondary' : undefined}
                  sx={{ justifyContent: 'flex-start' }}
                  onClick={() => setProgramId(p.programId)}
                >
                  {p.program.content.fields.title}
                </Button>
              ))}
            </Stack>
          </Stack>
        )}
        {error && <FriendlyError message={error.message} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose}>{t('cancel')}</Button>
        <LoadingButton
          loading={unenrollField.isPending}
          variant="contained"
          onClick={onSave}
          disabled={!programId}
          color="error"
        >
          {t('confirm')}
        </LoadingButton>
      </DialogActions>
    </ResponsiveDialog>
  )
}
