import { FieldModel, TraitId } from '@cibo/core'
import { Checkbox } from '@mui/material'
import {
  unstable_composeClasses as composeClasses,
  unstable_useForkRef as useForkRef,
} from '@mui/utils'
import {
  GridRenderCellParams,
  GridRowSelectionCheckboxParams,
  getDataGridUtilityClass,
  useGridApiContext,
  useGridRootProps,
} from '@mui/x-data-grid-pro'
import { DataGridProProcessedProps } from '@mui/x-data-grid-pro/models/dataGridProProps'
import * as React from 'react'

type OwnerState = { classes: DataGridProProcessedProps['classes'] }

const useUtilityClasses = (ownerState: OwnerState) => {
  const { classes } = ownerState

  const slots = {
    root: ['checkboxInput'],
  }

  return composeClasses(slots, getDataGridUtilityClass, classes)
}

interface TouchRippleActions {
  stop: (event: any, callback?: () => void) => void
}

const GridCellCheckboxForwardRef = React.forwardRef<
  HTMLInputElement,
  GridRenderCellParams<FieldModel, { traitId: TraitId; immutable?: boolean }> & {
    traitId: TraitId
    year?: number
  }
>(function GridCellCheckboxRenderer(props, ref) {
  const { field, id, value, row, rowNode, hasFocus, tabIndex, traitId, year } = props

  const isChecked = !!value

  const apiRef = useGridApiContext()
  const rootProps = useGridRootProps()
  const ownerState = { classes: rootProps.classes }
  const classes = useUtilityClasses(ownerState)
  const checkboxElement = React.useRef<HTMLElement>(null)

  const rippleRef = React.useRef<TouchRippleActions>(null)
  const handleRef = useForkRef(checkboxElement, ref)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const params: GridRowSelectionCheckboxParams = { value: event.target.checked, id }
    apiRef.current.publishEvent('rowSelectionCheckboxChange', params, event)
  }

  React.useLayoutEffect(() => {
    if (tabIndex === 0) {
      const element = apiRef.current.getCellElement(id, field)
      if (element) {
        element.tabIndex = -1
      }
    }
  }, [apiRef, tabIndex, id, field])

  React.useEffect(() => {
    if (hasFocus) {
      const input = checkboxElement.current?.querySelector('input')
      input?.focus({ preventScroll: true })
    } else if (rippleRef.current) {
      // Only available in @mui/material v5.4.1 or later
      rippleRef.current.stop({})
    }
  }, [hasFocus])

  const handleKeyDown = React.useCallback((event: React.KeyboardEvent) => {
    if (event.key === ' ') {
      // We call event.stopPropagation to avoid selecting the row and also scrolling to bottom
      // TODO: Remove and add a check inside useGridKeyboardNavigation
      event.stopPropagation()
    }
  }, [])

  // @ts-ignore copied from GridCellCheckbox source
  if (rowNode.type === 'footer' || rowNode.type === 'pinnedRow') {
    return null
  }

  const isSelectable = apiRef.current.isRowSelectable(id)

  const label = apiRef.current.getLocaleText(
    isChecked ? 'checkboxSelectionUnselectRow' : 'checkboxSelectionSelectRow'
  )

  const existingDetail = row.findDetail({ traitId, year })

  const immutable = existingDetail?.immutable
  return (
    <Checkbox
      ref={handleRef}
      tabIndex={tabIndex}
      checked={isChecked}
      onChange={handleChange}
      className={classes.root}
      inputProps={{ 'aria-label': label }}
      onKeyDown={handleKeyDown}
      disabled={!isSelectable || immutable}
      touchRippleRef={rippleRef as any /* FIXME: typing error */}
      // @ts-ignore copied from GridCellCheckbox source
      {...rootProps.slotProps?.baseCheckbox}
    />
  )
})

export const BooleanDetailCheckboxCellRenderer = GridCellCheckboxForwardRef
