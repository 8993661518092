import { GrowerProgramsAPI, ProgramConfig, ProgramModel } from '@cibo/core'
import { useQuery } from '@tanstack/react-query'
import { omit, propEq } from 'ramda'
import { PROGRAMS_QUERY_KEY } from './queryKey'
import { useAllProgramContent } from './useProgramContent'

export const useProgramConfigs = () => {
  const { data: allPrograms } = useAllProgramContent()

  return useQuery({
    queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_CONFIGS],
    queryFn: async () => {
      const responses = await Promise.all(
        allPrograms?.map(({ programId }) =>
          //@temp on 12/1/2023 there was an incident where the response dropped the programId
          GrowerProgramsAPI.program({ programId }).then(response => ({ ...response, programId }))
        ) || []
      )

      return responses
        .map(
          // include static config data only - not status data
          omit(['opportunities', 'participatingResources', 'phases', 'resourceCounts'])
        )
        .map(config => new ProgramModel(config as ProgramConfig))
    },
    enabled: !!allPrograms,
    gcTime: Infinity,
    staleTime: Infinity,
  })
}

export const useProgramConfig = ({ programId }: { programId: string | undefined }) => {
  const programConfigs = useProgramConfigs()

  return {
    ...programConfigs,
    data: programConfigs.data?.find(propEq('programId', programId)),
  }
}
