import { RDConversionDate, TraitId } from '@cibo/core'
import { pathEq } from 'ramda'
import * as Yup from 'yup'

import { ITraitFeatureById } from '../types'
import { NativeConversionDateCell } from './NativeConversionDateCell'
import { NativeConversionDateEditor } from './NativeConversionDateEditor'
import { NativeConversionDateRollup } from './NativeConversionDateRollup'

const TRAIT_ID: TraitId = 'nativeVegetationConversionDate'
const TEN_YEARS_AGO = new Date()
TEN_YEARS_AGO.setFullYear(TEN_YEARS_AGO.getFullYear() - 10)

const NativeConversionDate: ITraitFeatureById<RDConversionDate> = {
  traitId: TRAIT_ID,
  cellDisplay: NativeConversionDateCell,
  editor: NativeConversionDateEditor,

  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: NativeConversionDateRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        input: {
          moreThanTenYearsAgo: true,
        },
      }),
      isRollupValue: pathEq(['result', 'moreThanTenYearsAgo'], true),
    },
  },

  //@ts-ignore overridden below
  initialValues: ({ fieldModels }) => undefined,

  ns: '@cibo/landmanager/NativeConversionDateEditor',
  validationSchema: ({ t }) =>
    Yup.object({
      moreThanTenYearsAgo: Yup.boolean().required(t('responseRequired')),
      conversionDate: Yup.date().when('moreThanTenYearsAgo', {
        is: false,
        then: Yup.date()
          .min(TEN_YEARS_AGO)
          .required(t('dateRequired'))
          .typeError(t('dateRequired')),
        otherwise: Yup.date().nullable(),
      }),
    }).required(),

  detailFromEditorValues: values => {
    return {
      traitId: TRAIT_ID,
      input: {
        moreThanTenYearsAgo: values.input.moreThanTenYearsAgo,
        conversionDate:
          values.input.moreThanTenYearsAgo === 'false' ? values.input.conversionDate : undefined,
      },
    }
  },
}

export const NativeConversionDateResult: ITraitFeatureById<RDConversionDate> = {
  ...NativeConversionDate,
  initialValues: ({ fieldModels }) =>
    fieldModels.length === 1 && fieldModels[0].findDetail<RDConversionDate>({ traitId: TRAIT_ID })
      ? fieldModels[0].findDetail({ traitId: TRAIT_ID })?.result
      : {},
}
export const NativeConversionDateInput: ITraitFeatureById<RDConversionDate> = {
  ...NativeConversionDate,
  initialValues: ({ fieldModels }) => {
    if (fieldModels.length === 1) {
      const detail =
        fieldModels[0].findDetail<RDConversionDate>({ traitId: TRAIT_ID }) ??
        ({} as RDConversionDate)

      return detail?.input
        ? { conversionDate: null, ...detail.input }
        : { conversionDate: null, ...detail.result }
        ? { conversionDate: null, ...detail.result }
        : {
            conversionDate: null,
          }
    }
    return {}
  },
}
