import { RDUsdaGrowerType, RDUsdaGrowerTypeYearly } from '@cibo/core'
import { ResourceDetailFeature } from '@cibo/ui'
import {
  RDUsdaGrowerTypeTaskEditor,
  RDUsdaGrowerTypeYearlyTaskEditor,
} from './RDUsdaGrowerTypeTaskEditor'

export const RDUsdaGrowerTypeResourceDetailFeature: ResourceDetailFeature<RDUsdaGrowerType> = {
  traitId: 'usdaGrowerType',
  TaskEditor: RDUsdaGrowerTypeTaskEditor,
}

export const RDUsdaGrowerTypeYearlyResourceDetailFeature: ResourceDetailFeature<RDUsdaGrowerTypeYearly> =
  {
    traitId: 'usdaGrowerTypeYearly',
    TaskEditor: RDUsdaGrowerTypeYearlyTaskEditor,
  }
