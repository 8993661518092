import { AuthUserPermission, OrgUserSearchResult, UserId } from '@cibo/core'
import {
  AssignUserControl,
  Can,
  Loading,
  useAuth,
  usePaginatedOrgUsers,
  useUser,
} from '@cibo/profile'
import { ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import Search from '@mui/icons-material/Search'
import {
  Alert,
  Button,
  Collapse,
  FormHelperText,
  FormLabel,
  Popover,
  Stack,
  Typography,
} from '@mui/material'
import { Field, Form, Formik, useFormikContext } from 'formik'
import { TextField } from 'formik-mui'
import { equals } from 'ramda'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import {
  useProgramEngagementDetails,
  useUpdateProgramEngagementDetails,
} from '../../../../hooks/useProgramEngagementDetails'
import { AdvisorInformation, EqipAdvisorInformation } from './types'

export const AdvisorInformationTaskEditorForm = ({
  detailRequirements,
  resourceIds: [resourceId],
  onUpdating,
  onError,
  onSuccess,
  userRole,
}: ResourceDetailFeatureTaskEditorProps<EqipAdvisorInformation>) => {
  const { t } = useTranslation('@cibo/programs/AdvisorInformationTaskEditor')
  const [userId, setUserId] = useState<UserId>()
  const [popperOpen, setPopperOpen] = useState<boolean>(false)
  const [emailKnown, setEmailKnown] = useState<boolean | undefined>(undefined)
  // @ts-ignore not sure how to tell our types this detail uses value: {...}
  const updateResourceDetail = useUpdateProgramEngagementDetails<EqipAdvisorInformation>()
  const details = useProgramEngagementDetails({ resourceId, detailRequirements })
  const detail = details?.data?.[0]
  const { data: currentUser } = useUser()
  const self = useAuth()
  const { values, isValid, dirty, setFieldValue, errors, validateForm } =
    useFormikContext<AdvisorInformation>()

  const paginatedUsers = usePaginatedOrgUsers({
    isActive: true,
    fullText: values.email,
    persona: ['admin', 'advisor'],
  })

  const anchorEl = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (!isValid || equals(detail?.value, values) || !dirty) return

    onUpdating?.()
    updateResourceDetail
      .mutateAsync({
        resourceId,
        details: [
          {
            traitId: 'advisorInformation',
            year: detailRequirements[0].year,
            value: values,
          } as EqipAdvisorInformation,
        ],
      })
      .then(onSuccess)
      .catch(onError)
  }, [values, isValid, dirty])

  useEffect(() => {
    if (paginatedUsers.data) {
      if (paginatedUsers.data.numItems === 1) {
        setEmailKnown(true)
      } else {
        setEmailKnown(false)
      }
    }
  }, [values.email, paginatedUsers.dataUpdatedAt])

  useEffect(() => {
    validateForm()
  }, [values.name])

  return (
    <Form>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography variant="h6">{t('title')}</Typography>
            <Typography variant="caption">{t('cta')}</Typography>
          </Stack>
          {userRole === 'manager' && (
            <Stack direction="row" spacing={2} alignItems="center">
              <Can useAny={[AuthUserPermission.USER_EDIT_ADVISOR]}>
                <Button
                  size="small"
                  startIcon={<Search />}
                  ref={anchorEl}
                  variant="outlined"
                  onClick={() => setPopperOpen(currentVal => !currentVal)}
                >
                  {t('findAdvisor')}
                </Button>
              </Can>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  setFieldValue('name', currentUser?.displayNameFull, true)
                  setFieldValue('email', self?.userEmail, true)
                }}
              >
                {t('iAmAdvisor')}
              </Button>
            </Stack>
          )}
        </Stack>
        {userRole === 'manager' && (
          <Popover
            open={popperOpen}
            anchorEl={anchorEl.current}
            onClose={() => setPopperOpen(false)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            slotProps={{ paper: { style: { width: '50%' } } }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{ width: '50%' }}
          >
            <Stack padding={2}>
              <AssignUserControl
                hideLabel
                userId={userId}
                setUserId={(userId?: UserId, user?: OrgUserSearchResult) => {
                  setUserId(userId)
                  setFieldValue('name', user?.givenName + ' ' + user?.familyName, true)
                  setFieldValue('email', user?.email, true)
                  setPopperOpen(false)
                  // this value is intentionally ephemeral and is removed from the UI once the actual fields have been populated
                  setUserId(undefined)
                }}
                persona={['advisor', 'admin']}
                textFieldProps={{
                  placeholder: t('searchForAdvisor'),
                  fullWidth: true,
                }}
                data-testid="findAdvisor"
              />
            </Stack>
          </Popover>
        )}
        <Stack>
          <FormLabel required>{t('legalName')}</FormLabel>

          <Field
            id="name"
            name="name"
            component={TextField}
            InputProps={{
              inputProps: {
                'data-testid': 'name',
              },
            }}
            maxLength={255}
            placeholder={t('name')}
            fullWidth={true}
          />
        </Stack>
        <Stack>
          <FormLabel required>{t('email')}</FormLabel>
          <Stack spacing={1}>
            <Field
              id="email"
              name="email"
              component={TextField}
              InputProps={{
                inputProps: {
                  'data-testid': 'email',
                },
              }}
              maxLength={255}
              placeholder={t('email')}
              fullWidth={true}
            />
            {userRole === 'manager' && emailKnown === false && !errors.email && (
              <Alert severity={'warning'}>{t('emailNotKnown', { name: values.name })}</Alert>
            )}
          </Stack>
        </Stack>
        <Collapse in={updateResourceDetail.isError}>
          <FormHelperText>{updateResourceDetail.error?.response?.data?.message}</FormHelperText>
        </Collapse>
      </Stack>
    </Form>
  )
}

export const AdvisorInformationTaskEditor = (
  props: ResourceDetailFeatureTaskEditorProps<EqipAdvisorInformation>
) => {
  // @ts-ignore not sure how to tell our types this detail uses value: {...}
  const details = useProgramEngagementDetails<EqipAdvisorInformation>({
    resourceId: props.resourceIds[0],
    detailRequirements: props.detailRequirements,
  })
  const detail = details?.data?.[0]

  const schema = useMemo(
    () =>
      yup.object({
        name: yup.string().required(),
        email: yup.string().email().required(),
      }),
    []
  )

  if (details.isPending) return <Loading />

  return (
    <Formik<AdvisorInformation>
      initialValues={detail?.value || { name: '', email: '' }}
      onSubmit={() => {}}
      validationSchema={schema}
      validateOnMount
    >
      <AdvisorInformationTaskEditorForm {...props} />
    </Formik>
  )
}
