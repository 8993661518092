import { RDFertilizer } from '@cibo/core'
import { TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EventDetailEditor } from '../EventDetail'
import { DetailEditorPropsByYear } from '../types'
import { FertilizerEvent } from './FertilizerEvent'

export const FertilizerEditor = ({
  fieldModels,
  year,
  name = 'fertilizerEditor',
  requirement,
  filterInputs,
  traitId,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/FertilizerEditor')
  const FertilizerEventTableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell sx={{ minWidth: '100px' }}>
          <Typography>{t('date')}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{t('fertilizer')}</Typography>
        </TableCell>
        <TableCell colSpan={2}>
          <Typography>{t('application')}</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  )

  return (
    <EventDetailEditor<RDFertilizer['input']>
      traitId={traitId}
      fieldModels={fieldModels}
      year={year}
      name={name}
      requirement={requirement}
      filterInputs={filterInputs}
      EventTableHead={FertilizerEventTableHead}
      EventComponent={FertilizerEvent}
      translationContext="fertilizer"
      defaultEvent={{ date: null, type: '', units: '', method: '' }}
      modal
    />
  )
}
