import {
  FERTILIZER_METHODS,
  FERTILIZER_TYPE,
  FERTILIZER_TYPE_OTHER,
  FERTILIZER_UNIT,
  fertilizerNitrogenCalculation,
  FertilizerType,
  FertilizerUnit,
  RDFertilizerEvent,
  UNITS_BY_TYPE,
} from '@cibo/core'
import { AutocompleteField, CheckboxField, DatePickerField, SelectField } from '@cibo/ui'
import {
  Button,
  Collapse,
  createFilterOptions,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  InputLabel,
  Stack,
  TableCell,
  TableRow,
} from '@mui/material'
import { Field, useField, useFormikContext } from 'formik'
import { TextField } from 'formik-mui'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsRequired } from '../../useIsRequired'
import { useShowDetailAttribute } from '../../useShowDetailAttribute'
import { FertilizerComponentsInput } from './FertilizerComponentsInput'
import { FertilizerEventEditorProps } from './FertilizerEventTypes'
import { FertilizerEventFeedback } from './FertlizerEventFeedback'

const fertilizerSearchStrings: Record<FertilizerType, string> = {
  starter: 'starter',
  nh3: 'NH3 82-0-0 Anhydrous Ammonia',
  uan28: 'UAN 28-0-0 Urea Ammonium Nitrate Solution 28% N',
  uan30: 'UAN 30-0-0 Urea Ammonium Nitrate Solution 30% N',
  uan32: 'UAN 32-0-0 Urea Ammonium Nitrate Solution 32% N',
  urea: 'Urea 46-0-0 synthetic urea',
  amp_10_34_0: '10-34-0 Ammonium Polyphosphate',
  ams_21_0_0_24: 'AMS 21-0-0-24 Ammonium Sulfate',
  ats_12_0_0_26: 'ATS 12-0-0-26 Ammonium Thiosulfate',
  dap_18_46_0: 'DAP 18-46-0 Diammonium Phosphate',
  mesz_12_40_0_10: 'MESZ 12-40-0-10 MESZ',
  map_11_52_0: 'MAP 11-52-0 Monoammonium Phosphate',
  other: 'other',
}

export const FertilizerEventEditorForm = ({
  expanded,
  filterInputs,
  name,
  onPressRemove,
  requirement,
  setExpanded,
  shouldDisableDate,
  onEditEvent,
  year,
}: FertilizerEventEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/FertilizerEditor')
  const [, field] = useField<RDFertilizerEvent & { rateProxy?: number; unitProxy?: string }>(name)
  const { values, setFieldValue, resetForm, submitForm, isValid, dirty } = useFormikContext<
    RDFertilizerEvent & { rateProxy?: number; unitProxy?: string }
  >()

  const isRequired = useIsRequired(requirement)
  const show = useShowDetailAttribute<RDFertilizerEvent>(filterInputs)

  const fertilizerUnits = UNITS_BY_TYPE[values?.type] || FERTILIZER_UNIT
  const defaultMonth = new Date(year, 3)

  const fertilizerTypeFilterOptions = useMemo(
    () =>
      createFilterOptions({
        matchFrom: 'any',
        stringify: (option: FertilizerType) => fertilizerSearchStrings[option] || option,
      }),
    []
  )

  useEffect(() => {
    // This effect is an unfortunate result of the fact that the shape of the data the detail
    // accepts doesn't meaningfully correspond to the shape of the form.
    // Non-data values `rateProxy` and `unitProxy` are displayed and interactable
    // Data values `unit`, `rate` and `nitrogenAmnt` are set based on the proxy values

    if (!!values.nitrogenAmnt && !values.rateProxy && values.rateProxy !== 0) {
      // if there is NOT a value for `rateProxy` and there IS a value for `nitrogenAmnt`,
      // set `rateProxy` to the value of `nitrogenAmnt`, and set `proxyUnit` to 'poundsOfNitrogen'
      // this is most relevant on init
      setFieldValue('proxyRate', values.nitrogenAmnt, true)
      setFieldValue('proxyUnit', 'poundsOfNitrogenPerAcre', true)
    }

    // if `rateProxy`, `unitProxy` and `type` are present attempt to calculate hidden data value
    // `nitrogenAmnt` from those form values. if not, `nitrogenAmnt` should be undefined.
    // NOTE: `nitrogenAmnt` can still be undefined in the first case.
    if (!!values.rateProxy && !!values.type && !!values.unitProxy) {
      const calculatedNitrogen = fertilizerNitrogenCalculation(
        values.rateProxy,
        values.type,
        values.unitProxy as FertilizerUnit,
        values.nitrogenPer
      )
      setFieldValue('nitrogenAmnt', calculatedNitrogen, true)
    } else {
      setFieldValue('nitrogenAmnt', undefined, true)
    }

    // if the `unitProxy` is `poundsOfNitrogenPerAcre`, set `nitrogenAmnt` to `rateProxy`
    if (values.unitProxy === 'poundsOfNitrogenPerAcre') {
      setFieldValue('nitrogenAmnt', values.rateProxy, true)
      // clear `units` and `rate` values if `unitProxy` is changed to `poundsOfNitrogenPerAcre`
      setFieldValue('units', undefined, true)
      setFieldValue('rate', undefined, true)
    }
    // if the `unitProxy` is not `poundsOfNitrogenPerAcre`, `units` and `rate` should
    // match their proxied values
    if (values.unitProxy !== 'poundsOfNitrogenPerAcre') {
      setFieldValue('units', values.unitProxy, true)
      setFieldValue('rate', values.rateProxy, true)
    }
    // if the `type` is not `other`, `product` is cleared out
    if (values.type !== 'other') {
      setFieldValue('product', undefined, true)
    }
  }, [JSON.stringify(values)])

  const saveButtons = (
    <Stack direction="row" justifyContent="flex-end" spacing={1}>
      <Button
        variant="outlined"
        onClick={() => {
          !field.initialValue && !field.touched && onPressRemove()
          onEditEvent(false)
          resetForm()
          setExpanded(false)
        }}
        size="small"
      >
        {t('cancel')}
      </Button>
      <Button
        data-testid="add-fertilizer-event"
        variant="contained"
        onClick={submitForm}
        disabled={!isValid || !dirty}
        size="small"
      >
        {t(field.initialValue ? 'update' : 'add')}
      </Button>
    </Stack>
  )

  return (
    <TableRow>
      <TableCell sx={{ paddingBlock: 0, borderBottomWidth: expanded ? 1 : 0 }} colSpan={4}>
        <Collapse in={expanded}>
          <Stack sx={{ paddingBlock: 1 }} spacing={2}>
            {show('date') && (
              <Grid size={{ xs: 12, md: 4 }}>
                <FormControl fullWidth required>
                  <FormLabel>{t('date')}</FormLabel>
                  <DatePickerField
                    name={`date`}
                    minDate={new Date(year - 1, 6, 1)}
                    maxDate={new Date(year + 1, 0, 1)}
                    defaultCalendarMonth={defaultMonth}
                    shouldDisableDate={shouldDisableDate}
                    InputProps={{
                      //@ts-ignore this will be passed to the input element, but is not in the type definition
                      'data-testid': `${name}.date`,
                    }}
                  />
                </FormControl>
              </Grid>
            )}

            {show('type') && (
              <Grid size={{ xs: 12, md: 8 }}>
                <FormControl fullWidth required>
                  <FormLabel>{t('type')}</FormLabel>
                  <AutocompleteField
                    options={FERTILIZER_TYPE}
                    id={`${name}.type`}
                    filterOptions={fertilizerTypeFilterOptions}
                    name={`type`}
                    getOptionLabel={option => t(option)}
                    data-testid={`${name}.type`}
                  />
                </FormControl>
              </Grid>
            )}
            {values?.type === 'other' && (
              <Grid size={{ xs: 12, md: 6 }}>
                <FormControl required={FERTILIZER_TYPE_OTHER.includes(values?.type)} fullWidth>
                  <FormLabel>{t('product')}</FormLabel>
                  <Field component={TextField} name={`product`} data-testid={`${name}.product`} />
                </FormControl>
              </Grid>
            )}

            <Grid spacing={2} container>
              <Grid size={{ xs: 12, md: 4 }}>
                <FormControl fullWidth required>
                  <FormLabel htmlFor={`${name}.rateProxy`}>{t('applicationRate')}</FormLabel>
                  <Field
                    component={TextField}
                    name={`rateProxy`}
                    InputProps={{
                      type: 'number',
                      inputProps: {
                        min: 0,
                      },
                    }}
                    data-testid={`${name}.rateProxy`}
                  />
                </FormControl>
              </Grid>

              <Grid size={{ xs: 12, md: 8 }}>
                <FormControl required fullWidth>
                  <FormLabel htmlFor={`${name}.unitProxy`}>{t('applicationUnit')}</FormLabel>
                  <SelectField
                    fieldName={'unitProxy'}
                    options={fertilizerUnits}
                    renderOption={option =>
                      t(`${option}Long`, { product: values.product || t(values.type) })
                    }
                    data-testid={`${name}.unitProxy`}
                    disabled={'' === values?.type || fertilizerUnits.length === 1}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FertilizerComponentsInput name={name} />

            {values?.type !== 'other' && (
              <Grid size={{ xs: 12, md: 6 }}>
                <FormControl required={values?.type === 'other'} fullWidth>
                  <FormLabel>{t('product')}</FormLabel>
                  <Field component={TextField} name={`product`} data-testid={`${name}.product`} />
                </FormControl>
              </Grid>
            )}
            <Grid size={{ xs: 12, md: 4 }}>
              <FormControl required={isRequired('method')} fullWidth>
                <FormLabel>{t('method')}</FormLabel>

                <SelectField
                  fieldName={`method`}
                  options={FERTILIZER_METHODS}
                  renderOption={t}
                  data-testid={`${name}.method`}
                />
              </FormControl>
            </Grid>
            <Stack spacing={0}>
              {show('slowRelease') && (
                <Stack direction="row" alignItems="center" flex={1}>
                  <CheckboxField name={`slowRelease`} data-testid={`${name}.slowRelease`} />
                  <InputLabel htmlFor={`${name}.slowRelease`} required={isRequired('slowRelease')}>
                    {t('slowRelease')}
                  </InputLabel>
                </Stack>
              )}
              {show('inhibitor') && (
                <Stack direction="row" alignItems="center">
                  <CheckboxField name={`inhibitor`} data-testid={`${name}.inhibitor`} />
                  <InputLabel htmlFor={`${name}.inhibitor`} required={isRequired('inhibitor')}>
                    {t('inhibitor')}
                  </InputLabel>
                </Stack>
              )}
              {show('ammonium') && (
                <Stack direction="row" alignItems="center">
                  <CheckboxField name={`ammonium`} data-testid={`${name}.ammonium`} />
                  <InputLabel htmlFor={`${name}.ammonium`} required={isRequired('ammonium')}>
                    {t('ammonium')}
                  </InputLabel>
                </Stack>
              )}
            </Stack>

            <FertilizerEventFeedback saveButtons={saveButtons} name={name} />
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
