import { AddressedConcern } from '@cibo/core/src/types/RecommendationPointsTypes'
import { DebugButton } from '@cibo/profile'
import { downloadAxiosResponse, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import CheckIcon from '@mui/icons-material/Check'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid2 as Grid,
  Skeleton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { fetchSelectedPracticesPDF } from '../../features/ResourceDetailFeatures/EQIP/RDEqipReviewRecommendationResultsResourceDetailFeature/fetchSelectedPracticesPDF'
import { useResourceConcernsContent } from '../../queries'
import { useEqipRecommendations } from '../../queries/useEqipRecommendations'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

export const PracticeSelectionSummaryTable = ({
  engagementId,
}: Partial<Pick<ResourceDetailFeatureTaskEditorProps, 'engagementId'>>) => {
  const { t } = useTranslation('@cibo/programs/PracticeSelectionConfirmation')
  const { palette } = useTheme()
  const navigate = useNavigate()

  const recommendations = useEqipRecommendations(engagementId)
  const resourceConcernsContent = useResourceConcernsContent()

  const concernsWithDefinitions = useMemo(
    () =>
      recommendations.data?.addressedConcerns.map(concern => {
        const definitionEntry = resourceConcernsContent.data?.items.find(
          ({ fields }) => fields.resourceConcernComponentId === concern.resourceConcernId
        )

        return {
          ...concern,
          definition: definitionEntry?.fields.definition,
        } as AddressedConcern & { definition: string }
      }),
    [recommendations.dataUpdatedAt, resourceConcernsContent.dataUpdatedAt]
  )

  const [expanded, setExpanded] = useState(true)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const [debugBusy, setDebugBusy] = useState(false)

  return (
    <Stack padding={3}>
      <Accordion expanded={expanded} onChange={handleExpandClick}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label={t('expand')}
          onClick={handleExpandClick}
        >
          <Typography variant="h6" component="h2">
            {t('resourceConcernToAddress')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            {recommendations.isPending || resourceConcernsContent.isPending ? (
              <Grid columns={{ xs: 4, md: 8, lg: 12 }} container>
                <Grid size={{ xs: 12 }}>
                  <Skeleton variant="rounded" height={150} />
                </Grid>
              </Grid>
            ) : (
              <Stack>
                <TableContainer
                  component={Card}
                  elevation={0}
                  color={palette.text.primary}
                  sx={{
                    '& .MuiTableHead-root': {
                      backgroundColor: 'transparent',
                      color: `${palette.text.primary} !important`,
                    },
                    '& .MuiTableCell-root': {
                      color: palette.text.primary,
                    },
                  }}
                >
                  <Table>
                    <TableHead sx={{ '.MuiTableCell-root': { color: 'text.primary' } }}>
                      <TableRow>
                        <TableCell>{t('growersResourceConcerns')}</TableCell>
                        <TableCell>{t('fieldsAddressed')}</TableCell>
                        <TableCell>{t('statePriority')}</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {concernsWithDefinitions?.map(concern => (
                        <TableRow key={concern.resourceConcernId}>
                          <TableCell>
                            <Typography variant="body1" gutterBottom>
                              {concern.resourceConcernDesc}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {concern.definition}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {t('aOfB', {
                              countA: concern.numFieldsAddressed,
                              countB: concern.numFieldsWithConcern,
                            })}
                          </TableCell>
                          <TableCell align="center">
                            {concern.statePriority ? <CheckIcon color="success" /> : null}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>

      <DebugButton
        disabled={!engagementId}
        loading={debugBusy}
        onClick={() => {
          if (!engagementId) return

          const PDF_URL = `/pdf/eqip-selected-practices/${engagementId}/`
          navigate(PDF_URL)
        }}
      >
        Render PDF
      </DebugButton>

      <DebugButton
        disabled={!engagementId}
        loading={debugBusy}
        onClick={() => {
          if (!engagementId) return

          setDebugBusy(true)

          fetchSelectedPracticesPDF({ engagementId })
            .then(downloadAxiosResponse(`debug_${engagementId}.pdf`))
            .catch(e => console.error(e))
            .finally(() => setDebugBusy(false))
        }}
      >
        Generate PDF
      </DebugButton>
    </Stack>
  )
}
