import {
  AssignFieldsRequest,
  CreateFSAFarmRequest,
  FSAFarmsAPI,
  FSAFarmSearchQuery,
  UpdateFSAFarmRequest,
} from '@cibo/core'
import { useAuth } from '@cibo/profile'
import { logRequestError } from '@cibo/ui'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEY } from './queryKey'
import { useInvalidateBatchedFields } from './useBatchedFields'

export const useFSAFarm = (termsetId: string | undefined) => {
  const { isLoggedIn } = useAuth()
  return useQuery({
    queryKey: [QUERY_KEY.FSA_FARM, termsetId],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () =>
      !!termsetId
        ? FSAFarmsAPI.read(termsetId).then(response => response?.item)
        : Promise.resolve({}),

    enabled: isLoggedIn && !!termsetId,
  })
}

export const usePaginatedFSAFarms = (query?: FSAFarmSearchQuery) => {
  const { isLoggedIn } = useAuth()
  return useQuery({
    queryKey: [QUERY_KEY.FSA_FARMS_SEARCH, query],
    queryFn: () => FSAFarmsAPI.search(query),
    enabled: isLoggedIn,
  })
}

export const useCreateFSAFarm = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (request: CreateFSAFarmRequest) => FSAFarmsAPI.create(request),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FSA_FARMS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FSA_FARMS_SEARCH] })
    },
    onError: (error: any, variables, context) => {
      logRequestError(error, { query: 'useCreateFSAFarm', variables, context })
      return error
    },
  })
}

export const useUpdateFSAFarm = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (request: UpdateFSAFarmRequest) => FSAFarmsAPI.update(request),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FSA_FARM] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FSA_FARMS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FSA_FARMS_SEARCH] })
    },
  })
}

export const useAssignFieldsToFSAFarm = () => {
  const queryClient = useQueryClient()

  const invalidateBatchedFields = useInvalidateBatchedFields()

  return useMutation({
    mutationFn: (request: AssignFieldsRequest) => FSAFarmsAPI.assignFields(request),
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FSA_FARMS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })

      invalidateBatchedFields(request.fieldIds)
    },
  })
}
export const useUnassignFieldsToFSAFarm = () => {
  const queryClient = useQueryClient()

  const invalidateBatchedFields = useInvalidateBatchedFields()

  return useMutation({
    mutationFn: (request: AssignFieldsRequest) => FSAFarmsAPI.unassignFields(request),
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FSA_FARMS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })

      invalidateBatchedFields(request.fieldIds)
    },
  })
}

export const useDeleteFSAFarm = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (resourceId: string) => FSAFarmsAPI.delete(resourceId),
    onSuccess: (_, termsetId) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FSA_FARMS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FSA_FARM, termsetId] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FSA_FARMS_SEARCH] })
    },
  })
}
