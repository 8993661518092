import { FieldModel, FieldsAPI, MpxResponseError } from '@cibo/core'
import { keepPreviousData, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import { create, keyResolver } from '@yornaath/batshit'
import { AxiosError } from 'axios'

export const BATCHED_FIELD_QUERY_KEY = 'batchedFields'

const fields = create({
  fetcher: async (ids: string[]) => {
    const fields = await FieldsAPI.fields(ids, { includeAllDetails: true })
    return fields.map(field => new FieldModel(field))
  },
  resolver: keyResolver('resourceId'),
})

function query(id?: string) {
  return {
    queryKey: [BATCHED_FIELD_QUERY_KEY, id],
    queryFn: () => {
      // @ts-ignore this is disabled when id is undefined
      return fields.fetch(id)
    },
    enabled: !!id,
    keepPreviousData: keepPreviousData,
  }
}

export function useBatchedField(id?: string) {
  return useQuery<FieldModel | null, AxiosError<MpxResponseError>>(query(id))
}

export function useBatchedFields(ids: string[]) {
  return useQueries({
    queries: ids.map(query),
    combine: results => {
      return {
        data: results.every(result => result.data)
          ? (results.map(result => result.data) as FieldModel[])
          : undefined,
        isFetching: results.some(result => result.isFetching),
        isFetched: results.every(result => result.isFetched),
        isPending: results.some(result => result.isPending),
        isLoading: results.every(result => result.isLoading),
        isError: results.some(result => result.isError),
        dataUpdatedAt: results.reduce((acc, result) => Math.max(acc, result.dataUpdatedAt), 0),
        error: results.find(result => result.error),
        refetch: () =>
          new Promise<void>(resolve => {
            results.forEach(result => result.refetch())
            resolve()
          }),
      }
    },
  })
}

export const useInvalidateBatchedFields = () => {
  const queryClient = useQueryClient()

  return (ids: string[]) => {
    queryClient.invalidateQueries({
      predicate: query =>
        query.queryKey[0] === BATCHED_FIELD_QUERY_KEY && ids.includes(query.queryKey[1] as string),
    })
  }
}
