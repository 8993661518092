import { Array2d } from '../utils/geometry'

export enum LandProfileType {
  FIELD = 'field',
  PARCEL = 'parcel',
  PORTFOLIO = 'portfolio',
}

export type LandProfileCapability = 'carbon_lab' | 'yield_forecast'

export interface YieldValues {
  avgYield: number
  year: number
}

export interface LandProfile {
  acreage: number
  boundingBox: Array2d
  capabilities: LandProfileCapability[]
  color?: string
  cornHistoricalYields?: YieldValues[]
  countyPkId?: string
  entries?: CollectionEntry[]
  geometry?: any
  geometrySlug?: string
  ghgEmissions?: number
  ghgEmissionsIndex?: number
  ghgEmissionsPerYield?: number
  /** @deprecated */
  inventory?: any[]
  label?: {
    primary?: string
    secondary?: string
    tertiary?: string
  }
  labelPosition?: number[]
  leaseValue?: number
  leaseValuePerAcre: number
  location?: number[]
  name?: string
  nccpi?: number
  nitrogenLeaching?: number
  nitrogenLeachingIndex?: number
  nitrogenLeachingPerYield?: number
  parcelCount?: number
  productivity: number
  productivityUpdatedAt?: string
  regenPotential?: number
  regenPotentialPerAcre?: number
  resourceId?: any
  secTwnRng?: string
  soyHistoricalYields?: YieldValues[]
  space?: string
  stability: number
  statePi?: number
  statePiName?: string
  sustainability?: number
  sustainabilityValuesUpdatedAt?: string
  tillableAcres: number
  tillableGeometrySlug?: string
  type: LandProfileType
}

export interface LandProfileResult {
  data: {
    items: LandProfile[]
  }
}

export interface ParcelCollectionEntry {
  featureId?: number
  label?: {
    primary?: string
    secondary?: string
    tertiary?: string
  }
  taxInfo?: {
    assessDate: string
    assessVal: number
    taxAmount: number
  }
  ownerInfo?: {
    owner: string
    ownerAddress: string
  }
}

export type CollectionEntryRefItem = ParcelCollectionEntry // | CollectionCollectionEntry etc.

export interface CollectionEntry {
  id: string
  type: 'parcel'
  refItemId: string
  refItem: CollectionEntryRefItem
  geometrySlug: string
  _meta: {
    landProfile: LandProfile
  }
  fromResourceId?: string
}
