import { RDEqipEligibilityWetlandCompliance } from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { BooleanRollup } from '../../BooleanRollup'

export const RDEqipEligibilityWetlandComplianceResourceDetailFeature: ResourceDetailFeature<RDEqipEligibilityWetlandCompliance> =
  {
    traitId: 'eqipEligibilityWetlandCompliance',
    TaskEditor: (
      props: ResourceDetailFeatureTaskEditorProps<RDEqipEligibilityWetlandCompliance>
    ) => (
      <BooleanRollup<RDEqipEligibilityWetlandCompliance>
        answerValues={{ yes: { value: false }, no: { value: true } }}
        detailKey="wetlandCompliance"
        ns="@cibo/landmanager/RDEqipEligibilityWetlandComplianceResourceDetailFeature"
        {...props}
      />
    ),
  }
