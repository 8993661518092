import { EqipApplicationResultsParams, RDEqipApplicationResults } from '@cibo/core'
import { ResourceDetailFeatureTaskEditorProps, Section, useSaveIndicator } from '@cibo/ui'
import { Collapse, FormControl, FormLabel, InputAdornment, Stack, TextField } from '@mui/material'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProgramEngagementDetails } from '../../../../hooks/useProgramEngagementDetails'
import {
  useProgramEngagement,
  useProgramModel,
  useUploadEqipApplicationResults,
} from '../../../../queries'
import { ScheduleOfOperationsUpload } from './ScheduleOfOperationsUpload'
import { YesNoQuestion } from './YesNoQuestion'

export const RDEqipApplicationResultsTaskEditor = ({
  detailRequirements,
  resourceIds: [resourceId],
}: ResourceDetailFeatureTaskEditorProps<RDEqipApplicationResults>) => {
  const { t } = useTranslation('@cibo/programs/RDEqipApplicationResultsTaskEditor')
  const { data: details, isFetchedAfterMount } = useProgramEngagementDetails({
    resourceId,
    detailRequirements,
  })
  const { setIsDirty, setIsError, setIsSaving } = useSaveIndicator()
  const uploadEqipApplicationResults = useUploadEqipApplicationResults()
  const engagement = useProgramEngagement({ id: resourceId })
  const programModel = useProgramModel({ programId: engagement.data?.programId })
  const benchmark = programModel.data?.benchmarks?.find(
    ({ benchmark }) => benchmark === 'applicationResults'
  )
  const existingDetail: RDEqipApplicationResults | Record<any, any> = useMemo(
    () => (!!details?.length ? details[0] : {}),
    [details]
  )
  const locked = existingDetail?.immutable
  const existingResult = existingDetail.result || {}

  const [contractValue, setContractValue] = useState<number>(existingResult?.totalContractValue)

  const hasFile = !!existingResult?.file?.length
  const displayFileInput =
    (existingResult?.applicationApproved && existingResult?.responseReceived) || hasFile

  const onChange = (args: Omit<EqipApplicationResultsParams, 'id'>) => {
    setIsError(false)
    setIsDirty(true)
    setIsSaving(true)
    const { responseReceived, applicationApproved, totalContractValue } = existingResult
    uploadEqipApplicationResults
      .mutateAsync({
        id: resourceId,
        year: benchmark && benchmark.details[0].year,
        responseReceived,
        applicationApproved,
        totalContractValue,
        ...args,
      })
      .catch(() => setIsError(true))
      .finally(() => setIsSaving(false))
  }

  const debouncedOnChange = useCallback(
    debounce((args: Omit<EqipApplicationResultsParams, 'id'>) => {
      onChange(args)
    }, 500),
    [details]
  )

  useEffect(() => {
    setContractValue(existingResult?.totalContractValue)
  }, [isFetchedAfterMount])

  return (
    <Stack spacing={3} flex={1}>
      <Section
        sx={{
          flex: 1,
          marginBottom: displayFileInput ? undefined : -3,
          transition: 'margin-bottom 0.2s ease-in-out',
        }}
      >
        <Stack padding={3} spacing={3}>
          <YesNoQuestion
            data-testid="responseReceived"
            disabled={locked || uploadEqipApplicationResults.isPending || hasFile}
            onChange={answer => onChange({ responseReceived: answer === 'true' })}
            noLabel={t('no')}
            questionLabel={t('didYouHearBack')}
            yesLabel={t('yes')}
            isError={uploadEqipApplicationResults.isError}
            value={existingResult?.responseReceived}
          />
          <Collapse in={existingResult?.responseReceived ?? ''}>
            <YesNoQuestion
              data-testid="applicationApproved"
              disabled={locked || uploadEqipApplicationResults.isPending || hasFile}
              onChange={answer => onChange({ applicationApproved: answer === 'true' })}
              noLabel={t('applicationNotApproved')}
              yesLabel={t('applicationApproved')}
              questionLabel={t('wereYouApproved')}
              isError={uploadEqipApplicationResults.isError}
              value={existingResult?.applicationApproved}
            />
          </Collapse>

          <Collapse
            in={existingResult?.responseReceived && existingResult?.applicationApproved === false}
          >
            <YesNoQuestion
              data-testid="willReapply"
              disabled={locked || uploadEqipApplicationResults.isPending || hasFile}
              onChange={answer => onChange({ willReapply: answer === 'true' })}
              noLabel={t('no')}
              yesLabel={t('yes')}
              questionLabel={t('wouldYouReapply')}
              isError={uploadEqipApplicationResults.isError}
              value={existingResult?.willReapply}
            />
          </Collapse>
        </Stack>
      </Section>

      <Collapse in={displayFileInput} timeout={0.2}>
        <Section sx={{ flex: 1 }}>
          <Stack padding={3} spacing={3}>
            <ScheduleOfOperationsUpload
              currentFile={existingResult?.file}
              currentDetail={{
                ...existingResult,
                year: benchmark && benchmark.details[0].year,
              }}
              programEngagementId={resourceId}
              disabled={locked}
            />
            <FormControl
              component="fieldset"
              variant="standard"
              error={uploadEqipApplicationResults.isError}
            >
              <FormLabel id="contract-value-radio-buttons-group-label">{t('totalValue')}</FormLabel>
              <TextField
                data-testid="contractValue"
                value={contractValue ?? ''}
                slotProps={{
                  input: {
                    type: 'numeric',
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  },
                }}
                disabled={locked || uploadEqipApplicationResults.isPending}
                placeholder={t('enterValue')}
                onChange={event => {
                  setContractValue(+event.target.value)
                  debouncedOnChange({ totalContractValue: +event.target.value })
                }}
              />
            </FormControl>
          </Stack>
        </Section>
      </Collapse>
    </Stack>
  )
}
