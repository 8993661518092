// Per the NRCS practices 162 (nutrient management) and 157 (soil health management system design)

import { FieldModel, IntegerString } from '@cibo/core'

// cannot be implemented on the same field in the same application for reimbursement.
const incompatiblePractices: [IntegerString[]] = [['162' as IntegerString, '157' as IntegerString]]

export const doesFieldHaveIncompatiblePractice = (
  field: FieldModel,
  practiceId: IntegerString,
  year?: number
) => {
  const incompatiblePracticeSetsForThisPractice = incompatiblePractices.find(practices =>
    practices.some(a => a === practiceId)
  )

  const existingDetails: IntegerString[] = field.findDetail({
    traitId: 'eqipSelectedPractices',
    year,
  })?.input?.selectedPractices

  const incompatiblePracticesForThisPractice =
    !!incompatiblePracticeSetsForThisPractice &&
    existingDetails?.find(
      (practice: IntegerString) =>
        incompatiblePracticeSetsForThisPractice.includes(practice) && practice !== practiceId
    )
  return !!incompatiblePracticesForThisPractice
}
