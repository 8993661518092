'eqipPracticeChoiceWildlife'
import { GeneralResourceDetail } from '../ResourceDetail'

/**
 * !IMPORTANT
 *
 * When updating the list of practices, make sure to ALSO update the Contentful
 * entry associated with this resource detail.
 *
 * https://app.contentful.com/spaces/mqztk29gethb/entries/VJQ1pOoGfEasNYY2cvkjA
 *
 */
export const EQIP_PRACTICE_CHOICE_WILDLIFE = [
  '314',
  '666',
  '315',
  '384',
  '490',
  '378',
  '500',
  '472',
  '584',
  '570',
  '654',
  '660',
  'none',
] as const
export type EqipPracticeChoiceWildlife = typeof EQIP_PRACTICE_CHOICE_WILDLIFE[number]

export type RDEqipPracticeChoiceWildlife = GeneralResourceDetail<
  EqipPracticeChoiceWildlife[],
  'eqipPracticeChoiceWildlife'
>
