import { FSAFarm, UserId } from '@cibo/core'
import Add from '@mui/icons-material/Add'
import { Autocomplete, ListItemIcon, ListItemText, MenuItem, TextField } from '@mui/material'
import { useDebounce } from '@react-hook/debounce'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCreateFSAFarm, usePaginatedFSAFarms } from '../../../../../queries/useFSAFarms'

export type FarmNumberPickerProps = {
  onChange(value: FSAFarm): void
  onClear(): void
  ownerId: UserId
  value: string
  disabled?: boolean
}

export const FarmNumberPicker = ({
  onChange,
  onClear,
  ownerId,
  value,
  disabled,
}: FarmNumberPickerProps) => {
  const { t } = useTranslation('@cibo/landmanager/FarmInfo')
  const [fullText, setFullText] = useDebounce('', 250)
  const [termsetError, setTermsetError] = useState<string>()
  const createFSAFarm = useCreateFSAFarm()
  const paginatedFarms = usePaginatedFSAFarms({
    fullText,
    owner: ownerId,
  })

  const handleChangeFSAFarmNumber = (value: FSAFarm | string) => {
    if (typeof value === 'string') {
      const createRequest = {
        owner: ownerId,
        farmSerialNumber: value,
      }
      createFSAFarm
        .mutateAsync(createRequest)
        .then(response => {
          setTermsetError(undefined)
          onChange(response.item)
        })
        .catch(error => {
          setTermsetError(t('errorOnCreateShort'))
          throw error
        })
      return
    } else {
      onChange(value)
    }
  }

  return (
    <Autocomplete<FSAFarm, false, false, true>
      id="fsa-farm-termset"
      data-testid={'farm-number'}
      freeSolo
      getOptionLabel={(option: FSAFarm | string) =>
        typeof option === 'string' ? option : option.farmSerialNumber
      }
      sx={{ flex: 1 }}
      value={value || ''}
      disabled={createFSAFarm.isPending || disabled}
      options={paginatedFarms.data?.items || []}
      onInputChange={(_, value) => setFullText(value)}
      renderOption={(props, option) => {
        if (typeof option === 'string') {
          return (
            <MenuItem {...props} key={'create'}>
              <ListItemIcon>
                <Add color="secondary" />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ color: 'secondary' }}>
                {fullText === '' ? t('addNewFarmNumber') : t('addFarmWithNumber', { fullText })}
              </ListItemText>
            </MenuItem>
          )
        }
        return (
          <MenuItem {...props} key={option.id}>
            {option.farmSerialNumber}
          </MenuItem>
        )
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          error={!!termsetError}
          helperText={termsetError}
          onBlur={event => {
            if (!event.target.value) {
              onClear()
            }
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      onChange={(event, value) => {
        if (!value) {
          event.type === 'click' && onClear()
        } else {
          handleChangeFSAFarmNumber(value)
        }
      }}
      filterOptions={(options, params) => {
        const isExisting = options.some(option => fullText === option.farmSerialNumber)
        if (fullText !== '' && !isExisting) {
          // @ts-ignore options can be strings, mui makes you respect strings
          options.push(fullText)
        }
        if (fullText === '') {
          // @ts-ignore options can be strings, mui makes you respect strings
          options.push('')
        }

        return options
      }}
    />
  )
}
