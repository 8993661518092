import { RDEqipCurrentPractices, ResourceDetailsUpdate, TraitId } from '@cibo/core'
import { FieldNameCell, useUpdateMultipleFields } from '@cibo/landmanager'
import { ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import {
  Box,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import debounce from 'lodash/debounce'
import { flatten, uniq } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProgramEngagementDetails } from '../../../../hooks/useProgramEngagementDetails'
import { useProgramEngagementFields } from '../../../../queries'
import {
  RDEqipChangeAllCurrentPracticesInput,
  RDEqipCurrentPracticesInput,
} from './RDEqipCurrentPracticesInput'

const SOURCES = [
  'eqipPracticeChoiceAgroforestry',
  'eqipPracticeChoiceEngineeringLandErosionStructures',
  'eqipPracticeChoiceEngineeringWaterControlStructures',
  'eqipPracticeChoiceSoil',
  'eqipPracticeChoiceWildlife',
] as TraitId[]

export const RDEqipCurrentPracticesTaskEditor = ({
  detailRequirements,
  engagementId,
  onError,
  onSuccess,
  userRole,
  ownerName,
  onUpdating,
  resourceIds,
}: ResourceDetailFeatureTaskEditorProps<RDEqipCurrentPractices>) => {
  const { t } = useTranslation('@cibo/programs/RDEqipCurrentPracticesTaskEditor')
  const programEngagementFields = useProgramEngagementFields({ id: engagementId })
  const engagementDetails = useProgramEngagementDetails({ resourceId: engagementId })
  const updateFields = useUpdateMultipleFields()
  const year = detailRequirements?.[0]?.year
  const [combinedAnswer, setCombinedAnswer] = useState<Record<string, string>>({})
  const [allDisabled, setDisableAll] = useState<boolean>(false)

  const options = useMemo(
    () =>
      flatten(
        engagementDetails.data
          ?.filter(({ traitId }) => SOURCES.includes(traitId))
          .map(({ result }) => result) ?? []
      ) as string[],
    [engagementDetails.dataUpdatedAt]
  )

  useEffect(() => {
    const allOptions = uniq(['none', ...options])
    const allAnswers = programEngagementFields.data?.map(
      row => row.findDetail?.({ traitId: 'eqipCurrentPractices', year })?.result ?? []
    )
    const newCombinedAnswer: Record<string, 'unchecked' | 'checked' | 'indeterminate'> = {}
    allOptions.forEach(option => {
      const matchingAnswers = allAnswers?.filter(answer => answer.includes(option))
      if (!matchingAnswers?.length) {
        newCombinedAnswer[option] = 'unchecked'
      } else if (matchingAnswers?.length === programEngagementFields.data?.length) {
        newCombinedAnswer[option] = 'checked'
      } else {
        newCombinedAnswer[option] = 'indeterminate'
      }
    })
    setCombinedAnswer(newCombinedAnswer)
  }, [programEngagementFields.data, engagementDetails.dataUpdatedAt, detailRequirements?.[0]?.year])

  const onChangeAll = (event: SelectChangeEvent<string[]>) => {
    onUpdating?.()
    const prevValue = combinedAnswer

    // 'none' is an exclusive option.
    if ((event.target.value as string[])?.includes('none') && prevValue.none !== 'checked') {
      setCombinedAnswer({ none: 'checked' })

      updateChangeAll({ none: 'checked' }, prevValue)
    }
    // other options need to be considered individually
    else {
      setCombinedAnswer(prevValue => {
        const newCombinedAnswer: Record<string, string> = {}
        options.forEach(option => {
          if (option !== 'none') {
            if (prevValue[option] === 'indeterminate') {
              newCombinedAnswer[option] = !(event.target.value as string[]).includes(option)
                ? // if previous value is indetermine and value is not included, maintain existing value
                  'indeterminate'
                : 'checked'
            } else {
              newCombinedAnswer[option] = !(event.target.value as string[]).includes(option)
                ? // if previous value is checked, unchecked or undefined and value is not included, set to unchecked
                  'unchecked'
                : 'checked'
            }
          } else {
            newCombinedAnswer.none = 'unchecked'
          }
        })

        updateChangeAll(newCombinedAnswer, prevValue)

        return newCombinedAnswer
      })
    }
  }

  const updateChangeAll = useCallback(
    debounce((passedCombinedAnswer: Record<string, string>, prevValue: Record<string, string>) => {
      setDisableAll(true)
      let fieldUpdates: undefined | ResourceDetailsUpdate[] = []
      if (passedCombinedAnswer.none === 'checked') {
        fieldUpdates = programEngagementFields.data?.map(
          field =>
            ({
              resourceId: field.resourceId,
              details: [
                {
                  traitId: 'eqipCurrentPractices',
                  year: detailRequirements?.[0]?.year,
                  result: ['none'],
                },
              ],
            } as ResourceDetailsUpdate)
        )
      } else {
        fieldUpdates = programEngagementFields.data?.map(field => {
          const existingFieldValue = field.findDetail?.({ traitId: 'eqipCurrentPractices', year })
            ?.result as string[]
          const newFieldValue = options.map(option => {
            // if all values is indeterminate, maintain field value
            if (passedCombinedAnswer[option] === 'indeterminate') {
              return existingFieldValue?.find(value => option === value) ? option : undefined
            }
            // if all values is checked, add the value
            if (passedCombinedAnswer[option] === 'checked') {
              return option
            }
            // if all values is unchecked or undefined, remove the value
            return undefined
          })
          return {
            resourceId: field.resourceId,
            details: [
              {
                traitId: 'eqipCurrentPractices',
                year,
                result: !!newFieldValue.filter(Boolean).filter(a => a !== 'none').length
                  ? newFieldValue.filter(Boolean).filter(a => a !== 'none')
                  : undefined,
              },
            ],
          } as ResourceDetailsUpdate
        })
      }

      if (!!fieldUpdates) {
        updateFields
          .mutateAsync(fieldUpdates)
          .then(() => {
            onSuccess?.()
            setDisableAll(false)
          })
          .catch(error => {
            onError?.(error)
            setCombinedAnswer(prevValue)
            setDisableAll(false)
          })
      }
    }, 1000),
    [programEngagementFields.data, detailRequirements, setDisableAll]
  )

  return (
    <Stack spacing={3}>
      <Typography>
        {t('question', { context: userRole, ownerName, count: resourceIds.length })}
      </Typography>

      <TableContainer style={{ borderWidth: 0 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('field', { ns: '@cibo/landmanager/SelectRollupEditor' })}</TableCell>
              <TableCell>{t('currentPracticesHeader')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="all">
              <TableCell>
                <Box sx={{ marginLeft: 2, py: 2 }}>
                  <Typography sx={{ fontWeight: 'bold', my: 2 }}>
                    {t('answerForAllFields', { ns: '@cibo/landmanager/SelectRollupEditor' })}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <RDEqipChangeAllCurrentPracticesInput
                  value={combinedAnswer}
                  onChange={
                    !allDisabled ? onChangeAll : (event: SelectChangeEvent<string[]>) => null
                  }
                  options={options}
                  disabled={allDisabled}
                  immutable={
                    !!programEngagementFields.data?.filter(
                      field =>
                        !!field.findDetail?.({
                          traitId: 'eqipCurrentPractices',
                          year: detailRequirements?.[0]?.year,
                        })?.immutable
                    ).length
                  }
                />
              </TableCell>
            </TableRow>
            {programEngagementFields.data?.map((field, index) => (
              <TableRow key={field.resourceId}>
                <TableCell>
                  <Box sx={{ marginLeft: 2, py: 2 }}>
                    {/* @ts-ignore this isn't a data grid, so it's missing some props */}
                    <FieldNameCell row={field} />
                  </Box>
                </TableCell>
                <TableCell>
                  <RDEqipCurrentPracticesInput
                    row={field}
                    onError={onError}
                    onSuccess={onSuccess}
                    onUpdating={onUpdating}
                    options={options}
                    year={year}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
