import { FieldModel } from '@cibo/core'
import { useUpdateMultipleFields } from '@cibo/landmanager'
import { ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { uniq } from 'ramda'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const ANSWER_FOR_ALL = 'answerForAll'

type RDEqipCurrentPracticesInputProps = Pick<
  ResourceDetailFeatureTaskEditorProps,
  'onError' | 'onSuccess' | 'onUpdating'
> & {
  row: FieldModel
  options: string[]
  year?: number
  onChangeAll?(event: SelectChangeEvent<string[]>): void
}

export const RDEqipCurrentPracticesInput = ({
  onError,
  onSuccess,
  onUpdating,
  options,
  row,
  year,
}: RDEqipCurrentPracticesInputProps) => {
  const { t } = useTranslation('@cibo/programs/RDEqipCurrentPracticesInput')
  const updateFields = useUpdateMultipleFields()
  const [value, setValue] = useState<string[]>(
    row.findDetail?.({ traitId: 'eqipCurrentPractices', year })?.result ?? []
  )
  const allOptions = uniq(['none', ...options])
  const staleOptions =
    value?.filter(option => !allOptions.includes(option) && option !== 'none') ?? []

  useEffect(() => {
    value.length === 0 &&
      options.filter(a => a !== 'none').length === 0 &&
      onChange({ target: { value: ['none'] } } as SelectChangeEvent<string[]>)
  }, [options])

  useEffect(() => {
    setValue(row.findDetail({ traitId: 'eqipCurrentPractices', year })?.result ?? [])
  }, [row])

  const onChange = (event: SelectChangeEvent<string[]>) => {
    onUpdating?.()
    const prevValue = value
    // 'none' is an exclusive option.
    const newValue =
      (event.target.value as string[])?.includes('none') && !prevValue.includes('none')
        ? ['none']
        : (event.target.value as string[]).filter(option => option !== 'none')
    setValue(newValue)
    updateFields
      .mutateAsync([
        {
          resourceId: row.resourceId,
          details: [
            {
              traitId: 'eqipCurrentPractices',
              year,
              result: newValue.length ? newValue : undefined,
            },
          ],
        },
      ])
      .then(onSuccess)
      .catch(error => {
        onError?.(error)
        setValue(prevValue)
      })
  }

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <Select<string[]>
        labelId="current-practices-input-label"
        id="current-practices-input"
        data-testid="current-practices-input"
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput />}
        label={t('selectPractice')}
        renderValue={selected => {
          return selected.length
            ? selected
                .map(option => t(`choice-${option}`, { ns: '@cibo/programs/EqipPracticeChoices' }))
                .join(', ')
            : t('selectPractice')
        }}
        disabled={row.findDetail?.({ traitId: 'eqipCurrentPractices', year })?.immutable}
        displayEmpty
      >
        {staleOptions.map(option => (
          <MenuItem key={option} value={option} data-testid={`choice-${option}`}>
            <Checkbox checked={value?.includes(option)} />
            <ListItemText
              primary={t(`choice-${option}`, { ns: '@cibo/programs/EqipPracticeChoices' })}
              sx={{ fontStyle: 'italic', color: 'text.disabled' }}
            />
          </MenuItem>
        ))}
        {allOptions.map(option => (
          <MenuItem key={option} value={option} data-testid={`choice-${option}`}>
            <Checkbox checked={value?.includes(option)} />
            <ListItemText
              primary={t(`choice-${option}`, { ns: '@cibo/programs/EqipPracticeChoices' })}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const RDEqipChangeAllCurrentPracticesInput = ({
  onChange,
  value,
  immutable,
  disabled,
  options,
}: {
  onChange: (event: SelectChangeEvent<string[]>) => void
  value: Record<string, string>
  immutable: boolean
  disabled: boolean
  options: string[]
}) => {
  const { t } = useTranslation('@cibo/programs/RDEqipCurrentPracticesInput')
  const allOptions = uniq(['none', ...options])

  const displayValue = options.map(option => value[option] === 'checked' && option)
  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <Select<string[]>
        labelId="all-practices-input-label"
        id="all-practices-input"
        data-testid="all-practices-input"
        multiple
        value={displayValue.filter(val => !!val) as string[]}
        onChange={onChange}
        input={<OutlinedInput />}
        label={t('selectPractice')}
        renderValue={selected => {
          return selected.length
            ? selected
                .map(option => t(`choice-${option}`, { ns: '@cibo/programs/EqipPracticeChoices' }))
                .join(', ')
            : t('selectPractice')
        }}
        disabled={immutable || disabled}
        displayEmpty
      >
        {allOptions.map(option => (
          <MenuItem key={option} value={option} data-testid={`choice-${option}`}>
            <Checkbox
              checked={value[option] === 'checked'}
              indeterminate={value[option] === 'indeterminate'}
              disabled={disabled}
            />
            <ListItemText
              primary={t(`choice-${option}`, { ns: '@cibo/programs/EqipPracticeChoices' })}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
