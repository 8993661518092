import { RDDescription } from '@cibo/core'
import {
  AddCellValueButton,
  DataGridPro,
  Markdown,
  ResourceDetailFeatureTaskEditorProps,
} from '@cibo/ui'
import { Stack, Typography } from '@mui/material'
import { GridRenderCellParams, GridRowHeightReturnValue, useGridApiRef } from '@mui/x-data-grid-pro'
import { any, equals, prop } from 'ramda'
import { ReactNode, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { fieldColumnAttributes, useFieldColumns } from '../../../components/FieldColumns'
import { useBatchedFields } from '../../../queries'
import { useSaveDetail } from '../../DetailEditing'

type DescriptionInfoRow = ReturnType<typeof fieldColumnAttributes> & {
  id: string
  description: string
}

export const FieldDescription = ({
  ownerName,
  userRole,
  resourceIds,
  onSuccess,
  onError,
  onUpdating,
}: Omit<
  ResourceDetailFeatureTaskEditorProps<RDDescription>,
  'detailRequirements' | 'engagementId'
>) => {
  const { t } = useTranslation('@cibo/landmanager/FieldDescription')
  const gridApi = useGridApiRef()

  const fieldModels = useBatchedFields(resourceIds)
  const saveDetail = useSaveDetail()

  const { fieldNameColumn } = useFieldColumns()

  const busy = any(prop('isPending'), [fieldModels, saveDetail])

  const columns = useMemo(() => {
    return [
      { ...fieldNameColumn, sortable: false },
      {
        field: 'description',
        headerName: t('description'),
        sortable: false,
        disableColumnMenu: true,
        flex: 3,
        editable: true,
        renderCell: (params: GridRenderCellParams) =>
          params.value && params.value !== '' ? (
            params.value
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              flex={1}
              height="100%"
            >
              <AddCellValueButton {...params} data-testid="add-description" />
            </Stack>
          ),
      },
    ]
  }, [])

  const processRowUpdate = useCallback(
    (updated: DescriptionInfoRow, original: DescriptionInfoRow) => {
      if (equals(updated.description, original.description)) return updated

      onUpdating?.()
      saveDetail
        .mutateAsync({
          resourceId: updated.id as string,
          details: [
            {
              traitId: 'description',
              result: updated.description,
            },
          ],
        })
        .catch(onError)
        .then(onSuccess)

      return updated
    },
    [saveDetail, onUpdating, onSuccess, onError]
  )
  const rows = useMemo(
    () =>
      fieldModels.data?.map(field => {
        const description = field.resolveStandingDetail('description')?.result

        return {
          ...fieldColumnAttributes(field),
          description,
        }
      }),
    [fieldModels.isFetched]
  )

  const getRowHeight = useCallback((): GridRowHeightReturnValue => 'auto', [])

  return (
    <Stack spacing={3}>
      <Markdown
        overrides={{
          em: {
            component: ({ children }: { children: ReactNode }) => (
              <>
                <br />
                <Typography variant="body2" color="text.secondary" component="span">
                  {children}
                </Typography>
              </>
            ),
          },
        }}
      >
        {t('tableCta', { context: userRole, name: ownerName })}
      </Markdown>

      <DataGridPro<DescriptionInfoRow>
        apiRef={gridApi}
        autoHeight
        columns={columns}
        disableColumnSelector
        disableRowSelectionOnClick
        getRowHeight={getRowHeight}
        loading={busy}
        rows={rows || []}
        processRowUpdate={processRowUpdate}
      />
    </Stack>
  )
}
