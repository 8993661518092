import { ResourceDetail } from './ResourceDetail'

export interface RDAmendment extends ResourceDetail {
  traitId: 'amendment'
  year: number
  result: boolean
  input: {
    events: AmendmentEvent[]
  }
}

export type AmendmentEvent = {
  date: Date
  amendmentType: AmendmentType
  method: AmendmentMethod
  rate: number
  units: AmendmentUnits
  nitrogenPercentage: number
}

export const AMENDMENT_TYPE = [
  'beefManure',
  'compostedBeefManure',
  'dairyManure',
  'swineManure',
  'poultryManure',
  'otherManure',
  'otherOrganicAmendment',
] as const
export type AmendmentType = typeof AMENDMENT_TYPE[number]

export const AMENDMENT_METHOD = [
  'solidBroadcast',
  'solidBroadcastWithIncorporation',
  'liquidBroadcast',
  'liquidBroadcastWithIncorporation',
  'liquidInjectedWithKnife',
  'liquidInjectedWithSweep',
  'liquidInjectedWithCoulter',
  'other',
] as const
export type AmendmentMethod = typeof AMENDMENT_METHOD[number]

export const AMENDMENT_UNITS = ['gallonsPerAcre', 'poundsPerAcre', 'tonsPerAcre']

export type AmendmentUnits = typeof AMENDMENT_UNITS[number]
