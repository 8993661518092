import { logNonRequestError, logRequestError } from '@cibo/ui'
import { Button, Dialog, Stack, Typography } from '@mui/material'
import { Component } from 'react'

/**
 * This will NOT catch your event handlers
 * https://reactjs.org/docs/error-boundaries.html#how-about-event-handlers
 */
class ErrorBoundary extends Component<
  { children: React.ReactNode },
  { hasError: boolean; error?: Error }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props)
    this.state = { hasError: false, error: undefined }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error & { pathTemplate?: string }, errorInfo: React.ErrorInfo) {
    /**
     * only report from compiled builds of the app - this includes dev
     */
    if (import.meta.env.PROD || import.meta.env.VITE_DEBUG_SENTRY) {
      const contexts: { messagePopupVisible: boolean; cause?: any } = {
        messagePopupVisible: true,
      }

      if (error.cause) {
        contexts.cause = error.cause
      }

      if (error.pathTemplate) {
        logRequestError(error, { ...contexts, location: 'errorBoundary' })
      } else {
        logNonRequestError(error, {
          ...contexts,
          location: 'errorBoundary else',
        })
      }
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Dialog open={true}>
          <Stack p={2} spacing={2}>
            <Typography variant="h5">Sorry about that.</Typography>
            <Typography>
              We've contacted mission control about the problem you just experienced.
            </Typography>
            <Button variant="contained" onClick={() => window.location.reload()}>
              Restart
            </Button>
          </Stack>
        </Dialog>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
