import { FertilizerType, FertilizerUnit } from '../types'

const uanMultiplier: Record<FertilizerType, number> = {
  uan28: 2.9,
  uan30: 3.2,
  uan32: 3.5,
}
// Note: if rate is not one of the above, values between 10-50% can be multiplied by 10.5*percentage
// which is not strictly accurate but acceptable as an estimate.

const unitConversionMap: Record<FertilizerUnit, number> = {
  gallonsPerAcre: 11.1, // a gallon of fertilizer is assumed to have a density of 11.1lbs/gal EXCEPT starter
  poundsPerAcre: 1,
  poundsOfNitrogenPerAcre: 1,
  ouncesPerAcre: 0.08671875,
}

const nitrogenPercentPerProduct: Record<FertilizerType, number> = {
  amp_10_34_0: 0.1,
  ams_21_0_0_24: 0.21,
  ats_12_0_0_26: 0.12,
  dap_18_46_0: 0.18,
  mesz_12_40_0_10: 0.12,
  map_11_52_0: 0.11,
}

export const fertilizerNitrogenCalculation = (
  rate: number,
  fertilizerType: FertilizerType,
  unit: FertilizerUnit,
  nitrogenPercentInput?: number
) => {
  if (unit === 'poundsOfNitrogenPerAcre') {
    return rate
  }
  const unitConversion = unitConversionMap[unit]
  let nitrogenPercent
  if (!unit) {
    console.error('Unit of measure must be provided')
  }
  if (fertilizerType.includes('uan')) {
    // All the "uan" variants.
    if (!unit.includes('gal')) {
      console.error('Unit of measure must be gallons per acre for UAN type fertilizer.')
      return
    }
    const multiplier = uanMultiplier[fertilizerType]
    return parseFloat((rate * multiplier).toFixed(5))
  } else if (fertilizerType.startsWith('nh') || fertilizerType.startsWith('ur')) {
    if (unit !== 'ouncesPerAcre' && unit !== 'poundsPerAcre') {
      console.error(`Unit of measure must be provided in weight for ${fertilizerType}.`)
      return
    }
    const fraction = fertilizerType === 'nh3' ? 0.82 : 0.467
    return parseFloat((rate * fraction * unitConversion).toFixed(5))
  } else if (fertilizerType === 'starter') {
    // If a user entered a rate in gal/acre, assume 10% N for every gallon applied with a density of 11.72 lbs/gal.
    // If the user entered a rate in lbs/acre, assume 18% N applied for every pound of starter applied.
    if (unit.includes('gal')) {
      return parseFloat((rate * 11.72 * 0.1).toFixed(5))
    } else {
      return parseFloat((rate * 0.18).toFixed(5))
    }
  } else {
    const nitrogenPercentInferred = nitrogenPercentPerProduct[fertilizerType]
    if (!nitrogenPercentInput && !nitrogenPercentInferred) {
      console.error(
        `Nitrogen percent input is required to calculate nitrogen amount for ${fertilizerType}`
      )
      return
    }
    if (nitrogenPercentInferred) {
      nitrogenPercent = nitrogenPercentInferred
    } else {
      nitrogenPercent =
        // eslint-disable-next-line
        nitrogenPercentInput! > 1
          ? // convert to a decimal if passed as a whole number value
            // eslint-disable-next-line
            nitrogenPercentInput! / 100
          : nitrogenPercentInput
    } // eslint-disable-next-line
    const result = rate * nitrogenPercent! * unitConversion
    return parseFloat(result.toFixed(5))
  }
}
