import Axios from 'axios'
import * as contentful from 'contentful'

const axiosInstance = Axios.create({})

export const getContentfulClient = () =>
  contentful.createClient({
    // @ts-ignore meta.env is there
    accessToken: `${import.meta.env.VITE_CONTENTFUL_DELIVERY_TOKEN}`,
    httpAgent: axiosInstance,
    // @ts-ignore meta.env is there
    space: `${import.meta.env.VITE_CONTENTFUL_SPACE_ID}`,
  })

export const getPreviewClient = () => {
  return contentful.createClient({
    // @ts-ignore meta.env is there
    accessToken: `${import.meta.env.VITE_CONTENTFUL_PREVIEW_TOKEN}`,
    host: 'preview.contentful.com',
    httpAgent: axiosInstance,
    // @ts-ignore meta.env is there
    space: `${import.meta.env.VITE_CONTENTFUL_SPACE_ID}`,
  })
}
