import { FieldModel, ResourceDetail } from '@cibo/core'
import { DataGridPro, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { GridRenderCellParams, GridRowHeightReturnValue } from '@mui/x-data-grid-pro'
import { ReactNode, useCallback, useMemo } from 'react'
import { useFieldColumns } from '../../../components/FieldColumns'
import { fieldColumnAttributes } from '../../../components/FieldColumns/fieldNameColumn'
import { useBatchedFields } from '../../../queries'

export const ReadOnlyDetailTable = (
  props: ResourceDetailFeatureTaskEditorProps<any> & {
    accessValue(detail: ResourceDetail): any
    columnHeader: string
    renderDetail(value: any): ReactNode
  }
) => {
  const {
    resourceIds,
    detailRequirements: [firstRequirement],
    columnHeader,
    accessValue,
    renderDetail,
  } = props

  const { fieldNameColumn } = useFieldColumns()
  const fieldModels = useBatchedFields(resourceIds)

  const columns = useMemo(() => {
    return [
      fieldNameColumn,
      {
        field: 'answer',
        headerName: columnHeader,
        flex: 1,
        hideable: false,
        disableColumnMenu: true,
        filterable: false,
        renderCell: ({ value }: GridRenderCellParams<FieldModel>) =>
          value ? renderDetail?.(value) || '' : '',
      },
    ]
  }, [fieldModels.dataUpdatedAt])

  const rows = useMemo(() => {
    const answers = new Set()

    const rows = !fieldModels.data
      ? []
      : fieldModels.data.map(field => {
          const detail = field.findDetail(firstRequirement)

          const answer = !!detail && accessValue(detail)
          answers.add(answer)

          return {
            ...fieldColumnAttributes(field),
            answer,
            immutable: detail?.immutable,
          }
        })

    return rows
  }, [fieldModels.dataUpdatedAt, JSON.stringify(firstRequirement)])

  const getRowHeight = useCallback((): GridRowHeightReturnValue => 'auto', [])

  return (
    <DataGridPro
      autoHeight
      columns={columns}
      disableRowSelectionOnClick
      getRowHeight={getRowHeight}
      hideCellFocus
      rows={rows}
    />
  )
}
