import { ResourceDetail } from './ResourceDetail'

export const IRRIGATION_TYPE = [
  'whole',
  'centralPivot',
  'other',
  'drip',
  'furrow',
  'localized',
  'manual',
  'row',
  'sprinkler',
  'sub',
  'surface_flood',
] as const
export type IrrigationType = typeof IRRIGATION_TYPE[number]

export const IRRIGATION_SCHEDULE = [
  'calendar',
  'basedOnSoilMoistureOrWeather',
  'regulations',
] as const
export type IrrigationSchedule = typeof IRRIGATION_SCHEDULE[number]

export const IRRIGATION_UNITS = [
  'acreInchesPerHour',
  'gallonsPerMin',
  'millionGallonsPerDay',
] as const
export type IrrigationUnits = typeof IRRIGATION_UNITS[number]

export type IrrigationInput = {
  irrigationType: IrrigationType
  schedule?: IrrigationSchedule
  rate?: number
  units?: IrrigationUnits
  totalAcreInches?: number
}

export interface RDIrrigation extends ResourceDetail<IrrigationInput, boolean> {
  traitId: 'irrigation'
  season: number
}
