import { Benchmark, FieldModel, WorkflowItem } from '@cibo/core'
import { PaginatedFieldsProvider, useFieldStore } from '@cibo/landmanager'
import { LAND_MANAGER_ROUTES } from '@cibo/ui'
import { AxiosResponse } from 'axios'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { useCreateWorkflow, useUpdateWorkflow } from '../../queries'
import { AddMoreFieldsModal } from './AddMoreFieldsModal'
import { DeleteWorkflowModal } from './DeleteWorkflowModal'
import { EnrollmentMessageDialog } from './EnrollmentMessageDialog'
import { EnrollmentWindowClosedModal } from './EnrollmentWindowClosedModal'
import { FieldOverlapsModal } from './FieldOverlapsModal'
import { LedgerConflictsModal } from './LedgerConflictsModal'
import { RemoveFieldsModal } from './RemoveFieldsModal'
import { WorkflowNameDialog } from './WorkflowNameDialog'

type FieldOverlapsModalState = {
  open: boolean
  workflowId?: string
  onOverlapsRemoved?: () => void
}
type LedgerConflictsModalState = {
  open: boolean
  workflowId?: string
  onResolved?: () => void
  context?: 'enroll' | 'commit'
}
type EnrollmentWindowClosedModalState = {
  workflowId?: string
  open: boolean
  programName?: string
  count?: number
  onDeleteSuccess?: () => void
  programId?: string
}

export type RemoveFieldsModalState = {
  workflowId: string
  fieldsToRemove: string[]
}

export type AddFieldsModalState = {
  workflowId: string
  onSuccess?: () => void
}

export type ClosedNameDialogState = { open: false }
export type CreateNameDialogState = {
  open: true
  variant: 'create'
  programId: string
  benchmark: Benchmark
  fieldIds: string[]
  onSuccess?: () => void
}
export type EditNameDialogState = {
  open: true
  variant: 'edit'
  currentName?: string
  workflowId: string
  onSuccess?: () => void
}
export type NameDialogState = ClosedNameDialogState | CreateNameDialogState | EditNameDialogState

export interface MessageUserDialogState {
  open: boolean
  workflow?: WorkflowItem
  navigateCallback?: (programId?: string) => void
}

export type DeleteWorkflowModalState = {
  workflowId: string
  onSuccess(): void
  onUndo?(arg: AxiosResponse<WorkflowItem>): void
}

export interface WorkflowManagementState {
  setRemoveFieldsModal(state: RemoveFieldsModalState): void
  setAddFieldsModal(state: AddFieldsModalState): void
  setNameDialog(state: NameDialogState): void
  setMessageUserDialog(state: MessageUserDialogState): void
  setDeleteWorkflowModal(state: DeleteWorkflowModalState): void
  setOverlapWarning(state: FieldOverlapsModalState): void
  setLedgerConflicts(state: LedgerConflictsModalState): void
  setEnrollmentWindowClosed(state: EnrollmentWindowClosedModalState): void
}

export const WorkflowManagementContext = createContext<WorkflowManagementState>({
  setRemoveFieldsModal: (state: RemoveFieldsModalState) => {
    throw new Error('removeFields context not provided')
  },
  setAddFieldsModal: (state: AddFieldsModalState) => {
    throw new Error('setAddFieldsModal context not provided')
  },
  setDeleteWorkflowModal: (state: DeleteWorkflowModalState) => {
    throw new Error('setDeleteWorkflowModal context not provided')
  },
  setNameDialog: (state: NameDialogState) => {
    throw new Error('setNameDialog context not provided')
  },
  setMessageUserDialog: (state: MessageUserDialogState) => {
    throw new Error('setMessageUserDialog context not provided')
  },
  setOverlapWarning: (state: FieldOverlapsModalState) => {
    throw new Error('setOverlapWarning context not provided')
  },
  setLedgerConflicts: (state: LedgerConflictsModalState) => {
    throw new Error('setLedgerConflicts context not provided')
  },
  setEnrollmentWindowClosed: (state: EnrollmentWindowClosedModalState) => {
    throw new Error('setEnrollmentWindowClosed context not provided')
  },
})
WorkflowManagementContext.displayName = 'WorkflowManagementContext'

export const WorkflowManagementProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const { setFieldsetFields, setFieldsetMode } = useFieldStore()
  const { t } = useTranslation('@cibo/programs/EnrollmentMessageDialog')
  const { enqueueSnackbar } = useSnackbar()
  const { mutateAsync: createWorkflow } = useCreateWorkflow()
  const { mutateAsync: updateWorkflow } = useUpdateWorkflow()
  const [addFieldsModal, setAddFieldsModal] = useState<AddFieldsModalState | undefined>()
  const [removeFieldsModal, setRemoveFieldsModal] = useState<RemoveFieldsModalState | undefined>()
  const [deleteWorkflowModal, setDeleteWorkflowModal] = useState<
    DeleteWorkflowModalState | undefined
  >()
  const [nameDialog, setNameDialog] = useState<NameDialogState>({ open: false })
  const [messageUserDialog, setMessageUserDialog] = useState<MessageUserDialogState>({
    open: false,
  })

  const [overlapWarning, setOverlapWarning] = useState<FieldOverlapsModalState>({ open: false })
  const [ledgerConflicts, setLedgerConflicts] = useState<LedgerConflictsModalState>({ open: false })
  const [enrollmentWindowClosed, setEnrollmentWindowClosed] =
    useState<EnrollmentWindowClosedModalState>({
      open: false,
    })

  const handleEnrollmentMessageSuccess = () => {
    enqueueSnackbar(t('sendEnrollmentMessageSuccess'), { variant: 'success' })
    setMessageUserDialog({ open: false })
    messageUserDialog.navigateCallback &&
      messageUserDialog.navigateCallback(messageUserDialog.workflow?.programId)
  }

  return (
    <WorkflowManagementContext.Provider
      value={{
        setAddFieldsModal,
        setRemoveFieldsModal,
        setDeleteWorkflowModal,
        setNameDialog,
        setMessageUserDialog,
        setOverlapWarning,
        setLedgerConflicts,
        setEnrollmentWindowClosed,
      }}
    >
      {children}

      <PaginatedFieldsProvider>
        <AddMoreFieldsModal {...addFieldsModal} requestClose={() => setAddFieldsModal(undefined)} />
      </PaginatedFieldsProvider>

      <RemoveFieldsModal
        {...removeFieldsModal}
        requestClose={() => setRemoveFieldsModal(undefined)}
      />

      <EnrollmentMessageDialog
        open={messageUserDialog.open}
        onCancel={() => setMessageUserDialog({ open: false })}
        onSuccess={handleEnrollmentMessageSuccess}
        workflow={messageUserDialog.workflow}
        data-testid="messageSuccess"
      />

      <WorkflowNameDialog
        open={nameDialog.open}
        setOpen={() => setNameDialog({ open: false })}
        variant={nameDialog.open ? nameDialog.variant : undefined}
        currentName={
          nameDialog.open && nameDialog.variant === 'edit' ? nameDialog.currentName : undefined
        }
        onSubmit={values => {
          if (nameDialog.open) {
            if (nameDialog.variant === 'edit') {
              return updateWorkflow({
                workflowId: nameDialog.workflowId,
                // @ts-ignore this typing isn't coming through for some reason
                updates: values,
              }).then(() => nameDialog.onSuccess && nameDialog.onSuccess())
            } else {
              return createWorkflow({
                ...values,
                programId: nameDialog.programId,
                benchmark: nameDialog.benchmark,
                fields: nameDialog.fieldIds,
              }).then(() => nameDialog.onSuccess && nameDialog.onSuccess())
            }
          }
        }}
      />

      <FieldOverlapsModal {...overlapWarning} onClose={() => setOverlapWarning({ open: false })} />
      <EnrollmentWindowClosedModal
        {...enrollmentWindowClosed}
        onClose={() => setEnrollmentWindowClosed({ open: false })}
      />
      <LedgerConflictsModal
        {...ledgerConflicts}
        onClose={() => setLedgerConflicts({ open: false })}
        onResolveConflicts={(boundaryOverlapFields: FieldModel[]) => {
          setFieldsetMode('edit')
          setFieldsetFields(boundaryOverlapFields)
          navigate(generatePath(`${LAND_MANAGER_ROUTES.DRAW}?mode=fieldset`))
        }}
      />

      <DeleteWorkflowModal
        {...deleteWorkflowModal}
        requestClose={() => setDeleteWorkflowModal(undefined)}
      />
    </WorkflowManagementContext.Provider>
  )
}

export const useWorkflowManagement = () => useContext(WorkflowManagementContext)
