import { GeneralResourceDetail } from '../ResourceDetail'

/**
 * !IMPORTANT
 *
 * When updating the list of practices, make sure to ALSO update the Contentful
 * entry associated with this resource detail.
 *
 * https://app.contentful.com/spaces/mqztk29gethb/entries/m2nLRoRujUUOw7H9Z3NFb
 *
 */
export const EQIP_PRACTICE_CHOICE_ENGINEERING_LAND_EROSION_STRUCTURES = [
  '382',
  '464',
  '600',
  '558',
  '462',
  '527',
  '423',
  '450',
  '555',
  '560',
  '588',
  'none',
] as const
export type EqipPracticeChoiceEngineeringLandErosionStructures = typeof EQIP_PRACTICE_CHOICE_ENGINEERING_LAND_EROSION_STRUCTURES[number]

export type RDEqipPracticeChoiceEngineeringLandErosionStructures = GeneralResourceDetail<
  EqipPracticeChoiceEngineeringLandErosionStructures[],
  'eqipPracticeChoiceEngineeringLandErosionStructures'
>
